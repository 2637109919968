import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Skeleton } from '@/components/ui/skeleton';
import type { CascadeState, HSResult } from '@/lib/api/types/hs-lookup';
import { ThumbsUp } from 'lucide-react';
import { memo, useEffect, useState } from 'react';
import { formatHSCode } from '../utils/index';

interface ClassificationResultsProps {
  result: HSResult;
  lookup_id?: string;
  hsDigits: string;
  cascadeState: CascadeState;
  onFeedbackClick: () => void;
}

// Define the sections that will be progressively loaded
type ResultSection = 
  | 'hsCode' 
  | 'analysis' 
  | 'productDetails' 
  | 'tradeRequirements' 
  | 'hierarchy' 
  | 'alternatives';

export const ClassificationResults = memo(function ClassificationResults({
  result,
  lookup_id,
  hsDigits,
  cascadeState,
  onFeedbackClick,
}: ClassificationResultsProps) {
  // State to track which sections have been "loaded"
  const [loadedSections, setLoadedSections] = useState<ResultSection[]>(['hsCode']);
  
  // Simulate progressive loading of sections
  useEffect(() => {
    // If we're in processing state, don't show any sections yet
    if (result.status === 'processing') {
      setLoadedSections(['hsCode']);
      return;
    }
    
    // Define the sequence and timing of section reveals
    const sectionSequence: { section: ResultSection; delay: number }[] = [
      { section: 'analysis', delay: 300 },
      { section: 'productDetails', delay: 600 },
      { section: 'tradeRequirements', delay: 1200 },
      { section: 'hierarchy', delay: 1800 },
      { section: 'alternatives', delay: 2400 }
    ];
    
    // Clear any existing timeouts
    const timeouts: NodeJS.Timeout[] = [];
    
    // Schedule the progressive loading of sections
    sectionSequence.forEach(({ section, delay }) => {
      const timeout = setTimeout(() => {
        setLoadedSections(prev => [...prev, section]);
      }, delay);
      timeouts.push(timeout);
    });
    
    // Cleanup timeouts on unmount
    return () => {
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, [result]);
  
  const renderCascadeProgress = () => {
    return (
      <div className="space-y-2">
        <div className="text-sm font-medium">
          {cascadeState.stage === 'initial' && 'Initial Analysis...'}
          {cascadeState.stage === 'detailed' && 'Detailed Review...'}
          {cascadeState.stage === 'validation' && 'Final Validation...'}
          {cascadeState.stage === 'complete' && 'Complete'}
        </div>
        <div className="w-full bg-secondary h-2 rounded-full">
          <div
            className="bg-primary h-2 rounded-full transition-all duration-300"
            style={{ width: `${cascadeState.progress}%` }}
          />
        </div>
      </div>
    );
  };

  const formatTradeConsiderationValue = (key: string, value: any) => {
    if (typeof value === 'object' && value !== null) {
      // Handle seasonal requirements object
      if (key === 'seasonal_requirements') {
        return Object.entries(value)
          .map(
            ([k, v]) =>
              `${k
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}: ${v}`
          )
          .join('\n');
      }
      return JSON.stringify(value);
    }

    if (key === 'duty_rates') {
      // Remove percentage signs from duty rates
      return value.replace(/%/g, '');
    }
    return value;
  };

  // Early return for processing state to show minimal UI
  if (result.status === 'processing') {
    return (
      <Card className="border-[#D1D5DB]">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
          <CardTitle className="text-lg font-semibold">
            Classification Results
          </CardTitle>
        </CardHeader>
        <CardContent>
          {renderCascadeProgress()}
          <div className="p-4 mt-4 bg-accent/5 rounded-lg">
            <div className="text-sm font-medium text-gray-900">Processing your request...</div>
            <div className="text-sm text-gray-700 mt-2">
              We're analysing your product details to provide accurate code classification.
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }

  // Helper function to render a section skeleton
  const renderSectionSkeleton = (title: string) => (
    <div className="border-t border-gray-300 pt-4 animate-pulse">
      <div className="text-sm font-medium text-gray-900 mb-2">
        {title}
      </div>
      <div className="space-y-2">
        <Skeleton className="h-4 w-3/4" />
        <Skeleton className="h-4 w-full" />
        <Skeleton className="h-4 w-5/6" />
      </div>
    </div>
  );

  return (
    <Card className="border-[#D1D5DB]">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-4">
        <CardTitle className="text-lg font-semibold">
          Classification Results
        </CardTitle>
        <div className="flex space-x-2">
          <Button
            variant="outline"
            size="sm"
            className="flex items-center gap-1"
            onClick={onFeedbackClick}
          >
            <ThumbsUp className="h-4 w-4" />
            Rate Classification
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        {renderCascadeProgress()}
        <div className="space-y-6">
          {/* Primary Classification - Always show this first */}
          <div className="space-y-4">
            <div className="p-4 bg-accent/5 rounded-lg">
              <div className="text-sm font-medium text-gray-900">HS Code</div>
              <div className="text-2xl font-bold tracking-tight text-gray-900">
                {formatHSCode(result.code, parseInt(hsDigits))}
              </div>
              <div className="text-sm font-medium text-gray-900 mt-1">
                Confidence: {result.confidence}%
              </div>
            </div>

            {/* Classification Analysis */}
            {loadedSections.includes('analysis') ? (
              <div className="border-t border-gray-300 pt-4">
                <div className="text-sm font-medium text-gray-900 mb-2">
                  Classification Analysis
                </div>
                <div className="space-y-3">
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      Primary Classification
                    </div>
                    <div className="text-sm text-gray-700">
                      {result.description}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      Reasoning
                    </div>
                    <div className="text-sm text-gray-700">
                      {result.reasoning}
                    </div>
                  </div>
                </div>
              </div>
            ) : renderSectionSkeleton("Classification Analysis")}

            {/* Product Details */}
            {loadedSections.includes('productDetails') ? (
              <div className="border-t border-gray-300 pt-4">
                <div className="text-sm font-medium text-gray-900 mb-2">
                  Product Details
                </div>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="text-sm font-medium text-gray-900">Type</div>
                    <div className="text-sm">
                      {result.classification_notes?.product_type || 'N/A'}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-900">Stage</div>
                    <div className="text-sm">
                      {result.classification_notes?.manufacture_stage || 'N/A'}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      Key Materials
                    </div>
                    <div className="text-sm">
                      {result.classification_notes?.key_materials || 'N/A'}
                    </div>
                  </div>
                </div>
              </div>
            ) : renderSectionSkeleton("Product Details")}

            {/* Trade Requirements */}
            {loadedSections.includes('tradeRequirements') ? (
              <div className="border-t border-gray-300 pt-4">
                <div className="text-sm font-medium text-gray-900 mb-2">
                  Trade Requirements
                </div>
                {Object.entries(result.trade_considerations).map(
                  ([key, value]) => (
                    <div key={key} className="mb-4">
                      <div className="text-sm font-medium text-gray-900">
                        {key
                          .split('_')
                          .map(
                            word => word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(' ')}
                      </div>
                      <div className="text-sm whitespace-pre-line">
                        {formatTradeConsiderationValue(key, value) || 'N/A'}
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : renderSectionSkeleton("Trade Requirements")}

            {/* Classification Hierarchy */}
            {loadedSections.includes('hierarchy') ? (
              <div className="border-t border-gray-300 pt-4">
                <div className="text-sm font-medium text-gray-900 mb-2">
                  Classification Hierarchy
                </div>
                <div className="space-y-2">
                  <div>
                    <div className="text-sm font-medium text-gray-900 capitalize">
                      Chapter
                    </div>
                    <div className="text-sm">
                      {result.hierarchy.chapter.code} -{' '}
                      {result.hierarchy.chapter.description}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-900 capitalize">
                      Heading
                    </div>
                    <div className="text-sm">
                      {result.hierarchy.heading.code} -{' '}
                      {result.hierarchy.heading.description}
                    </div>
                  </div>
                  <div>
                    <div className="text-sm font-medium text-gray-900 capitalize">
                      Subheading
                    </div>
                    <div className="text-sm">
                      {result.code.substring(0, 6)} - {result.description}
                    </div>
                  </div>
                </div>
              </div>
            ) : renderSectionSkeleton("Classification Hierarchy")}

            {/* Alternative Codes */}
            {result.alternatives && result.alternatives.length > 0 && (
              loadedSections.includes('alternatives') ? (
                <div className="border-t border-gray-300 pt-4">
                  <div className="text-sm font-medium text-gray-900 mb-2">
                    Alternative Classifications
                  </div>
                  <div className="space-y-2">
                    {result.alternatives.map((alt, idx) => (
                      <div key={idx} className="text-sm">
                        {formatHSCode(alt.code, parseInt(hsDigits))} -{' '}
                        {alt.description}
                        <div className="text-xs text-gray-700 mt-1">
                          Confidence: {alt.confidence}% - {alt.differentiators}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : renderSectionSkeleton("Alternative Classifications")
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
});
