import type {
    ExtractionJob,
    ExtractionResponse,
    ExtractionsListResponse,
    TableExtractionResponse,
    TablePreviewData,
    TablePreviewResponse
} from '@/lib/api/types/table-extraction';
import { getApiUrl } from '@/lib/config';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getAuthToken } from '../../auth-store';

// Use the API URL from config
const API_BASE = getApiUrl('table-extraction');

// Constants for API requests
const MAX_RETRIES = 3;
const RETRY_DELAY = 1500;
const REQUEST_TIMEOUT = 60000; // 60 seconds
const CACHE_TIME = 0; // Disable caching
const STALE_TIME = 0; // Always consider data stale
const REFETCH_INTERVAL = 10000; // 10 seconds

// Transform TableExtractionRecord to ExtractionJob with improved date handling
const transformRecord = (record: TableExtractionResponse['jobs'][0]): ExtractionJob => {
  // Ensure we have valid data for required fields
  if (!record) {
    console.error('Invalid record received:', record);
    return {
      id: 'unknown',
      userId: 'unknown',
      fileName: 'Unknown File',
      fileType: 'application/octet-stream',
      status: 'failed',
      errorMessage: 'Invalid data received',
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  }

  // Ensure ID is properly converted to string
  const id = record.id ? record.id.toString() : 'unknown';
  
  // Ensure user ID is properly converted to string
  // Handle both snake_case (from API) and camelCase (from frontend)
  const userId = (record.user_id || record.userId) ? (record.user_id || record.userId).toString() : 'unknown';
  
  // Ensure file name is properly handled - check both camelCase and snake_case
  // Make sure to check for null, undefined, or empty string
  const fileName = (record.fileName || record.file_name) && (record.fileName || record.file_name).trim() !== '' 
    ? (record.fileName || record.file_name).trim() 
    : 'Unknown File';
  
  // Ensure file type is properly handled - check both camelCase and snake_case
  const fileType = record.fileType || record.file_type || 'application/octet-stream';
  
  // Ensure status is properly handled - check both camelCase and snake_case
  const status = record.status || 'pending';
  
  // Ensure table count is properly handled - check both camelCase and snake_case
  // Use 0 as default for completed jobs with null table_count
  const tableCount = (record.tableCount !== null && record.tableCount !== undefined) 
    ? Number(record.tableCount) 
    : (record.table_count !== null && record.table_count !== undefined)
      ? Number(record.table_count)
      : (status === 'completed' ? 0 : undefined);
  
  // Ensure confidence is properly handled - check both camelCase and snake_case
  // Use undefined for null values, but keep 0 as a valid value
  const confidence = (record.confidence !== null && record.confidence !== undefined) 
    ? Number(record.confidence) 
    : (record.confidence !== null && record.confidence !== undefined)
      ? Number(record.confidence)
      : undefined;
  
  // Ensure processing time is properly handled - check both camelCase and snake_case
  const processingTime = record.processingTime || record.processing_time || undefined;
  
  // Ensure error message is properly handled - check both camelCase and snake_case
  const errorMessage = record.errorMessage || record.error_message || undefined;
  
  // Ensure output URL is properly handled - check both camelCase and snake_case
  // For completed jobs, use a fallback URL if none is provided
  const outputUrl = record.outputUrl || record.output_url || 
    (status === 'completed' ? `${API_BASE}/${id}/download` : undefined);
  
  // Ensure preview URL is properly handled - check both camelCase and snake_case
  const previewUrl = record.previewUrl || record.preview_url || undefined;
  
  // Properly format dates with timezone handling - check both camelCase and snake_case
  // Ensure dates are valid ISO strings
  let createdAt = record.createdAt || record.created_at || new Date().toISOString();
  let updatedAt = record.updatedAt || record.updated_at || new Date().toISOString();
  
  // Validate and fix date formats if needed
  try {
    // Check if the date is a valid ISO string
    new Date(createdAt).toISOString();
  } catch (e) {
    // If not, try to parse it as a different format or use current date
    console.warn(`Invalid created_at date format: ${createdAt}`, e);
    createdAt = new Date().toISOString();
  }
  
  try {
    // Check if the date is a valid ISO string
    new Date(updatedAt).toISOString();
  } catch (e) {
    // If not, try to parse it as a different format or use current date
    console.warn(`Invalid updated_at date format: ${updatedAt}`, e);
    updatedAt = new Date().toISOString();
  }
  
  // Derive additional properties
  const error = errorMessage;
  const displayStatus = status;
  
  // A job can be downloaded if it's completed, regardless of whether output_url is set
  // The API should handle the download request based on the job ID
  const canDownload = status === 'completed';
  
  const isProcessing = status === 'processing';
  const isPending = status === 'pending';

  // Log the transformation for debugging
  console.log('Transformed record:', {
    id,
    fileName,
    status,
    tableCount,
    outputUrl,
    canDownload,
    createdAt,
    updatedAt
  });

  return {
    id,
    userId,
    fileName,
    fileType,
    status,
    tableCount,
    confidence,
    processingTime,
    errorMessage,
    outputUrl,
    previewUrl,
    createdAt,
    updatedAt,
    error,
    displayStatus,
    canDownload,
    isProcessing,
    isPending
  };
};

/**
 * Hook for managing table extraction state locally
 * This provides a more robust way to handle the extraction list
 * by maintaining local state and updating it directly
 */
export const useTableExtractionState = () => {
  const [extractionJobs, setExtractionJobs] = useState<ExtractionJob[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [totalJobs, setTotalJobs] = useState(0);
  
  // Refs for polling
  const pollingIntervalRef = useRef<NodeJS.Timeout>();
  const isPollingRef = useRef(false);
  const mountedRef = useRef(true);
  const lastPollTimeRef = useRef<number>(0);
  const retryCountRef = useRef(0);
  const pollStartTimeRef = useRef<number | null>(null);
  
  // Constants
  const POLL_INTERVAL = 10000; // 10 seconds
  const POLL_TIMEOUT = 10 * 60 * 1000; // 10 minutes
  const REQUEST_TIMEOUT = 30000; // 30 seconds
  
  // Get preview data for a file
  const getPreviewData = useCallback(async (formData: FormData): Promise<TablePreviewData | null> => {
    const token = getAuthToken();
    if (!token) {
      throw new Error('Authentication token not found');
    }
    
    // Log the API base and full URL for debugging
    const previewUrl = `${API_BASE}/preview`;
    console.log('API_BASE:', API_BASE);
    console.log('Preview URL:', previewUrl);
    
    try {
      // Create an AbortController for timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
      
      // Call the API to get preview data
      const response = await fetch(previewUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        credentials: 'include',
        signal: controller.signal,
      });
      
      // Clear the timeout
      clearTimeout(timeoutId);
      
      // Log the response status and URL for debugging
      console.log('Preview response status:', response.status);
      console.log('Preview response URL:', response.url);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ message: `Server error: ${response.status}` }));
        console.error('Preview error response:', errorData);
        throw new Error(errorData.message || 'Failed to generate preview');
      }
      
      const data: TablePreviewResponse = await response.json();
      
      if (!data.success || !data.previewData) {
        throw new Error(data.error || 'Failed to generate preview');
      }
      
      return data.previewData;
    } catch (error) {
      console.error('Error getting preview data:', error);
      throw error;
    }
  }, []);
  
  // Fetch extractions from the API
  const fetchExtractions = useCallback(async (limit: number = 50, offset: number = 0, jobId?: string) => {
    if (!mountedRef.current) return;
    
    const token = getAuthToken();
    if (!token) {
      setError(new Error('Not authenticated'));
      setIsLoading(false);
      return;
    }
    
    try {
      // Only set loading state for full refreshes, not individual job updates
      if (!jobId) {
        setIsLoading(true);
      }
      
      // Create an AbortController for timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
      
      // Add a timestamp to prevent browser caching
      const timestamp = Date.now();
      
      // If jobId is provided, only fetch that specific job
      const url = jobId 
        ? `${API_BASE}/${jobId}?_t=${timestamp}` 
        : `${API_BASE}/list?limit=${limit}&offset=${offset}&_t=${timestamp}`;
      
      const response = await fetch(
        url,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          },
          credentials: 'include',
          signal: controller.signal,
        }
      );
      
      // Clear the timeout
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ message: `Server error: ${response.status}` }));
        throw new Error(errorData.message || 'Failed to fetch extractions');
      }
      
      // Handle different response formats for single job vs list
      if (jobId) {
        const data = await response.json();
        
        if (!data.success) {
          throw new Error(data.error || 'Failed to fetch extraction');
        }
        
        // Transform the single job
        const transformedJob = transformRecord(data.job);
        
        if (mountedRef.current) {
          // Update just this job in the existing jobs array
          setExtractionJobs(prev => 
            prev.map(job => job.id === transformedJob.id ? transformedJob : job)
          );
          setError(null);
        }
        
        return [transformedJob];
      } else {
        // Handle list response
        const data: TableExtractionResponse = await response.json();
        
        // Transform the records
        const transformedJobs = data.jobs.map(record => transformRecord(record));
        
        if (mountedRef.current) {
          setExtractionJobs(transformedJobs);
          setTotalJobs(data.total);
          setError(null);
        }
        
        return transformedJobs;
      }
    } catch (error) {
      console.error('Error fetching extractions:', error);
      
      if (mountedRef.current) {
        setError(error instanceof Error ? error : new Error(String(error)));
      }
      
      return [];
    } finally {
      if (mountedRef.current && !jobId) {
        setIsLoading(false);
      }
      
      // Update last poll time
      lastPollTimeRef.current = Date.now();
    }
  }, []);
  
  // Refresh a specific job without refreshing the whole list
  const refreshSingleJob = useCallback(async (jobId: string) => {
    return fetchExtractions(50, 0, jobId);
  }, [fetchExtractions]);
  
  // Check if we should continue polling
  const shouldContinuePolling = useCallback(() => {
    if (!pollStartTimeRef.current) return false;
    
    // Check if we've exceeded the timeout
    if (Date.now() - pollStartTimeRef.current > POLL_TIMEOUT) {
      console.log('Polling timeout reached');
      return false;
    }
    
    // Check if there are any jobs in progress or recently completed/failed
    const activeJobs = extractionJobs.filter(job => {
      const status = (job.status || '').toLowerCase();
      
      // Always consider these statuses as active
      if (status === 'pending' || 
          status === 'processing' || 
          status === 'uploading' || 
          status === 'queued') {
        return true;
      }
      
      // For completed or failed jobs, check if they were updated recently
      // Use a longer window (3 minutes) to ensure UI has time to update
      if ((status === 'completed' || status === 'failed') && job.updatedAt) {
        const updatedTime = new Date(job.updatedAt).getTime();
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - updatedTime;
        
        // Consider recently updated jobs as active for 3 minutes
        // This ensures we keep polling long enough for the UI to update
        if (timeDiff < 180000) { // 3 minutes
          console.log(`Recently ${status} job: ${job.id}, updated ${Math.round(timeDiff/1000)}s ago`);
          return true;
        }
      }
      
      return false;
    });
    
    // Log active jobs for debugging
    if (activeJobs.length > 0) {
      console.log('Active jobs:', activeJobs.map(job => ({ id: job.id, status: job.status })));
    }
    
    // Continue polling if we have active jobs or if we've been polling for less than 30 seconds
    // This ensures we poll for at least 30 seconds after starting, even if no active jobs are found
    const minPollingTime = 30000; // 30 seconds
    const hasBeenPollingLongEnough = (Date.now() - pollStartTimeRef.current) > minPollingTime;
    
    if (activeJobs.length === 0 && hasBeenPollingLongEnough) {
      console.log('No active jobs and minimum polling time reached, stopping polling');
      return false;
    }
    
    console.log(`Continuing polling for ${activeJobs.length} active jobs`);
    return true;
  }, [extractionJobs]);
  
  // Start polling for job updates
  const startPolling = useCallback(() => {
    // Clear any existing polling interval
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = undefined;
    }
    
    // Set the start time if not set
    if (!pollStartTimeRef.current) {
      pollStartTimeRef.current = Date.now();
    }
    
    // Reset retry count when starting fresh
    retryCountRef.current = 0;
    
    // Calculate polling interval with exponential backoff
    const timeElapsed = pollStartTimeRef.current ? (Date.now() - pollStartTimeRef.current) : 0;
    const timeBasedMultiplier = Math.min(timeElapsed / 60000, 3); // Increase interval based on minutes elapsed, max 3x
    
    const currentInterval = Math.min(
      POLL_INTERVAL * Math.pow(1.5, retryCountRef.current) * (1 + timeBasedMultiplier), // Exponential backoff
      60000 // Max 60 seconds
    );
    
    console.log(`Starting polling with interval: ${Math.round(currentInterval)}ms (retry count: ${retryCountRef.current})`);
    
    // Force an immediate fetch before starting the interval
    fetchExtractions().then(() => {
      // Only continue polling if we should
      if (!shouldContinuePolling()) {
        console.log('Not continuing polling as conditions are not met after initial fetch');
        pollStartTimeRef.current = null;
        isPollingRef.current = false;
        return;
      }
      
      isPollingRef.current = true;
      
      // Set up the main polling interval
      pollingIntervalRef.current = setInterval(async () => {
        console.log('Polling for extraction updates...');
        
        // Increment retry count for backoff calculation
        retryCountRef.current++;
        
        // Fetch latest extractions
        await fetchExtractions();
        
        // Check if we should continue polling
        if (!shouldContinuePolling()) {
          console.log('Stopping polling as conditions are no longer met');
          if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = undefined;
          }
          isPollingRef.current = false;
          pollStartTimeRef.current = null;
          retryCountRef.current = 0;
        }
      }, currentInterval);
      
      // Set up a forced refresh every 30 seconds for jobs that might be stuck
      // This is separate from the main polling interval to ensure we get updates
      // even if the exponential backoff increases the main interval too much
      const forcedRefreshInterval = setInterval(() => {
        // Only do the forced refresh if we're still polling
        if (isPollingRef.current) {
          console.log('Performing forced refresh for extraction jobs...');
          fetchExtractions().catch(err => {
            console.error('Error in forced refresh:', err);
          });
        } else {
          // Clean up if we're no longer polling
          clearInterval(forcedRefreshInterval);
        }
      }, 30000); // Fixed 30-second interval
      
      // Store the forced refresh interval reference for cleanup
      return () => {
        clearInterval(forcedRefreshInterval);
      };
    });
  }, [fetchExtractions, shouldContinuePolling]);
  
  // Initialize and clean up
  useEffect(() => {
    mountedRef.current = true;
    
    // Track all intervals for cleanup
    const intervals: NodeJS.Timeout[] = [];
    
    // Initial fetch
    fetchExtractions();
    
    return () => {
      mountedRef.current = false;
      
      // Clean up polling
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
      }
      
      // Clean up any other intervals
      intervals.forEach(interval => clearInterval(interval));
      
      isPollingRef.current = false;
      pollStartTimeRef.current = null;
    };
  }, [fetchExtractions]);
  
  // Add a new job to the local state
  const addJob = useCallback((job: ExtractionJob) => {
    setExtractionJobs(prevJobs => {
      // Check if job already exists
      const existingJobIndex = prevJobs.findIndex(j => j.id === job.id);
      
      if (existingJobIndex >= 0) {
        // Update existing job
        const updatedJobs = [...prevJobs];
        updatedJobs[existingJobIndex] = job;
        return updatedJobs;
      } else {
        // Add new job at the beginning of the list
        return [job, ...prevJobs];
      }
    });
    
    // Increment total count if this is a new job
    setTotalJobs(prev => prev + 1);
    
    // Start polling if job is in progress
    if (job.status === 'pending' || job.status === 'processing') {
      if (!isPollingRef.current) {
        pollStartTimeRef.current = Date.now();
        retryCountRef.current = 0;
        startPolling();
      }
    }
  }, [startPolling]);
  
  // Update an existing job in the local state
  const updateJob = useCallback((jobId: string, updates: Partial<ExtractionJob>) => {
    setExtractionJobs(prevJobs => {
      const jobIndex = prevJobs.findIndex(job => job.id === jobId);
      
      if (jobIndex >= 0) {
        const updatedJobs = [...prevJobs];
        updatedJobs[jobIndex] = { ...updatedJobs[jobIndex], ...updates };
        return updatedJobs;
      }
      
      return prevJobs;
    });
  }, []);
  
  // Remove a job from the local state
  const removeJob = useCallback((jobId: string) => {
    setExtractionJobs(prevJobs => prevJobs.filter(job => job.id !== jobId));
    setTotalJobs(prev => Math.max(0, prev - 1));
  }, []);
  
  // Upload a file
  const uploadFile = useCallback(async (options: {
    file: File;
    customColumns?: string | string[];
    generatePreview?: boolean;
    selectedHeaders?: string[];
    orderedHeaders?: string[];
  }): Promise<string> => {
    const token = getAuthToken();
    if (!token) {
      throw new Error('Not authenticated');
    }
    
    const { file, customColumns, generatePreview, selectedHeaders, orderedHeaders } = options;
    
    try {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('file', file);
      
      // Add generatePreview option if provided
      if (generatePreview !== undefined) {
        formData.append('generatePreview', generatePreview ? '1' : '0');
      }
      
      // Add custom columns if provided
      if (customColumns) {
        // If it's already a string, send it directly
        if (typeof customColumns === 'string') {
          formData.append('customColumns', customColumns);
        } 
        // If it's an array, stringify it
        else if (Array.isArray(customColumns) && customColumns.length > 0) {
          formData.append('customColumns', JSON.stringify(customColumns));
        }
      }
      
      // Add selected headers if provided
      if (selectedHeaders && selectedHeaders.length > 0) {
        formData.append('selectedHeaders', JSON.stringify(selectedHeaders));
      }
      
      if (orderedHeaders && orderedHeaders.length > 0) {
        formData.append('orderedHeaders', JSON.stringify(orderedHeaders));
      }
      
      // Create an AbortController for timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
      
      // Call the API to upload the file
      const response = await fetch(`${API_BASE}/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
        credentials: 'include',
        signal: controller.signal,
      });
      
      // Clear the timeout
      clearTimeout(timeoutId);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({ message: `Server error: ${response.status}` }));
        throw new Error(errorData.message || 'Failed to upload file');
      }
      
      const data: ExtractionResponse = await response.json();
      
      if (!data.success || !data.extractionId) {
        throw new Error(data.error || 'Failed to upload file');
      }
      
      console.log(`File uploaded successfully with ID: ${data.extractionId}`);
      
      // Add a new job to the local state with pending status
      addJob({
        id: data.extractionId,
        userId: 'current', // We don't know the actual userId yet, will be updated on refresh
        fileName: file.name,
        fileType: file.type || 'application/octet-stream',
        status: 'pending',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        displayStatus: 'Pending',
        isProcessing: true,
        isPending: true,
        canDownload: false
      });
      
      // Start polling for updates
      if (!isPollingRef.current) {
        console.log('Starting polling after file upload');
        startPolling();
      } else {
        console.log('Polling already active, not starting again');
      }
      
      return data.extractionId;
    } catch (error) {
      console.error('Error uploading file:', error);
      throw error;
    }
  }, [addJob, startPolling]);
  
  // Download an extraction result
  const downloadExtraction = useCallback(async (jobId: string) => {
    try {
      await downloadExtractionResult(jobId);
    } catch (error) {
      console.error('Error downloading extraction:', error);
      throw error;
    }
  }, []);
  
  // Delete an extraction
  const deleteExtraction = useCallback(async (jobId: string) => {
    const token = getAuthToken();
    if (!token) {
      throw new Error('Not authenticated');
    }
    
    try {
      const response = await fetch(`${API_BASE}/${jobId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: 'include',
      });
      
      if (!response.ok) {
        const error = await response.json().catch(() => ({ message: `Server error: ${response.status}` }));
        throw new Error(error.message || 'Failed to delete extraction');
      }
      
      // Remove the job from the local state
      removeJob(jobId);
      
      return true;
    } catch (error) {
      console.error('Error deleting extraction:', error);
      throw error;
    }
  }, [removeJob]);
  
  // Manual refresh
  const refresh = useCallback(async () => {
    return fetchExtractions();
  }, [fetchExtractions]);
  
  return {
    jobs: extractionJobs,
    totalJobs,
    isLoading,
    error,
    uploadFile,
    downloadExtraction,
    deleteExtraction,
    refresh: fetchExtractions,
    refreshSingleJob,
    startPolling,
    stopPolling: () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
      }
      isPollingRef.current = false;
    },
    addJob,
    updateJob,
    removeJob,
    getPreviewData
  };
};

/**
 * Hook for uploading files for table extraction
 * @returns Mutation for uploading files
 */
export const useTableExtraction = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async ({ file, customColumns, generatePreview }: { 
      file: File; 
      customColumns?: string[]; 
      generatePreview?: boolean;
    }): Promise<ExtractionResponse> => {
      const token = getAuthToken();
      if (!token) {
        throw new Error('Not authenticated');
      }
      
      // Generate a unique processing ID to prevent duplicate processing
      const processingId = crypto.randomUUID();
      console.log(`Starting extraction with processing ID: ${processingId}`);
      
      // Create an AbortController for timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);
      
      // Define common fetch options with CORS mode explicitly set
      const fetchOptions = {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        credentials: 'include',
        signal: controller.signal,
        mode: 'cors' as RequestMode,
      };
      
      try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('processingId', processingId);
        
        if (customColumns && customColumns.length > 0) {
          formData.append('customColumns', JSON.stringify(customColumns));
        }
        
        if (generatePreview) {
          formData.append('generatePreview', 'true');
        }
        
        // Try multiple endpoints in sequence if the primary one fails
        // This helps with CORS issues by trying different approaches
        const endpoints = [
          `${API_BASE}/extract`,
          `${API_BASE.replace('/api', '')}/table-extraction/extract`
        ];
        
        let lastError: Error | null = null;
        
        for (const endpoint of endpoints) {
          try {
            console.log(`Attempting upload to endpoint: ${endpoint}`);
            const response = await fetch(endpoint, {
              ...fetchOptions,
              body: formData,
            });
            
            // Clear the timeout
            clearTimeout(timeoutId);
            
            if (!response.ok) {
              const errorData = await response.json().catch(() => ({ 
                message: `Server error: ${response.status}` 
              }));
              throw new Error(errorData.message || 'Failed to upload file');
            }
            
            const data: ExtractionResponse = await response.json();
            console.log('Extraction response:', data);
            
            if (!data.success) {
              throw new Error(data.error || data.message || 'Unknown error occurred');
            }
            
            return data;
          } catch (error) {
            console.error(`Error with endpoint ${endpoint}:`, error);
            lastError = error instanceof Error ? error : new Error(String(error));
            
            // If this is specifically a CORS error, try the next endpoint
            if (error instanceof TypeError && 
                (error.message.includes('Failed to fetch') || 
                 error.message.includes('NetworkError'))) {
              console.warn('CORS or network error detected, trying next endpoint...');
              continue;
            }
            
            // For other errors, don't try additional endpoints
            throw error;
          }
        }
        
        // If we've tried all endpoints and still have an error, throw the last one
        if (lastError) {
          throw lastError;
        }
        
        throw new Error('Failed to upload file after trying all endpoints');
      } catch (error) {
        // Clear the timeout if there's an error
        clearTimeout(timeoutId);
        
        console.error('Error in table extraction:', error);
        
        // Handle CORS errors specifically with a more helpful message
        if (error instanceof TypeError && 
            (error.message.includes('Failed to fetch') || 
             error.message.includes('NetworkError'))) {
          console.error('CORS or network error detected:', error);
          // Still invalidate the query to ensure UI updates even with errors
          queryClient.invalidateQueries({ queryKey: ['recentExtractions'] });
          throw new Error('Network error: Could not connect to the extraction service. This may be due to CORS restrictions or network issues. Please try again later.');
        }
        
        // Re-throw the original error
        throw error;
      }
    },
    onSuccess: () => {
      // Invalidate the recent extractions query to trigger a refresh
      queryClient.invalidateQueries({ queryKey: ['recentExtractions'] });
    },
    onError: (error) => {
      // Also invalidate on error to ensure the UI updates
      console.error('Error in table extraction mutation:', error);
      queryClient.invalidateQueries({ queryKey: ['recentExtractions'] });
    }
  });
};

/**
 * Hook for fetching recent extractions
 * @param limit Number of items to fetch
 * @param offset Offset for pagination
 * @param timestamp Cache-busting timestamp
 * @returns Query result with extraction jobs
 */
export const useRecentExtractions = (limit: number = 15, offset: number = 0, timestamp: number = Date.now()) => {
  // Use a simple approach that works with the existing types
  return useQuery<ExtractionsListResponse, Error>({
    queryKey: ['extractions', limit, offset, timestamp],
    queryFn: async () => {
      const token = getAuthToken();
      if (!token) {
        throw new Error('Not authenticated');
      }

      // Create an AbortController for timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);

      try {
        // Add a timestamp to prevent browser caching
        const response = await fetch(
          `${API_BASE}/list?limit=${limit}&offset=${offset}&_t=${timestamp}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Cache-Control': 'no-cache, no-store, must-revalidate',
              'Pragma': 'no-cache',
              'Expires': '0'
            },
            credentials: 'include',
            signal: controller.signal,
          }
        );

        // Clear the timeout
        clearTimeout(timeoutId);

        if (!response.ok) {
          const error = await response.json().catch(() => ({ message: `Server error: ${response.status}` }));
          throw new Error(error.message || 'Failed to fetch extractions');
        }

        const data: TableExtractionResponse = await response.json();
        
        // Transform the records
        const transformedJobs = data.jobs.map(record => transformRecord(record));
        
        return {
          jobs: transformedJobs,
          total: data.total,
          success: true
        };
      } catch (error) {
        // Handle abort errors specifically
        if (error instanceof DOMException && error.name === 'AbortError') {
          throw new Error('Request timed out. Please try again.');
        }
        
        // Log the error but don't throw to prevent UI disruption
        console.error('Error fetching extractions:', error);
        
        // Return empty data instead of throwing to prevent UI disruption
        // This helps reduce the refresh cycle when there are network issues
        if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
          console.warn('Network error when fetching extractions, returning empty data');
          return {
            jobs: [],
            total: 0,
            success: false
          };
        }
        
        throw error;
      }
    },
    // Use more conservative caching settings to reduce server load
    staleTime: 5000, // 5 seconds
    cacheTime: 30000, // 30 seconds
    refetchOnMount: true,
    refetchOnWindowFocus: false, // Don't refetch on window focus to reduce server load
    refetchInterval: 30000, // Refresh every 30 seconds for active jobs
    retry: 2, // Limit retries to reduce server load
    retryDelay: 3000, // 3 second delay between retries
  });
};

/**
 * Hook for deleting an extraction
 * @returns Mutation for deleting an extraction
 */
export const useDeleteExtraction = () => {
  return useMutation<void, Error, string>({
    mutationFn: async (extractionId: string) => {
      const token = getAuthToken();
      if (!token) {
        throw new Error('Not authenticated');
      }

      // Create an AbortController for timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);

      try {
        const response = await fetch(`${API_BASE}/${extractionId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: 'include',
          signal: controller.signal,
        });

        // Clear the timeout
        clearTimeout(timeoutId);

        if (!response.ok) {
          try {
            const error = await response.json();
            throw new Error(error.message || 'Failed to delete extraction');
          } catch (parseError) {
            throw new Error(`Failed to delete extraction: ${response.statusText || response.status}`);
          }
        }
      } catch (error) {
        // Handle abort errors specifically
        if (error instanceof DOMException && error.name === 'AbortError') {
          throw new Error('Request timed out. Please try again.');
        }
        
        throw error;
      }
    },
    retry: 1,
  });
};

/**
 * Download an extraction result
 * @param id Extraction ID
 */
export const downloadExtractionResult = async (id: string): Promise<void> => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('Not authenticated');
  }

  let retryCount = 0;
  const maxRetries = 3;
  const retryDelay = 1000; // 1 second

  const attemptDownload = async (): Promise<void> => {
    try {
      // Create an AbortController for timeout handling
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), REQUEST_TIMEOUT);

      console.log(`Attempting to download extraction ${id}`);

      // First try the direct download endpoint
      const response = await fetch(`${API_BASE}/${id}/download`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
        credentials: 'include',
        signal: controller.signal,
        mode: 'cors',
      });

      // Clear the timeout
      clearTimeout(timeoutId);

      if (!response.ok) {
        // Try to get error details from response
        try {
          const contentType = response.headers.get('Content-Type');
          if (contentType && contentType.includes('application/json')) {
            const errorData = await response.json();
            console.error('Server error response:', errorData);
            
            // Check for specific error codes
            if (errorData.code === 'MISSING_OUTPUT_FILE') {
              throw new Error(errorData.details || 'The extraction was marked as completed, but the output file is missing. Please try re-uploading the file.');
            }
            
            throw new Error(errorData.message || errorData.error || errorData.details || `Server error: ${response.status}`);
          } else {
            throw new Error(`Failed to download file: ${response.statusText || response.status}`);
          }
        } catch (parseError) {
          // If parsing fails, use status text
          console.error('Failed to parse error response:', parseError);
          throw new Error(`Failed to download file: ${response.statusText || response.status}`);
        }
      }

      // Check if the response is a blob or JSON
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        // Handle JSON response (which might contain error details or alternate download info)
        const jsonData = await response.json();
        console.log('JSON response from download endpoint:', jsonData);
        
        if (jsonData.error) {
          // Check for specific error about extraction result not available
          if (jsonData.error.includes('Extraction result not available') || 
              (jsonData.details && jsonData.details.includes('no results are available')) ||
              jsonData.code === 'MISSING_OUTPUT_FILE') {
            console.error('Extraction marked as completed but has no output file:', id);
            throw new Error(jsonData.details || 'The extraction is completed but the output file is not available. This might be due to a processing issue. Please try re-uploading the file or contact support if the issue persists.');
          }
          throw new Error(jsonData.error);
        }
        
        if (jsonData.success && jsonData.results) {
          // This is a special case where we got results but no file
          // We could implement a client-side Excel generation here
          console.warn('Received results but no file, should regenerate Excel client-side');
          
          // Instead of throwing an error, we could potentially generate the Excel file client-side
          // For now, we'll throw a more user-friendly error
          throw new Error('The extraction results are available but the Excel file needs to be regenerated. Please try again later or contact support.');
        }
        
        throw new Error('Unexpected response from the server. Please try again later or contact support.');
      }

      // Get the filename from the Content-Disposition header or use a default
      let filename = 'extracted_tables.xlsx';
      const contentDisposition = response.headers.get('Content-Disposition');
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="(.+?)"/);
        if (filenameMatch && filenameMatch[1]) {
          filename = filenameMatch[1];
        }
      }

      // Create a blob from the response
      const blob = await response.blob();
      
      // Validate the blob
      if (blob.size === 0) {
        throw new Error('Downloaded file is empty');
      }
      
      if (blob.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && 
          !blob.type.includes('excel')) {
        console.warn('Unexpected blob type:', blob.type);
      }

      // Create a download link and trigger the download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      
      // Small delay to ensure download starts before cleanup
      await new Promise(resolve => setTimeout(resolve, 100));
      
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      console.log('Download completed successfully:', {
        id,
        filename,
        size: blob.size,
        type: blob.type
      });
    } catch (error) {
      console.error('Download error:', error);
      
      // Handle abort errors specifically
      if (error instanceof DOMException && error.name === 'AbortError') {
        throw new Error('Download request timed out. Please try again.');
      }
      
      // Handle CORS errors
      if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
        console.error('Possible CORS error during download');
        
        // Retry logic for potential CORS issues
        if (retryCount < maxRetries) {
          retryCount++;
          const currentDelay = retryDelay * Math.pow(2, retryCount - 1); // Exponential backoff
          console.log(`Retrying download (${retryCount}/${maxRetries}) after delay of ${currentDelay}ms...`);
          await new Promise(resolve => setTimeout(resolve, currentDelay));
          return attemptDownload();
        } else {
          throw new Error('Failed to download file due to network issues. Please try again later.');
        }
      }
      
      // Check for specific error messages and provide more helpful guidance
      const errorMessage = error instanceof Error ? error.message : 'Failed to download file: Network error';
      
      if (errorMessage.includes('output file is not available') || 
          errorMessage.includes('output file is missing') ||
          errorMessage.includes('Excel file needs to be regenerated')) {
        // This is a known issue with a clear resolution path
        throw new Error(`${errorMessage} Please try re-uploading the file.`);
      }
      
      // For other errors, just rethrow
      throw error instanceof Error 
        ? error 
        : new Error('Failed to download file: Network error');
    }
  };
  
  return attemptDownload();
};
