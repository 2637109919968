import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { GripVertical } from 'lucide-react';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export interface ColumnSelectorProps {
  headers: string[];
  onSelectionChange: (selectedHeaders: string[], orderedHeaders: string[]) => void;
  initialSelection?: string[];
}

export function ColumnSelector({ 
  headers, 
  onSelectionChange,
  initialSelection 
}: ColumnSelectorProps) {
  // State for selected headers (checkboxes)
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>(initialSelection || headers);
  
  // State for ordered headers (drag and drop)
  const [orderedHeaders, setOrderedHeaders] = useState<string[]>(initialSelection || headers);

  // Update parent component when selection changes
  useEffect(() => {
    onSelectionChange(selectedHeaders, orderedHeaders);
  }, [selectedHeaders, orderedHeaders, onSelectionChange]);

  // Handle checkbox change
  const handleHeaderToggle = (header: string, checked: boolean) => {
    if (checked) {
      // Add header to selected list
      setSelectedHeaders(prev => [...prev, header]);
      
      // Also add to ordered list if not already there
      if (!orderedHeaders.includes(header)) {
        setOrderedHeaders(prev => [...prev, header]);
      }
    } else {
      // Remove header from selected list
      setSelectedHeaders(prev => prev.filter(h => h !== header));
    }
  };

  // Handle drag end
  const handleDragEnd = (result: any) => {
    // Drop outside the list
    if (!result.destination) {
      return;
    }

    // Reorder the headers
    const items = Array.from(orderedHeaders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setOrderedHeaders(items);
  };

  // Select all headers
  const selectAll = () => {
    setSelectedHeaders([...headers]);
  };

  // Deselect all headers
  const deselectAll = () => {
    setSelectedHeaders([]);
  };

  // Reset order to original
  const resetOrder = () => {
    const newOrder = headers.filter(header => selectedHeaders.includes(header));
    setOrderedHeaders(newOrder);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Select and Order Columns</h3>
        <div className="space-x-2">
          <Button variant="outline" size="sm" onClick={selectAll}>
            Select All
          </Button>
          <Button variant="outline" size="sm" onClick={deselectAll}>
            Deselect All
          </Button>
          <Button variant="outline" size="sm" onClick={resetOrder}>
            Reset Order
          </Button>
        </div>
      </div>

      <div className="border rounded-md p-4 bg-muted/20">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="headers">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-2"
              >
                {orderedHeaders.map((header, index) => (
                  <Draggable key={header} draggableId={header} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={`flex items-center justify-between p-2 rounded-md border ${
                          selectedHeaders.includes(header) ? 'bg-white' : 'bg-muted/30'
                        }`}
                      >
                        <div className="flex items-center space-x-3">
                          <div {...provided.dragHandleProps}>
                            <GripVertical className="h-5 w-5 text-muted-foreground" />
                          </div>
                          <div className="flex items-center space-x-2">
                            <Checkbox
                              id={`header-${index}`}
                              checked={selectedHeaders.includes(header)}
                              onCheckedChange={(checked) => 
                                handleHeaderToggle(header, checked === true)
                              }
                            />
                            <Label htmlFor={`header-${index}`} className="font-medium">
                              {header}
                            </Label>
                          </div>
                        </div>
                        {selectedHeaders.includes(header) && (
                          <div className="text-xs text-muted-foreground">
                            {index + 1}
                          </div>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <div className="text-sm text-muted-foreground">
        <p>Drag and drop columns to reorder them. Only selected columns will be included in the output.</p>
      </div>
    </div>
  );
} 