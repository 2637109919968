import { useEffect, useState } from 'react';
import {
    ChatMessage,
    ChatResponse,
    DocumentContext,
    DocumentContextResponse,
} from '../types/chat';
import { fetchApi, uploadFile } from '../utils/fetch';

export function useChatWithContext(documentContext: DocumentContext | null) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);

  const sendMessage = async (content: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const userMessage: ChatMessage = {
        role: 'user',
        content,
        timestamp: new Date(),
      };

      setMessages(prev => [...prev, userMessage]);

      // Create a session if we don't have one
      if (!sessionId) {
        const sessionResponse = await fetchApi<{ sessionId: string; title: string }>('chat/sessions', {
          method: 'POST',
          body: JSON.stringify({ title: 'Document Chat' }),
        });
        
        if (sessionResponse.sessionId) {
          setSessionId(sessionResponse.sessionId);
        } else {
          throw new Error('Failed to create chat session');
        }
      }

      // Now we should have a sessionId
      const formData = new FormData();
      formData.append('content', content);
      
      if (documentContext) {
        formData.append('context', JSON.stringify(documentContext));
      }

      const response = await uploadFile<ChatResponse>(`chat/sessions/${sessionId}/messages`, formData);

      console.log('Chat API Response:', {
        full: response,
        success: response.success,
        assistantMessageId: response.assistantMessageId,
        userMessageId: response.userMessageId,
        response: response.response,
        message: response.message,
        documentId: response.documentId,
        error: response.error
      });

      if (response.userMessageId) {
        const assistantMessage: ChatMessage = {
          id: response.assistantMessageId,
          role: 'assistant',
          content: response.response,
          timestamp: new Date(),
        };

        // Update user message with ID
        setMessages(prev => 
          prev.map((msg, idx) => 
            idx === prev.length - 1 ? { ...msg, id: response.userMessageId } : msg
          )
        );
        
        setMessages(prev => [...prev, assistantMessage]);
      } else {
        setError(response.error || 'Failed to get response');
      }
    } catch (error) {
      setError(
        error instanceof Error ? error.message : 'Failed to send message'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearMessages = () => {
    setMessages([]);
    setError(null);
  };

  return {
    messages,
    isLoading,
    error,
    sendMessage,
    clearMessages,
    sessionId,
  };
}

export function useDocumentContext() {
  const [context, setContext] = useState<DocumentContext | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const uploadDocument = async (file: File) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await uploadFile<DocumentContextResponse>(
        'doc-check/upload',
        file
      );
      setContext(response.context);
    } catch (error) {
      setError(
        error instanceof Error ? error.message : 'Failed to upload document'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const clearContext = () => {
    setContext(null);
    setError(null);
  };

  return {
    context,
    isLoading,
    error,
    uploadDocument,
    clearContext,
  };
}

export interface ChatSession {
  id: string;
  title: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export function useChatSessions() {
  const [sessions, setSessions] = useState<ChatSession[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchSessions = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetchApi<{ sessions: ChatSession[] }>('chat/sessions');
      if (response.sessions) {
        // Convert ISO strings to Date objects for display
        setSessions(response.sessions.map(session => ({
          ...session,
          createdAt: session.createdAt,
          updatedAt: session.updatedAt
        })));
      } else {
        setError('Failed to fetch sessions');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to fetch sessions');
    } finally {
      setIsLoading(false);
    }
  };

  const createSession = async (title?: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetchApi<{ sessionId: string; title: string }>('chat/sessions', {
        method: 'POST',
        body: JSON.stringify({ title: title || 'New Chat' }),
      });

      if (response.sessionId) {
        // Refresh sessions after creating a new one
        await fetchSessions();
        return response.sessionId;
      } else {
        setError('Failed to create session');
        return null;
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to create session');
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const updateSession = async (sessionId: string, title: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetchApi<{ success: boolean }>(`chat/sessions/${sessionId}`, {
        method: 'PATCH',
        body: JSON.stringify({ title }),
      });

      if (response.success) {
        // Refresh sessions after updating
        await fetchSessions();
        return true;
      } else {
        setError('Failed to update session');
        return false;
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to update session');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSession = async (sessionId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetchApi<{ success: boolean }>(`chat/sessions/${sessionId}`, {
        method: 'DELETE',
      });

      if (response.success) {
        // Refresh sessions after deleting
        await fetchSessions();
        return true;
      } else {
        setError('Failed to delete session');
        return false;
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to delete session');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // Archive a chat session (update status to 'archived')
  const archiveSession = async (sessionId: string) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetchApi<{ success: boolean }>(`chat/sessions/${sessionId}/archive`, {
        method: 'POST',
      });

      if (response.success) {
        // Refresh sessions after archiving
        await fetchSessions();
        return true;
      } else {
        setError('Failed to archive session');
        return false;
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to archive session');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch sessions on mount
  useEffect(() => {
    fetchSessions();
  }, []);

  return {
    sessions,
    isLoading,
    error,
    fetchSessions,
    createSession,
    updateSession,
    deleteSession,
    archiveSession,
  };
}

export function useChat(sessionId: string | null) {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchMessages = async () => {
    if (!sessionId) return;

    setIsLoading(true);
    setError(null);

    try {
      const response = await fetchApi<{ messages: any[] }>(`chat/sessions/${sessionId}/messages`);
      
      if (response.messages) {
        // Convert ISO strings to Date objects
        setMessages(response.messages.map(msg => ({
          ...msg,
          timestamp: new Date(msg.timestamp),
        })));
      } else {
        setError('Failed to fetch messages');
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to fetch messages');
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessage = async (content: string) => {
    if (!sessionId) return;
    
    setIsLoading(true);
    setError(null);

    try {
      // Add user message immediately for better UX
      const userMessageId = crypto.randomUUID();
      const userMessage: ChatMessage = {
        id: userMessageId,
        role: 'user',
        content,
        timestamp: new Date(),
      };
      
      setMessages(prev => [...prev, userMessage]);
      
      // Create FormData for the request
      const formData = new FormData();
      formData.append('content', content);
      formData.append('sessionId', sessionId);
      
      // Send the message to the server using uploadFile which handles multipart/form-data
      const response = await uploadFile<ChatResponse>(
        `chat/sessions/${sessionId}/messages`,
        formData
      );
      
      console.log('Chat API Response:', {
        full: response,
        success: response.success,
        assistantMessageId: response.assistantMessageId,
        userMessageId: response.userMessageId,
        response: response.response,
        message: response.message,
        documentId: response.documentId,
        error: response.error
      });
      
      // Check if we have a valid response
      if (response.assistantMessageId) {
        // Get the response content - check both response and message fields
        const responseContent = response.response || response.message;
        
        if (responseContent) {
          const assistantMessage: ChatMessage = {
            id: response.assistantMessageId,
            role: 'assistant',
            content: responseContent,
            timestamp: new Date(),
            documentId: response.documentId,
          };
          
          // Add the assistant message to state
          setMessages(prev => [...prev, assistantMessage]);
        } else {
          console.error('Empty response from AI:', response);
          setError('Received empty response from AI');
        }
      } else if (!response.success) {
        setError(response.error || 'Failed to send message');
        // Remove the user message since the send failed
        setMessages(prev => prev.filter(msg => msg.id !== userMessageId));
      }
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to send message');
      console.error('Chat message error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Delete a message
  const deleteMessage = async (messageId: string) => {
    if (!sessionId || !messageId) return false;
    
    setIsLoading(true);
    setError(null);

    try {
      console.log('Deleting message:', {
        sessionId,
        messageId,
        endpoint: `chat/sessions/${sessionId}/messages/${messageId}`
      });

      const response = await fetchApi<{ success: boolean }>(`chat/sessions/${sessionId}/messages/${messageId}`, {
        method: 'DELETE',
      });

      console.log('Delete message response:', response);

      if (response.success) {
        // Remove the message from the local state
        setMessages(prev => prev.filter(msg => msg.id !== messageId));
        return true;
      } else {
        setError('Failed to delete message');
        return false;
      }
    } catch (error) {
      console.error('Error deleting message:', error);
      setError(error instanceof Error ? error.message : 'Failed to delete message');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // Define the extended ChatResponse type for edit message response
  interface EditMessageResponse {
    success: boolean;
    assistantMessageId?: string;
    response?: string;
    error?: string;
    documentId?: string;
  }

  // Define the file upload response type
  interface FileUploadResponse {
    success: boolean;
    documentId?: string;
    error?: string;
  }

  // Edit a message
  const editMessage = async (messageId: string, content: string) => {
    if (!sessionId) return false;
    
    setIsLoading(true);
    setError(null);

    try {
      // Find the message to edit
      const messageToEdit = messages.find(m => m.id === messageId);
      if (!messageToEdit) {
        setError('Message not found');
        return false;
      }

      // Find the index of the message to edit
      const messageIndex = messages.findIndex(m => m.id === messageId);
      
      // Check if there's an assistant message after this one that needs to be removed
      let assistantMessageToRemove = null;
      if (messageIndex < messages.length - 1 && messages[messageIndex + 1].role === 'assistant') {
        assistantMessageToRemove = messages[messageIndex + 1];
      }

      // Update the message in the local state immediately for better UX
      const updatedMessages = [...messages];
      updatedMessages[messageIndex] = {
        ...messageToEdit,
        content: content
      };
      
      // If there's an assistant message to remove, remove it from the local state
      if (assistantMessageToRemove) {
        updatedMessages.splice(messageIndex + 1, 1);
      }
      
      // Update the local state
      setMessages(updatedMessages);

      console.log('Editing message:', {
        sessionId,
        messageId,
        content,
        endpoint: `chat/sessions/${sessionId}/messages/${messageId}`
      });

      // Send the edit request to the server
      const response = await fetchApi<EditMessageResponse>(
        `chat/sessions/${sessionId}/messages/${messageId}`,
        {
          method: 'PATCH',
          body: JSON.stringify({ content }),
        }
      );

      console.log('Edit message response:', response);

      if (response.success) {
        // If the server returned an assistant message, add it to the local state
        if (response.assistantMessageId && response.response) {
          const assistantMessage: ChatMessage = {
            id: response.assistantMessageId,
            role: 'assistant',
            content: response.response,
            timestamp: new Date(),
            documentId: response.documentId,
          };
          
          // Add the assistant message to state
          setMessages(prev => {
            // Make sure we're adding the assistant message after the edited user message
            const updatedIndex = prev.findIndex(m => m.id === messageId);
            if (updatedIndex === -1) return [...prev, assistantMessage];
            
            const newMessages = [...prev];
            // Insert the assistant message after the user message
            newMessages.splice(updatedIndex + 1, 0, assistantMessage);
            return newMessages;
          });
        }
        
        return true;
      } else {
        setError(response.error || 'Failed to edit message');
        return false;
      }
    } catch (error) {
      console.error('Error editing message:', error);
      setError(error instanceof Error ? error.message : 'Failed to edit message');
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  // Export chat history as JSON
  const exportChat = async () => {
    if (!sessionId) return null;
    
    try {
      // Get the current session details
      const sessionResponse = await fetchApi<{ session: ChatSession }>(`chat/sessions/${sessionId}`);
      
      if (!sessionResponse.session) {
        setError('Failed to fetch session details');
        return null;
      }
      
      // Format the chat data for export
      const exportData = {
        session: sessionResponse.session,
        messages: messages.map(msg => ({
          id: msg.id,
          role: msg.role,
          content: msg.content,
          timestamp: msg.timestamp,
        })),
        exportedAt: new Date().toISOString(),
      };
      
      return exportData;
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to export chat');
      return null;
    }
  };

  // Fetch messages when sessionId changes
  useEffect(() => {
    if (sessionId) {
      fetchMessages();
    } else {
      setMessages([]);
    }
  }, [sessionId]);

  return {
    messages,
    isLoading,
    error,
    sendMessage,
    fetchMessages,
    deleteMessage,
    editMessage,
    exportChat,
  };
}
