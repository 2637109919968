import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Skeleton } from '@/components/ui/skeleton';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { formatPermission, getUserPermissions } from '@/lib/permissions';
import { formatDate } from '@/lib/utils';
import { User, UserUpdateData } from '@/types/user';
import {
    Calendar,
    KeyRound,
    Loader2,
    Mail,
    MoreHorizontal,
    Shield,
    Trash2,
    UserCog,
    User as UserIcon
} from 'lucide-react';
import { useState } from 'react';
import { EditUserDialog } from './EditUserDialog';
import { UpdatePasswordDialog } from './UpdatePasswordDialog';

interface UsersTableProps {
  users: User[];
  onDeleteUser: (id: string) => Promise<boolean>;
  onUpdateUser: (userData: UserUpdateData) => Promise<boolean>;
  onUpdatePassword: (id: string, password: string) => Promise<boolean>;
  isLoading?: boolean;
}

export function UsersTable({
  users,
  onDeleteUser,
  onUpdateUser,
  onUpdatePassword,
  isLoading = false,
}: UsersTableProps) {
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [passwordUser, setPasswordUser] = useState<User | null>(null);
  const [deletingUser, setDeletingUser] = useState<User | null>(null);
  const [actionLoading, setActionLoading] = useState<string | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  const handleDialogChange =
    (setter: (user: User | null) => void) => (open: boolean) => {
      if (!open) {
        setter(null);
      }
    };

  const handleDeleteConfirm = async () => {
    if (!userToDelete) return;
    
    setActionLoading(userToDelete.id);
    try {
      const success = await onDeleteUser(userToDelete.id);
      if (success) {
        setDeleteConfirmOpen(false);
        setUserToDelete(null);
      }
    } finally {
      setActionLoading(null);
    }
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        {Array(5).fill(0).map((_, i) => (
          <div key={i} className="flex items-center space-x-4 py-4">
            <Skeleton className="h-12 w-12 rounded-full" />
            <div className="space-y-2">
              <Skeleton className="h-4 w-[250px]" />
              <Skeleton className="h-4 w-[200px]" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="whitespace-nowrap px-3 py-3.5">
                  User
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5 hidden md:table-cell">
                  Email
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5 hidden lg:table-cell">
                  Role
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5 hidden xl:table-cell">
                  Permissions
                </TableHead>
                <TableHead className="whitespace-nowrap px-3 py-3.5 hidden lg:table-cell">
                  Created
                </TableHead>
                <TableHead className="relative whitespace-nowrap px-3 py-3.5">
                  <span className="sr-only">Actions</span>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users.map(user => (
                <TableRow key={user.id} className="hover:bg-muted/50">
                  <TableCell className="px-3 py-4">
                    <div className="flex items-center">
                      <div className="h-10 w-10 flex-shrink-0 rounded-full bg-primary/10 flex items-center justify-center">
                        <UserIcon className="h-5 w-5 text-primary" />
                      </div>
                      <div className="ml-4">
                        <div className="font-medium">{user.name}</div>
                        <div className="text-sm text-muted-foreground">@{user.username}</div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell className="whitespace-nowrap px-3 py-4 hidden md:table-cell">
                    <div className="flex items-center">
                      <Mail className="h-4 w-4 mr-2 text-muted-foreground" />
                      <span>{user.email}</span>
                    </div>
                  </TableCell>
                  <TableCell className="whitespace-nowrap px-3 py-4 hidden lg:table-cell">
                    <Badge
                      variant={user.role === 'admin' ? 'default' : 'secondary'}
                      className="flex items-center gap-1 capitalize"
                    >
                      {user.role === 'admin' ? <Shield className="h-3 w-3" /> : <UserIcon className="h-3 w-3" />}
                      {user.role}
                    </Badge>
                  </TableCell>
                  <TableCell className="px-3 py-4 hidden xl:table-cell">
                    <div className="flex flex-wrap gap-1 max-w-md">
                      {getUserPermissions(user).length > 0 ? (
                        getUserPermissions(user).map((permission, index) => (
                          <Badge
                            key={`${user.id}-${permission}-${index}`}
                            variant="outline"
                            className="text-xs"
                          >
                            {formatPermission(permission)}
                          </Badge>
                        ))
                      ) : (
                        <span className="text-sm text-muted-foreground">No permissions</span>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="whitespace-nowrap px-3 py-4 hidden lg:table-cell">
                    <div className="flex items-center">
                      <Calendar className="h-4 w-4 mr-2 text-muted-foreground" />
                      <span>{formatDate(user.created_at)}</span>
                    </div>
                  </TableCell>
                  <TableCell className="relative whitespace-nowrap px-3 py-4">
                    {/* Desktop view - show icons directly */}
                    <div className="hidden md:flex items-center justify-end space-x-2">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => setEditingUser(user)}
                              className="h-8 w-8"
                              disabled={actionLoading === user.id}
                              aria-label="Edit user"
                            >
                              {actionLoading === user.id ? (
                                <Loader2 className="h-4 w-4 animate-spin" />
                              ) : (
                                <UserCog className="h-4 w-4" />
                              )}
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Edit user</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>

                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => setPasswordUser(user)}
                              className="h-8 w-8"
                              disabled={actionLoading === user.id}
                              aria-label="Update password"
                            >
                              <KeyRound className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Update password</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>

                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={() => {
                                setUserToDelete(user);
                                setDeleteConfirmOpen(true);
                              }}
                              className="h-8 w-8 text-destructive hover:text-destructive hover:bg-destructive/10"
                              disabled={actionLoading === user.id}
                              aria-label="Delete user"
                            >
                              <Trash2 className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Delete user</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>

                    {/* Mobile view - show dropdown */}
                    <div className="md:hidden">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button 
                            variant="ghost" 
                            className="h-8 w-8 p-0"
                            disabled={actionLoading === user.id}
                          >
                            {actionLoading === user.id ? (
                              <Loader2 className="h-4 w-4 animate-spin" />
                            ) : (
                              <>
                                <span className="sr-only">Open menu</span>
                                <MoreHorizontal className="h-4 w-4" />
                              </>
                            )}
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel>User Actions</DropdownMenuLabel>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem
                            onClick={() => setEditingUser(user)}
                            disabled={actionLoading === user.id}
                          >
                            <UserCog className="mr-2 h-4 w-4" />
                            Edit user
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            onClick={() => setPasswordUser(user)}
                            disabled={actionLoading === user.id}
                          >
                            <KeyRound className="mr-2 h-4 w-4" />
                            Update password
                          </DropdownMenuItem>
                          <DropdownMenuItem
                            className="text-destructive focus:bg-destructive/10"
                            onClick={() => {
                              setUserToDelete(user);
                              setDeleteConfirmOpen(true);
                            }}
                            disabled={actionLoading === user.id}
                          >
                            <Trash2 className="mr-2 h-4 w-4" />
                            Delete user
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {users.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} className="h-24 text-center">
                    No users found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>

      {editingUser && (
        <EditUserDialog
          user={editingUser}
          onUpdateUser={onUpdateUser}
          open={!!editingUser}
          onOpenChange={handleDialogChange(setEditingUser)}
        />
      )}

      {passwordUser && (
        <UpdatePasswordDialog
          userId={passwordUser.id}
          onUpdatePassword={onUpdatePassword}
          open={!!passwordUser}
          onOpenChange={handleDialogChange(setPasswordUser)}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the user
              <strong className="font-semibold"> {userToDelete?.name} </strong>
              and remove their access to the system.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteConfirm}
              className="bg-destructive hover:bg-destructive/90"
              disabled={actionLoading === userToDelete?.id}
            >
              {actionLoading === userToDelete?.id ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                <>
                  <Trash2 className="mr-2 h-4 w-4" />
                  Delete User
                </>
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
