import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea } from '@/components/ui/scroll-area';
import { cn } from '@/lib/utils';
import { Archive, Download, Edit, Loader2, MessageSquare, MoreVertical, Plus, Trash2 } from 'lucide-react';
import React from 'react';
import { ChatSidebarProps } from './types';

/**
 * Chat sidebar component that displays a list of chat sessions
 */
export const ChatSidebar: React.FC<ChatSidebarProps> = ({
  sessions,
  isLoading,
  activeSessionId,
  onSessionSelect,
  onNewSession,
  onEditSession,
  onArchiveSession,
  onExportSession,
  onDeleteSession,
  formatDate
}) => {
  return (
    <Card className="lg:col-span-1 h-[700px] flex flex-col">
      <CardHeader>
        <CardTitle>Chat History</CardTitle>
        <CardDescription>
          Your previous conversations
        </CardDescription>
      </CardHeader>
      <CardContent className="flex-grow overflow-hidden">
        <ScrollArea className="h-full pr-4">
          {isLoading ? (
            <div className="flex items-center justify-center h-full">
              <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
            </div>
          ) : sessions.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-center text-muted-foreground">
              <MessageSquare className="h-12 w-12 mb-4" />
              <p>No chat sessions yet</p>
              <p className="text-sm mt-2">
                Start a new conversation by clicking the "New Chat" button
              </p>
            </div>
          ) : (
            <div className="space-y-2">
              {sessions.map((session) => (
                <div
                  key={session.id}
                  className={cn(
                    "p-3 rounded-lg cursor-pointer hover:bg-muted flex justify-between items-center group",
                    activeSessionId === session.id && "bg-muted",
                    session.status === 'archived' && "opacity-60"
                  )}
                  onClick={() => onSessionSelect(session.id)}
                >
                  <div className="flex items-center space-x-3 overflow-hidden">
                    <MessageSquare className="h-5 w-5 flex-shrink-0" />
                    <div className="overflow-hidden">
                      <p className="font-medium truncate">
                        {session.title}
                        {session.status === 'archived' && " (Archived)"}
                      </p>
                      <p className="text-xs text-muted-foreground truncate">
                        {formatDate(session.updatedAt)}
                      </p>
                    </div>
                  </div>
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button
                        variant="ghost"
                        size="icon"
                        className="h-8 w-8 opacity-0 group-hover:opacity-100"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <MoreVertical className="h-4 w-4" />
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditSession(session.id, session.title);
                        }}
                      >
                        <Edit className="h-4 w-4 mr-2" />
                        Rename
                      </DropdownMenuItem>
                      {session.status !== 'archived' && (
                        <DropdownMenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            onArchiveSession(session.id);
                          }}
                        >
                          <Archive className="h-4 w-4 mr-2" />
                          Archive
                        </DropdownMenuItem>
                      )}
                      <DropdownMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onExportSession(session.id);
                        }}
                      >
                        <Download className="h-4 w-4 mr-2" />
                        Export
                      </DropdownMenuItem>
                      <DropdownMenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteSession(session.id);
                        }}
                        className="text-destructive"
                      >
                        <Trash2 className="h-4 w-4 mr-2" />
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </div>
              ))}
            </div>
          )}
        </ScrollArea>
      </CardContent>
      <CardFooter className="border-t pt-4">
        <Button
          variant="outline"
          className="w-full"
          onClick={onNewSession}
        >
          <Plus className="h-4 w-4 mr-2" />
          New Chat
        </Button>
      </CardFooter>
    </Card>
  );
}; 