import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { Upload } from 'lucide-react';
import React, { useEffect, useRef, useState } from 'react';

interface HSFileUploadAreaProps {
  selectedFile: File | null;
  isUploading: boolean;
  onFileSelect: (file: File | null) => void;
}

export function HSFileUploadArea({
  selectedFile,
  isUploading,
  onFileSelect,
}: HSFileUploadAreaProps) {
  const { toast } = useToast();
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dragCounter = useRef(0);
  const dropZoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const preventDefaults = (e: Event) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDragEnter = (e: DragEvent) => {
      preventDefaults(e);
      dragCounter.current += 1;
      if (e.dataTransfer?.items && e.dataTransfer.items.length > 0) {
        setIsDragging(true);
      }
    };

    const handleDragLeave = (e: DragEvent) => {
      preventDefaults(e);
      dragCounter.current -= 1;
      if (dragCounter.current === 0) {
        setIsDragging(false);
      }
    };

    const handleDragOver = (e: DragEvent) => {
      preventDefaults(e);
    };

    const handleDrop = (e: DragEvent) => {
      preventDefaults(e);
      dragCounter.current = 0;
      setIsDragging(false);
      
      if (isUploading) return;
      
      if (e.dataTransfer?.files && e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (validateExcelFile(file)) {
          onFileSelect(file);
        }
      }
    };

    const dropZone = dropZoneRef.current;
    if (dropZone) {
      dropZone.addEventListener('dragenter', handleDragEnter);
      dropZone.addEventListener('dragleave', handleDragLeave);
      dropZone.addEventListener('dragover', handleDragOver);
      dropZone.addEventListener('drop', handleDrop);
      
      return () => {
        dropZone.removeEventListener('dragenter', handleDragEnter);
        dropZone.removeEventListener('dragleave', handleDragLeave);
        dropZone.removeEventListener('dragover', handleDragOver);
        dropZone.removeEventListener('drop', handleDrop);
      };
    }
  }, [isUploading, onFileSelect]);

  const handleClick = () => {
    if (isUploading) return;
    fileInputRef.current?.click();
  };

  const validateExcelFile = (file: File): boolean => {
    const validExcelTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'text/csv'
    ];
    
    if (!validExcelTypes.includes(file.type)) {
      toast({
        title: 'Invalid file type',
        description: 'Please upload an Excel file (.xlsx, .xls, .csv)',
        variant: 'destructive',
      });
      return false;
    }
    return true;
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && validateExcelFile(file)) {
      onFileSelect(file);
    } else if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset input if validation fails
    }
  };

  const handleClearFile = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (typeof onFileSelect === 'function') {
      onFileSelect(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  return (
    <div
      ref={dropZoneRef}
      className={cn(
        'border-2 border-dashed rounded-lg p-8 text-center transition-all duration-200 ease-in-out',
        isDragging
          ? 'border-primary bg-primary/10 scale-[1.02]'
          : 'border-gray-300 hover:border-primary hover:bg-gray-50',
        isUploading ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
      )}
      onClick={handleClick}
    >
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept=".xlsx,.xls,.csv"
        onChange={handleFileInputChange}
        disabled={isUploading}
        title="Upload Excel file"
      />
      
      <div className="flex flex-col items-center justify-center gap-4">
        <Upload className="h-12 w-12 text-gray-400" />
        
        {selectedFile ? (
          <div className="space-y-2">
            <p className="text-sm font-medium">{selectedFile.name}</p>
            <p className="text-xs text-gray-500">
              {(selectedFile.size / 1024 / 1024).toFixed(2)} MB
            </p>
            {!isUploading && (
              <button
                type="button"
                onClick={handleClearFile}
                className="text-xs text-red-500 hover:text-red-700 font-medium"
              >
                Remove
              </button>
            )}
          </div>
        ) : (
          <div className="space-y-1">
            <p className="text-sm font-medium">
              {isDragging ? 'Drop your file here' : 'Drag & drop your Excel file here'}
            </p>
            <p className="text-xs text-gray-500">
              or click to browse (.xlsx, .xls, .csv)
            </p>
          </div>
        )}
        
        {isUploading && (
          <div className="mt-2">
            <div className="animate-pulse text-sm text-primary">Uploading...</div>
          </div>
        )}
      </div>
    </div>
  );
}
