import { getStoredAuthState } from './auth-store';
import { getApiUrl } from './config';

interface RequestOptions extends RequestInit {
  headers?: HeadersInit;
  priority?: 'high' | 'low' | 'auto';
  timeout?: number;
}

interface ApiClientOptions {
  onError?: (error: Error) => void;
}

class ApiClient {
  private baseUrl: string;
  private options: ApiClientOptions;
  private pendingRequests: Map<string, Promise<any>> = new Map();

  constructor(options: ApiClientOptions = {}) {
    this.baseUrl = getApiUrl();
    this.options = options;
  }

  private async request<T>(
    endpoint: string,
    options: RequestOptions = {}
  ): Promise<T> {
    const { token } = getStoredAuthState();
    const headers = new Headers(options.headers || {});
    const isChatRequest = endpoint.includes('/chat/') || endpoint.includes('/sessions/');
    
    // Set appropriate timeout based on request type
    const timeout = options.timeout || (isChatRequest ? 15000 : 30000); // 15s for chat, 30s for others

    if (!headers.has('Content-Type') && !(options.body instanceof FormData)) {
      headers.set('Content-Type', 'application/json');
    }

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    
    // Add cache control headers to prevent caching
    headers.set('Cache-Control', 'no-cache, no-store, must-revalidate');
    headers.set('Pragma', 'no-cache');
    headers.set('Expires', '0');
    
    // Add a timestamp to prevent caching
    const cacheBuster = `_t=${Date.now()}`;

    try {
      const normalizedEndpoint = endpoint.startsWith('/')
        ? endpoint.slice(1)
        : endpoint;
        
      // Add cache buster to URL
      const urlWithCacheBuster = normalizedEndpoint.includes('?') 
        ? `${normalizedEndpoint}&${cacheBuster}`
        : `${normalizedEndpoint}?${cacheBuster}`;
        
      const url = `${this.baseUrl}/${urlWithCacheBuster}`;

      // Check if we already have a pending request for this exact URL (for GET requests)
      const requestKey = options.method === 'GET' ? url : `${url}-${Date.now()}`;
      const existingRequest = this.pendingRequests.get(requestKey);
      
      if (existingRequest && options.method === 'GET') {
        console.log('Reusing existing request for:', url);
        return existingRequest;
      }

      console.log('Making API request to:', url, {
        method: options.method,
        priority: options.priority || 'auto',
        timeout
      });
      
      // Use AbortController to set a timeout
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), timeout);
      
      // Set fetch priority if supported
      const fetchOptions: RequestInit = {
        ...options,
        headers,
        credentials: 'include',
        mode: 'cors',
        signal: controller.signal
      };
      
      // Add priority hint if available in the browser
      if (options.priority && 'priority' in fetchOptions) {
        (fetchOptions as any).priority = options.priority;
      }
      
      // Create the fetch promise
      const fetchPromise = (async () => {
        try {
          const response = await fetch(url, fetchOptions);
          
          // Clear the timeout
          clearTimeout(timeoutId);

          if (!response.ok) {
            const contentType = response.headers.get('content-type');
            let errorMessage = `Request failed with status ${response.status}`;

            if (contentType?.includes('application/json')) {
              try {
                const errorData = await response.json();
                errorMessage = errorData.message || errorData.error || errorMessage;
              } catch {
                // If JSON parsing fails, use the default error message
              }
            }

            throw new Error(errorMessage);
          }

          const contentType = response.headers.get('content-type');
          if (contentType?.includes('application/json')) {
            return response.json();
          }

          return response as unknown as T;
        } finally {
          // Remove from pending requests when done
          this.pendingRequests.delete(requestKey);
        }
      })();
      
      // Store the promise for potential reuse
      this.pendingRequests.set(requestKey, fetchPromise);
      
      return fetchPromise;
    } catch (error) {
      console.error('API request error:', error);
      throw error instanceof Error ? error : new Error('Network error');
    }
  }

  async get<T>(endpoint: string, options: Omit<RequestOptions, 'method'> = {}): Promise<T> {
    return this.request<T>(endpoint, { ...options, method: 'GET' });
  }

  async post<T>(endpoint: string, data?: unknown, options: Omit<RequestOptions, 'method' | 'body'> = {}): Promise<T> {
    const isChatRequest = endpoint.includes('/chat/') || endpoint.includes('/sessions/');
    
    // Create a copy of options to avoid modifying the original
    const modifiedOptions = { ...options };
    
    // Convert 'normal' priority to 'auto' to ensure compatibility
    if ((modifiedOptions as any).priority === 'normal') {
      modifiedOptions.priority = 'auto';
    }
    
    return this.request<T>(endpoint, {
      ...modifiedOptions,
      method: 'POST',
      body: data instanceof FormData ? data : JSON.stringify(data),
      // Set high priority for chat requests
      priority: isChatRequest ? 'high' : modifiedOptions.priority || 'auto',
      // Set shorter timeout for chat requests
      timeout: isChatRequest ? 15000 : modifiedOptions.timeout
    });
  }

  async put<T>(endpoint: string, data?: unknown, options: Omit<RequestOptions, 'method' | 'body'> = {}): Promise<T> {
    return this.request<T>(endpoint, {
      ...options,
      method: 'PUT',
      body: data instanceof FormData ? data : JSON.stringify(data),
    });
  }

  async patch<T>(endpoint: string, data?: unknown, options: Omit<RequestOptions, 'method' | 'body'> = {}): Promise<T> {
    return this.request<T>(endpoint, {
      ...options,
      method: 'PATCH',
      body: data instanceof FormData ? data : JSON.stringify(data),
    });
  }

  async delete<T>(endpoint: string, options: Omit<RequestOptions, 'method'> = {}): Promise<T> {
    return this.request<T>(endpoint, { ...options, method: 'DELETE' });
  }
}

// Create and export a singleton instance
export const apiClient = new ApiClient();
