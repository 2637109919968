import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../../../hooks/useApi';
import type {
    BulkHSLookupRequest,
    BulkHSLookupResponse,
    BulkHSLookupStatus,
    HSDeleteRequest,
    HSDocumentsResponse,
    HSImportRequest,
    HSImportResponse,
    HSLookupFormValues,
    HSLookupResponse,
    RecentBulkUpload
} from '../types/hs-lookup';

const HS_DOCUMENTS_KEY = ['hs-documents'] as const;
const RECENT_UPLOADS_KEY = ['recent-uploads'] as const;

export function useHSLookup() {
  const api = useApi();

  return useMutation({
    mutationFn: async (values: HSLookupFormValues) => {
      if (!values.product_overview || values.product_overview.trim() === '') {
        throw new Error('Product overview is required');
      }

      const endpoint = values.jobId
        ? `/hs-lookup/status/${values.jobId}`
        : '/hs-lookup/single';

      // Ensure we're sending all fields, even if they're empty strings
      // Important: Set both query and product_overview to the same value
      // This ensures compatibility with both Claude and Mistral models
      const payload = {
        query: values.product_overview, // Always set query to product_overview for API compatibility
        product_overview: values.product_overview,
        product_function: values.product_function || '',
        product_type: values.product_type || '',
        manufacture_stage: values.manufacture_stage || '',
        materials: values.materials || '',
        dimensions: values.dimensions || '',
        industry: values.industry || '',
        origin_country: values.origin_country || '',
        target_country: values.target_country || '',
        hsDigits: parseInt(values.hs_digits || '8', 10),
        isDetailedMode: true // Always use detailed mode
      };

      console.log('Sending HS lookup request to:', endpoint);
      console.log('Request payload:', JSON.stringify(payload));

      try {
        const response = await api.post<HSLookupResponse>(endpoint, payload);
        console.log('HS lookup response:', JSON.stringify(response));
        return response;
      } catch (error) {
        console.error('Error getting classification:', error);
        throw error;
      }
    },
  });
}

export function useBulkHSLookup() {
  const api = useApi();

  return useMutation({
    mutationFn: async ({ file, hsDigits }: BulkHSLookupRequest) => {
      const formData = new FormData();
      formData.append('file', file);
      if (hsDigits) {
        formData.append('hsDigits', hsDigits);
      }

      const response = await api.post<BulkHSLookupResponse>(
        '/hs-lookup/bulk',
        formData
      );
      return response;
    },
  });
}

export function useBulkHSLookupStatus() {
  const api = useApi();

  return useMutation({
    mutationFn: async (jobId: string) => {
      const response = await api.get<BulkHSLookupStatus>(
        `/hs-lookup/bulk/status/${jobId}`
      );
      return response;
    },
  });
}

export function useHSDocuments() {
  const api = useApi();

  return useQuery({
    queryKey: HS_DOCUMENTS_KEY,
    queryFn: async () => {
      const response = await api.get<HSDocumentsResponse>('/settings/hs-codes');
      return response.documents;
    },
  });
}

export function useImportHSCodes() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      file,
      country,
      version,
      headerRow,
    }: HSImportRequest) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('country', country);
      formData.append('version', version);
      formData.append('headerRow', headerRow ? 'true' : 'false');

      const response = await api.put<HSImportResponse>(
        '/settings/hs-codes/import',
        formData
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: HS_DOCUMENTS_KEY });
    },
  });
}

export function useDeleteHSDocument() {
  const api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ country, version }: HSDeleteRequest) => {
      const response = await api.delete<{ success: boolean }>(
        `/settings/hs-codes?country=${country}&version=${version}`
      );
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: HS_DOCUMENTS_KEY });
    },
  });
}

export function useRecentBulkUploads() {
  const api = useApi();

  return useQuery({
    queryKey: RECENT_UPLOADS_KEY,
    queryFn: async () => {
      const response = await api.get<{ uploads: RecentBulkUpload[] }>(
        '/hs-lookup/bulk/jobs'
      );
      return response.uploads;
    },
  });
}
