import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import React from 'react';
import { SessionDialogProps } from './types';

/**
 * A reusable dialog component for session operations like creating, editing, etc.
 */
export const SessionDialog: React.FC<SessionDialogProps> = ({
  isOpen,
  onOpenChange,
  title,
  description,
  inputLabel,
  inputValue,
  onInputChange,
  confirmLabel,
  onConfirm,
  isDestructive = false
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>
        {inputLabel !== undefined && (
          <div className="py-4">
            <Input
              placeholder={inputLabel}
              value={inputValue}
              onChange={(e) => onInputChange(e.target.value)}
            />
          </div>
        )}
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button 
            variant={isDestructive ? "destructive" : "default"} 
            onClick={onConfirm}
          >
            {confirmLabel}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}; 