import { Alert, AlertDescription } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Progress } from '@/components/ui/progress';
import { AlertCircle, CheckCircle2, Eye, EyeOff, KeyRound, Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';

interface UpdatePasswordDialogProps {
  userId: string;
  onUpdatePassword: (id: string, password: string) => Promise<boolean>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

// Password strength calculation
const calculatePasswordStrength = (password: string): number => {
  if (!password) return 0;
  
  let strength = 0;
  
  // Length check
  if (password.length >= 8) strength += 25;
  
  // Contains lowercase
  if (/[a-z]/.test(password)) strength += 25;
  
  // Contains uppercase
  if (/[A-Z]/.test(password)) strength += 25;
  
  // Contains number or special char
  if (/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) strength += 25;
  
  return strength;
};

export function UpdatePasswordDialog({
  userId,
  onUpdatePassword,
  open,
  onOpenChange,
}: UpdatePasswordDialogProps) {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  
  // Password strength
  const passwordStrength = calculatePasswordStrength(password);
  
  // Reset form when dialog opens/closes
  useEffect(() => {
    if (!open) {
      // Small delay to avoid flickering during close animation
      const timer = setTimeout(() => {
        setPassword('');
        setConfirmPassword('');
        setError('');
        setValidationErrors({});
        setShowPassword(false);
        setShowConfirmPassword(false);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [open]);
  
  const getStrengthColor = (strength: number) => {
    if (strength < 25) return 'bg-destructive';
    if (strength < 50) return 'bg-orange-500';
    if (strength < 75) return 'bg-yellow-500';
    return 'bg-green-500';
  };
  
  const getStrengthText = (strength: number) => {
    if (strength < 25) return 'Very Weak';
    if (strength < 50) return 'Weak';
    if (strength < 75) return 'Good';
    return 'Strong';
  };

  const validateForm = () => {
    const errors: Record<string, string> = {};
    
    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 8) {
      errors.password = 'Password must be at least 8 characters long';
    }
    
    if (!confirmPassword) {
      errors.confirmPassword = 'Please confirm your password';
    } else if (password !== confirmPassword) {
      errors.confirmPassword = 'Passwords do not match';
    }
    
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    try {
      setIsSubmitting(true);
      const success = await onUpdatePassword(userId, password);
      if (success) {
        setPassword('');
        setConfirmPassword('');
        onOpenChange(false);
      }
    } catch (error) {
      setError(
        error instanceof Error ? error.message : 'Failed to update password'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <KeyRound className="h-5 w-5" />
            Update Password
          </DialogTitle>
          <DialogDescription>
            Enter a new password for this user.
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 py-4">
            <div className="grid gap-2">
              <Label htmlFor="password">New Password</Label>
              <div className="relative">
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={e => {
                    setPassword(e.target.value);
                    if (validationErrors.password) {
                      const newErrors = { ...validationErrors };
                      delete newErrors.password;
                      setValidationErrors(newErrors);
                    }
                  }}
                  placeholder="Enter new password"
                  className={validationErrors.password ? 'border-destructive pr-10' : 'pr-10'}
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOff className="h-4 w-4 text-muted-foreground" />
                  ) : (
                    <Eye className="h-4 w-4 text-muted-foreground" />
                  )}
                  <span className="sr-only">
                    {showPassword ? "Hide password" : "Show password"}
                  </span>
                </Button>
              </div>
              {validationErrors.password && (
                <p className="text-xs text-destructive">{validationErrors.password}</p>
              )}
              
              {/* Password strength indicator */}
              {password && (
                <div className="space-y-1">
                  <div className="flex justify-between text-xs">
                    <span>Password strength:</span>
                    <span className={passwordStrength >= 75 ? 'text-green-500 font-medium' : 'text-muted-foreground'}>
                      {getStrengthText(passwordStrength)}
                    </span>
                  </div>
                  <Progress value={passwordStrength} className={`h-1 ${getStrengthColor(passwordStrength)}`} />
                  
                  <ul className="text-xs space-y-1 mt-2">
                    <li className="flex items-center gap-1">
                      <span className={password.length >= 8 ? 'text-green-500' : 'text-muted-foreground'}>
                        {password.length >= 8 ? (
                          <CheckCircle2 className="h-3 w-3 inline" />
                        ) : (
                          <AlertCircle className="h-3 w-3 inline" />
                        )}
                      </span>
                      <span className={password.length >= 8 ? 'text-green-500' : 'text-muted-foreground'}>
                        At least 8 characters
                      </span>
                    </li>
                    <li className="flex items-center gap-1">
                      <span className={/[a-z]/.test(password) ? 'text-green-500' : 'text-muted-foreground'}>
                        {/[a-z]/.test(password) ? (
                          <CheckCircle2 className="h-3 w-3 inline" />
                        ) : (
                          <AlertCircle className="h-3 w-3 inline" />
                        )}
                      </span>
                      <span className={/[a-z]/.test(password) ? 'text-green-500' : 'text-muted-foreground'}>
                        Lowercase letters
                      </span>
                    </li>
                    <li className="flex items-center gap-1">
                      <span className={/[A-Z]/.test(password) ? 'text-green-500' : 'text-muted-foreground'}>
                        {/[A-Z]/.test(password) ? (
                          <CheckCircle2 className="h-3 w-3 inline" />
                        ) : (
                          <AlertCircle className="h-3 w-3 inline" />
                        )}
                      </span>
                      <span className={/[A-Z]/.test(password) ? 'text-green-500' : 'text-muted-foreground'}>
                        Uppercase letters
                      </span>
                    </li>
                    <li className="flex items-center gap-1">
                      <span className={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password) ? 'text-green-500' : 'text-muted-foreground'}>
                        {/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password) ? (
                          <CheckCircle2 className="h-3 w-3 inline" />
                        ) : (
                          <AlertCircle className="h-3 w-3 inline" />
                        )}
                      </span>
                      <span className={/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password) ? 'text-green-500' : 'text-muted-foreground'}>
                        Numbers or special characters
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            
            <div className="grid gap-2">
              <Label htmlFor="confirmPassword">Confirm Password</Label>
              <div className="relative">
                <Input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={e => {
                    setConfirmPassword(e.target.value);
                    if (validationErrors.confirmPassword) {
                      const newErrors = { ...validationErrors };
                      delete newErrors.confirmPassword;
                      setValidationErrors(newErrors);
                    }
                  }}
                  placeholder="Confirm new password"
                  className={validationErrors.confirmPassword ? 'border-destructive pr-10' : 'pr-10'}
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  className="absolute right-0 top-0 h-full px-3"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeOff className="h-4 w-4 text-muted-foreground" />
                  ) : (
                    <Eye className="h-4 w-4 text-muted-foreground" />
                  )}
                  <span className="sr-only">
                    {showConfirmPassword ? "Hide password" : "Show password"}
                  </span>
                </Button>
              </div>
              {validationErrors.confirmPassword && (
                <p className="text-xs text-destructive">{validationErrors.confirmPassword}</p>
              )}
              {password && confirmPassword && password === confirmPassword && (
                <p className="text-xs text-green-500 flex items-center gap-1">
                  <CheckCircle2 className="h-3 w-3" />
                  Passwords match
                </p>
              )}
            </div>
            
            {error && (
              <Alert variant="destructive">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
          </div>
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Updating...
                </>
              ) : (
                <>
                  <KeyRound className="mr-2 h-4 w-4" />
                  Update Password
                </>
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
