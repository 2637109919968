import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { AddUserDialog } from '@/components/users/AddUserDialog';
import { UsersTable } from '@/components/users/UsersTable';
import { useToast } from '@/hooks/use-toast';
import { useUserManagement } from '@/hooks/useUserManagement';
import { formatDate } from '@/lib/utils';
import {
    AlertCircle,
    Clock,
    Loader2,
    RefreshCw,
    Search,
    Shield,
    UserPlus,
    Users as UsersIcon
} from 'lucide-react';
import { useEffect, useState } from 'react';

export default function Users() {
  const {
    users,
    isLoading,
    fetchUsers,
    addUser,
    updateUser,
    updatePassword,
    deleteUser,
  } = useUserManagement();
  const { toast } = useToast();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [roleFilter, setRoleFilter] = useState<string>('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState<string | null>(null);
  
  const itemsPerPage = 10;

  useEffect(() => {
    console.log('Users page mounted, fetching users...');
    fetchUsers().catch(err => {
      setError(err instanceof Error ? err.message : 'Failed to fetch users');
    });
  }, []);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    setError(null);
    try {
      await fetchUsers();
      toast({
        title: 'Refreshed',
        description: 'User data has been refreshed',
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to refresh users';
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  // Filter and paginate users
  const filteredUsers = users.filter(user => {
    // Search filter
    const matchesSearch = 
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase());
    
    // Role filter
    const matchesRole = roleFilter === 'all' || user.role === roleFilter;
    
    return matchesSearch && matchesRole;
  });

  const paginatedUsers = filteredUsers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  // Calculate statistics
  const totalUsers = users.length;
  const adminUsers = users.filter(user => user.role === 'admin').length;
  const regularUsers = users.filter(user => user.role === 'user').length;
  const newestUser = users.length > 0 
    ? users.reduce((newest, user) => 
        new Date(user.created_at) > new Date(newest.created_at) ? user : newest, 
        users[0]
      ) 
    : null;

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <UsersIcon className="h-6 w-6" />
          <div>
            <h1 className="text-3xl font-bold">Users</h1>
            <p className="text-muted-foreground">
              Manage user access and permissions
            </p>
          </div>
        </div>
        <div className="flex space-x-4">
          <Button 
            variant="outline" 
            onClick={handleRefresh}
            disabled={isLoading || isRefreshing}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isRefreshing ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
          <AddUserDialog onAddUser={addUser} />
        </div>
      </div>

      {/* Statistics Cards */}
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Users
            </CardTitle>
            <UsersIcon className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? '-' : totalUsers}</div>
            <p className="text-xs text-muted-foreground">
              Active accounts in the system
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Admin Users
            </CardTitle>
            <Shield className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? '-' : adminUsers}</div>
            <p className="text-xs text-muted-foreground">
              {adminUsers > 0 ? `${Math.round((adminUsers / totalUsers) * 100)}% of total users` : 'No admin users'}
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Regular Users
            </CardTitle>
            <UsersIcon className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? '-' : regularUsers}</div>
            <p className="text-xs text-muted-foreground">
              {regularUsers > 0 ? `${Math.round((regularUsers / totalUsers) * 100)}% of total users` : 'No regular users'}
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Newest User
            </CardTitle>
            <Clock className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-md font-bold truncate">
              {isLoading ? '-' : newestUser ? newestUser.name : 'No users'}
            </div>
            <p className="text-xs text-muted-foreground">
              {newestUser ? `Added ${formatDate(newestUser.created_at)}` : 'No users in system'}
            </p>
          </CardContent>
        </Card>
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      <Card>
        <CardHeader>
          <CardTitle>Users</CardTitle>
          <CardDescription>
            View and manage all users in the system
          </CardDescription>
          <div className="flex flex-col sm:flex-row gap-4 mt-4">
            <div className="relative flex-1">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setCurrentPage(1); // Reset to first page on search
                }}
                className="pl-8"
              />
            </div>
            <Select 
              value={roleFilter} 
              onValueChange={(value) => {
                setRoleFilter(value);
                setCurrentPage(1); // Reset to first page on filter change
              }}
            >
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Filter by role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="all">All Roles</SelectItem>
                <SelectItem value="admin">Admin</SelectItem>
                <SelectItem value="user">User</SelectItem>
              </SelectContent>
            </Select>
            <Button 
              variant="outline" 
              onClick={() => document.getElementById('add-user-dialog-trigger')?.click()}
              className="sm:w-auto w-full"
            >
              <UserPlus className="h-4 w-4 mr-2" />
              Add User
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : filteredUsers.length === 0 ? (
            <div className="flex flex-col items-center justify-center py-8 text-center">
              <AlertCircle className="h-12 w-12 text-muted-foreground mb-4" />
              <h3 className="text-lg font-medium">No users found</h3>
              <p className="text-sm text-muted-foreground mt-2">
                {users.length === 0
                  ? "No users have been added yet."
                  : "No users match your search criteria."}
              </p>
              {users.length === 0 && (
                <Button 
                  className="mt-4" 
                  onClick={() => document.getElementById('add-user-dialog-trigger')?.click()}
                >
                  <UserPlus className="h-4 w-4 mr-2" />
                  Add First User
                </Button>
              )}
            </div>
          ) : (
            <>
              <UsersTable
                users={paginatedUsers}
                onDeleteUser={async (id) => {
                  const success = await deleteUser(id);
                  if (success) {
                    toast({
                      title: 'User Deleted',
                      description: 'The user has been successfully deleted',
                    });
                  }
                  return success;
                }}
                onUpdateUser={async (userData) => {
                  const success = await updateUser(userData);
                  if (success) {
                    toast({
                      title: 'User Updated',
                      description: 'The user has been successfully updated',
                    });
                  }
                  return success;
                }}
                onUpdatePassword={async (id, password) => {
                  const success = await updatePassword(id, password);
                  if (success) {
                    toast({
                      title: 'Password Updated',
                      description: 'The password has been successfully updated',
                    });
                  }
                  return success;
                }}
              />
              
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex items-center justify-between mt-4">
                  <div className="text-sm text-muted-foreground">
                    Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredUsers.length)} of {filteredUsers.length}
                  </div>
                  <div className="flex items-center space-x-2">
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <div className="text-sm">
                      Page {currentPage} of {totalPages}
                    </div>
                    <Button
                      variant="outline"
                      size="sm"
                      onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </CardContent>
        {!isLoading && filteredUsers.length > 0 && (
          <CardFooter className="border-t px-6 py-4">
            <div className="text-xs text-muted-foreground">
              Last updated: {new Date().toLocaleString()}
            </div>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
