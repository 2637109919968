import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { SimplePagination } from '@/components/ui/pagination';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip';
import { ExtractionJob } from '@/lib/api/types/table-extraction';
import { format, formatDistanceToNow, isValid, parseISO } from 'date-fns';
import { AlertCircle, Download, Eye, FileIcon, Loader2, Trash2 } from 'lucide-react';
import { memo, useCallback, useEffect, useState } from 'react';

// Constants
const ITEMS_PER_PAGE = 15;

// Extended status type to include all possible statuses
type ExtendedStatus = 'pending' | 'processing' | 'completed' | 'failed' | 'uploading' | 'queued';

interface ExtractionsListProps {
  jobs: ExtractionJob[];
  isLoading: boolean;
  isDownloading: boolean;
  isDeletingId: string | null;
  onDownload: (id: string) => void;
  onDelete: (id: string) => void;
  onPreviewClick: (job: ExtractionJob) => void;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

// Memoized row component to prevent unnecessary re-renders
const ExtractionRow = memo(({ 
  job, 
  isDownloading, 
  isDeletingId, 
  onDownload, 
  onDelete,
  onPreviewClick
}: { 
  job: ExtractionJob; 
  isDownloading: boolean; 
  isDeletingId: string | null; 
  onDownload: (id: string) => void; 
  onDelete: (id: string) => void;
  onPreviewClick: (job: ExtractionJob) => void;
}) => {
  // Format the date for display
  const formatDate = (dateString: string) => {
    try {
      const date = parseISO(dateString);
      if (!isValid(date)) return 'Invalid date';
      
      // Format as relative time if recent, otherwise as absolute date
      const now = new Date();
      const diffInHours = Math.abs(now.getTime() - date.getTime()) / (1000 * 60 * 60);
      
      if (diffInHours < 24) {
        return formatDistanceToNow(date, { addSuffix: true });
      } else {
        return format(date, 'MMM d, yyyy h:mm a');
      }
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  // Get status badge color based on status
  const getStatusColor = (status: string): string => {
    const normalizedStatus = status.toLowerCase() as ExtendedStatus;
    
    switch (normalizedStatus) {
      case 'completed':
        return 'bg-green-500 hover:bg-green-600';
      case 'processing':
      case 'uploading':
      case 'queued':
        return 'bg-blue-500 hover:bg-blue-600';
      case 'pending':
        return 'bg-yellow-500 hover:bg-yellow-600';
      case 'failed':
        return 'bg-red-500 hover:bg-red-600';
      default:
        return 'bg-gray-500 hover:bg-gray-600';
    }
  };

  // Get a user-friendly status label
  const getStatusLabel = (job: ExtractionJob): string => {
    // Use displayStatus if available
    if (job.displayStatus) return job.displayStatus;
    
    // Otherwise, format based on status
    const status = (job.status || '').toLowerCase();
    
    switch (status as ExtendedStatus) {
      case 'completed':
        return 'Completed';
      case 'processing':
        return 'Processing';
      case 'uploading':
        return 'Uploading';
      case 'queued':
        return 'Queued';
      case 'pending':
        return 'Pending';
      case 'failed':
        return job.errorMessage ? 'Failed' : 'Failed';
      default:
        // Handle unknown status safely
        return status ? `${status.charAt(0).toUpperCase()}${status.slice(1)}` : 'Unknown';
    }
  };

  // Format file size for display
  const formatFileSize = (size?: number): string => {
    if (size === undefined || size === null) return 'Unknown';
    
    const kb = size / 1024;
    if (kb < 1024) {
      return `${kb.toFixed(1)} KB`;
    } else {
      const mb = kb / 1024;
      return `${mb.toFixed(1)} MB`;
    }
  };

  // Get file icon based on file type
  const getFileIcon = (fileType?: string) => {
    if (!fileType) return <FileIcon className="h-4 w-4" />;
    
    if (fileType.includes('pdf')) {
      return <FileIcon className="h-4 w-4 text-red-500" />;
    } else if (fileType.includes('image')) {
      return <FileIcon className="h-4 w-4 text-blue-500" />;
    } else if (fileType.includes('excel') || fileType.includes('spreadsheet')) {
      return <FileIcon className="h-4 w-4 text-green-500" />;
    } else {
      return <FileIcon className="h-4 w-4" />;
    }
  };

  // Determine if the job is in a state where download is available
  const canDownload = job.status?.toLowerCase() === 'completed' && job.outputUrl;
  
  // Determine if the job is in a state where preview is available
  const canPreview = job.status?.toLowerCase() === 'completed' && job.previewUrl;

  // Determine if the job is in a processing state
  const isProcessing = job.isProcessing || ['pending', 'processing', 'uploading', 'queued'].includes((job.status || '').toLowerCase() as ExtendedStatus);

  return (
    <TableRow key={job.id} className="hover:bg-muted/50">
      <TableCell className="font-medium">
        <div className="flex items-center space-x-2">
          {getFileIcon(job.fileType)}
          <span className="truncate max-w-[150px]" title={job.fileName || 'Unknown File'}>
            {job.fileName || 'Unknown File'}
          </span>
        </div>
      </TableCell>
      <TableCell>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Badge className={`${getStatusColor(job.status || 'unknown')} text-white`}>
                {getStatusLabel(job)}
              </Badge>
            </TooltipTrigger>
            {job.errorMessage && (
              <TooltipContent>
                <p className="max-w-xs">{job.errorMessage}</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>
      </TableCell>
      <TableCell>{job.tableCount || 0}</TableCell>
      <TableCell>{job.rowCount || 0}</TableCell>
      <TableCell>{formatDate(job.createdAt)}</TableCell>
      <TableCell className="text-right">
        <div className="flex justify-end space-x-2">
          {canPreview && (
            <Button
              variant="outline"
              size="icon"
              onClick={() => onPreviewClick(job)}
              title="Preview"
            >
              <Eye className="h-4 w-4" />
            </Button>
          )}
          
          <Button
            variant="outline"
            size="icon"
            onClick={() => onDownload(job.id)}
            disabled={!canDownload || isDownloading}
            title={canDownload ? 'Download' : 'Download not available'}
          >
            {isDownloading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Download className="h-4 w-4" />
            )}
          </Button>
          
          <Button
            variant="outline"
            size="icon"
            onClick={() => onDelete(job.id)}
            disabled={isDeletingId === job.id}
            title="Delete"
          >
            {isDeletingId === job.id ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Trash2 className="h-4 w-4" />
            )}
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
});

// Set display name for debugging
ExtractionRow.displayName = 'ExtractionRow';

export function ExtractionsList({
  jobs,
  isLoading,
  isDownloading,
  isDeletingId,
  onDownload,
  onDelete,
  onPreviewClick,
  currentPage,
  totalPages,
  onPageChange,
}: ExtractionsListProps) {
  const [previewJob, setPreviewJob] = useState<ExtractionJob | null>(null);
  
  // Handle preview click
  const handlePreviewClick = useCallback((job: ExtractionJob) => {
    setPreviewJob(job);
  }, []);
  
  // Close preview dialog
  const handleClosePreview = useCallback(() => {
    setPreviewJob(null);
  }, []);

  // Reset to page 1 if we have no jobs and are on a different page
  useEffect(() => {
    if (jobs.length === 0 && currentPage > 1 && totalPages === 0) {
      onPageChange(1);
    }
  }, [jobs.length, currentPage, totalPages, onPageChange]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-12">
        <div className="flex flex-col items-center space-y-4">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <p className="text-muted-foreground">Loading extractions...</p>
        </div>
      </div>
    );
  }

  if (jobs.length === 0) {
    return (
      <div className="flex justify-center items-center py-12 text-center">
        <div className="flex flex-col items-center space-y-4 max-w-md">
          <AlertCircle className="h-8 w-8 text-muted-foreground" />
          <div>
            <h3 className="text-lg font-medium">No extractions found</h3>
            <p className="text-muted-foreground mt-1">
              Upload a document to extract tables and convert them to Excel format.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>File Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Tables</TableHead>
              <TableHead>Rows</TableHead>
              <TableHead>Created</TableHead>
              <TableHead className="text-right">Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {jobs.map((job) => (
              <ExtractionRow
                key={job.id}
                job={job}
                isDownloading={isDownloading}
                isDeletingId={isDeletingId}
                onDownload={onDownload}
                onDelete={onDelete}
                onPreviewClick={handlePreviewClick}
              />
            ))}
          </TableBody>
        </Table>
      </div>

      {totalPages > 1 && (
        <div className="flex justify-center mt-4">
          <SimplePagination
            currentPage={currentPage}
            totalItems={totalPages * ITEMS_PER_PAGE}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={onPageChange}
          />
        </div>
      )}

      {/* Preview Dialog */}
      <Dialog open={!!previewJob} onOpenChange={handleClosePreview}>
        <DialogContent className="max-w-4xl h-[80vh] flex flex-col">
          <DialogHeader>
            <DialogTitle>
              Preview: {previewJob?.fileName || 'Table Preview'}
            </DialogTitle>
          </DialogHeader>
          <div className="flex-1 overflow-auto mt-4">
            {previewJob?.previewUrl ? (
              <iframe
                src={previewJob.previewUrl}
                className="w-full h-full border rounded"
                title="Table Preview"
              />
            ) : (
              <div className="flex justify-center items-center h-full">
                <p className="text-muted-foreground">Preview not available</p>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
