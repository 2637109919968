import { useAuth } from '@/hooks/useAuth';
import { getApiUrl } from '@/lib/config';
import { useCallback } from 'react';

interface ApiError {
  error: string;
  details?: string;
}

interface ApiOptions {
  headers?: Record<string, string>;
}

export function useApi() {
  const { token } = useAuth();

  const request = useCallback(
    async <T>(
      method: string,
      path: string,
      body?: unknown,
      options: ApiOptions = {}
    ): Promise<T> => {
      const headers = {
        ...options.headers,
      };

      if (!(body instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
      }

      // Log token state
      console.log('API Request:', {
        path,
        method,
        hookToken: token,
        storedToken: localStorage.getItem('token'),
      });

      // Always add auth token if available
      if (token) {
        headers['Authorization'] = `Bearer ${token}`;
        console.log('Using hook token for auth');
      } else {
        // Get token from localStorage as fallback
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          headers['Authorization'] = `Bearer ${storedToken}`;
          console.log('Using stored token for auth');
        } else {
          console.warn('No token available for request');
        }
      }

      console.log('Request headers:', headers);

      const url = getApiUrl(path);
      console.log('Request URL:', url);

      const response = await fetch(url, {
        method,
        headers,
        body: body instanceof FormData ? body : JSON.stringify(body),
        credentials: 'include',
      });

      console.log('API Response:', {
        path,
        status: response.status,
        ok: response.ok,
      });

      if (!response.ok) {
        const data = await response.json();
        console.error('API Error:', {
          path,
          status: response.status,
          error: data,
        });
        throw new Error((data as ApiError).error || 'An error occurred');
      }

      const text = await response.text();
      console.log('Raw response:', text);

      try {
        const data = JSON.parse(text);
        console.log('Parsed response:', {
          path,
          isArray: Array.isArray(data),
          type: typeof data,
          keys: data ? Object.keys(data) : [],
        });
        return data as T;
      } catch (error) {
        console.error('Failed to parse response:', error);
        throw new Error('Invalid response format');
      }
    },
    [token]
  );

  return {
    get: <T>(path: string, options?: ApiOptions) =>
      request<T>('GET', path, undefined, options),
    post: <T>(path: string, body?: unknown, options?: ApiOptions) =>
      request<T>('POST', path, body, options),
    put: <T>(path: string, body?: unknown, options?: ApiOptions) =>
      request<T>('PUT', path, body, options),
    delete: <T>(path: string, options?: ApiOptions) =>
      request<T>('DELETE', path, undefined, options),
  };
}
