import { useToast } from '@/hooks/use-toast';
import { format } from 'date-fns';

/**
 * Format a date string for display
 * @param dateString - The date string to format
 * @returns Formatted date string
 */
export const formatDate = (dateString: string): string => {
  try {
    const date = new Date(dateString);
    return format(date, 'MMM d, yyyy h:mm a');
  } catch (e) {
    return dateString;
  }
};

/**
 * Validate a file for upload
 * @param file - The file to validate
 * @returns An error message if invalid, or null if valid
 */
export const validateFile = (file: File): string | null => {
  // Check file type
  if (!file.type.match(/^(image\/|application\/pdf)/)) {
    return 'Please upload an image or PDF file';
  }
  
  // Check file size (10MB max)
  if (file.size > 10 * 1024 * 1024) {
    return 'Please upload a file smaller than 10MB';
  }
  
  return null;
};

/**
 * Custom hook for handling file uploads
 */
export const useFileUpload = () => {
  const { toast } = useToast();
  
  const handleFileChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFile: (file: File | null) => void
  ) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const error = validateFile(selectedFile);
      
      if (error) {
        toast({
          title: 'Invalid file',
          description: error,
          variant: 'destructive',
        });
        return;
      }
      
      setFile(selectedFile);
    }
  };
  
  return { handleFileChange };
}; 