import { AddEnterpriseDialog } from '@/components/tenants/AddEnterpriseDialog';
import { TenantsTable } from '@/components/tenants/TenantsTable';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { AddUserDialog } from '@/components/users/AddUserDialog';
import { useToast } from '@/hooks/use-toast';
import { useTenantManagement } from '@/hooks/useTenantManagement';
import {
    AlertCircle,
    Building,
    Globe,
    Plus,
    RefreshCw,
    Users
} from 'lucide-react';
import { useEffect, useState } from 'react';

export default function TenantManagement() {
  const {
    tenants,
    isLoading,
    error,
    fetchTenants,
    addTenant,
    updateTenant,
    deleteTenant,
    toggleTenantStatus,
  } = useTenantManagement();
  const { toast } = useToast();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    fetchTenants();
  }, []);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await fetchTenants();
      toast({
        title: 'Refreshed',
        description: 'Enterprise data has been refreshed',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to refresh enterprise data',
        variant: 'destructive',
      });
    } finally {
      setIsRefreshing(false);
    }
  };

  // Calculate statistics
  const totalTenants = tenants.length;
  const activeTenants = tenants.filter(t => t.status === 'active').length;
  const totalUsers = tenants.reduce((sum, tenant) => sum + tenant.currentUsers, 0);
  const totalDomains = tenants.length; // Simplified, could be more complex in real app

  // Filter tenants based on active tab
  const filteredTenants = tenants.filter(tenant => {
    if (activeTab === 'all') return true;
    if (activeTab === 'active') return tenant.status === 'active';
    if (activeTab === 'disabled') return tenant.status === 'disabled';
    return true;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Enterprise Management</h1>
          <p className="text-muted-foreground">
            Manage enterprise tenants and their users
          </p>
        </div>
        <div className="flex space-x-4">
          <Button 
            variant="outline" 
            onClick={handleRefresh}
            disabled={isLoading || isRefreshing}
          >
            <RefreshCw className={`h-4 w-4 mr-2 ${isRefreshing ? 'animate-spin' : ''}`} />
            Refresh
          </Button>
          <AddUserDialog
            onAddUser={userData => console.log('Add user:', userData)}
          />
          <AddEnterpriseDialog onAddEnterprise={addTenant} />
        </div>
      </div>

      {/* Statistics Cards */}
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Enterprises
            </CardTitle>
            <Building className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? '-' : totalTenants}</div>
            <p className="text-xs text-muted-foreground">
              {activeTenants} active, {totalTenants - activeTenants} disabled
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Total Users
            </CardTitle>
            <Users className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? '-' : totalUsers}</div>
            <p className="text-xs text-muted-foreground">
              Across all enterprises
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Active Enterprises
            </CardTitle>
            <Building className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? '-' : activeTenants}</div>
            <p className="text-xs text-muted-foreground">
              {((activeTenants / totalTenants) * 100 || 0).toFixed(0)}% of total
            </p>
          </CardContent>
        </Card>
        <Card>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">
              Domains
            </CardTitle>
            <Globe className="h-4 w-4 text-muted-foreground" />
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold">{isLoading ? '-' : totalDomains}</div>
            <p className="text-xs text-muted-foreground">
              Registered domains
            </p>
          </CardContent>
        </Card>
      </div>

      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>
            {typeof error === 'string' 
              ? error 
              : error instanceof Error 
                ? error.message 
                : 'Failed to load enterprise data'}
          </AlertDescription>
        </Alert>
      )}

      <Card>
        <CardHeader className="space-y-1">
          <div className="flex items-center justify-between">
            <div>
              <CardTitle>Enterprise Tenants</CardTitle>
              <CardDescription>
                View and manage all enterprise tenants in the system
              </CardDescription>
            </div>
            <Button variant="outline" onClick={() => document.getElementById('add-enterprise-dialog-trigger')?.click()}>
              <Plus className="h-4 w-4 mr-2" />
              Add Enterprise
            </Button>
          </div>
          <Tabs value={activeTab} onValueChange={setActiveTab} className="mt-6">
            <TabsList>
              <TabsTrigger value="all">All Enterprises</TabsTrigger>
              <TabsTrigger value="active">Active</TabsTrigger>
              <TabsTrigger value="disabled">Disabled</TabsTrigger>
            </TabsList>
          </Tabs>
        </CardHeader>
        <CardContent>
          <TenantsTable
            tenants={filteredTenants}
            onDeleteTenant={async (id) => {
              const success = await deleteTenant(id);
              if (success) {
                toast({
                  title: 'Enterprise Deleted',
                  description: 'The enterprise has been successfully deleted',
                });
              }
              return success;
            }}
            onUpdateTenant={async (id, data) => {
              const success = await updateTenant(id, data);
              if (success) {
                toast({
                  title: 'Enterprise Updated',
                  description: 'The enterprise has been successfully updated',
                });
              }
              return success;
            }}
            onToggleStatus={async (id) => {
              const success = await toggleTenantStatus(id);
              if (success) {
                const tenant = tenants.find(t => t.id === id);
                const newStatus = tenant?.status === 'active' ? 'disabled' : 'active';
                toast({
                  title: `Enterprise ${newStatus === 'active' ? 'Activated' : 'Disabled'}`,
                  description: `The enterprise has been ${newStatus === 'active' ? 'activated' : 'disabled'} successfully`,
                });
              }
              return success;
            }}
            isLoading={isLoading}
          />
        </CardContent>
        {!isLoading && filteredTenants.length > 0 && (
          <CardFooter className="border-t px-6 py-4">
            <div className="text-xs text-muted-foreground">
              Last updated: {new Date().toLocaleString()}
            </div>
          </CardFooter>
        )}
      </Card>
    </div>
  );
}
