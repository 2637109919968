import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { ExtractionJob } from '@/lib/api/types/table-extraction';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { ColumnSelector } from './ColumnSelector';

export interface TablePreviewData {
  headers: string[];
  previewUrl?: string;
  previewHtml?: string;
  rowCount?: number;
  tableCount?: number;
}

export interface TablePreviewDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  job?: ExtractionJob | null;
  previewData?: TablePreviewData | null;
  isLoading: boolean;
  onConfirm: (selectedHeaders: string[], orderedHeaders: string[]) => void;
}

export function TablePreviewDialog({
  open,
  onOpenChange,
  job,
  previewData,
  isLoading,
  onConfirm,
}: TablePreviewDialogProps) {
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
  const [orderedHeaders, setOrderedHeaders] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>('preview');
  const [showTextTable, setShowTextTable] = useState<boolean>(false);

  // Reset state when job changes
  useEffect(() => {
    if (previewData?.headers) {
      setSelectedHeaders(previewData.headers);
      setOrderedHeaders(previewData.headers);
    }
  }, [previewData]);

  // Handle selection change
  const handleSelectionChange = (selected: string[], ordered: string[]) => {
    setSelectedHeaders(selected);
    setOrderedHeaders(ordered);
  };

  // Handle confirm button click
  const handleConfirm = () => {
    onConfirm(selectedHeaders, orderedHeaders);
    onOpenChange(false);
  };

  // Toggle between image and text representation
  const toggleTextTable = () => {
    setShowTextTable(!showTextTable);
  };

  // Generate a text-based table representation
  const renderTextTable = () => {
    if (!previewData?.previewHtml) return null;
    
    return (
      <div className="border rounded p-4 bg-white overflow-auto">
        <div dangerouslySetInnerHTML={{ __html: previewData.previewHtml }} />
      </div>
    );
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>
            {job ? `Preview: ${job.fileName || 'Table Preview'}` : 'Table Preview'}
          </DialogTitle>
          {previewData && (
            <div className="text-sm text-muted-foreground mt-1">
              {previewData.tableCount && previewData.tableCount > 0 && (
                <span className="mr-3">Tables: {previewData.tableCount}</span>
              )}
              {previewData.rowCount && previewData.rowCount > 0 && (
                <span>Rows: {previewData.rowCount}</span>
              )}
            </div>
          )}
        </DialogHeader>

        {isLoading ? (
          <div className="flex-1 flex justify-center items-center">
            <div className="flex flex-col items-center space-y-4">
              <Loader2 className="h-8 w-8 animate-spin text-primary" />
              <p className="text-muted-foreground">Analyzing document...</p>
            </div>
          </div>
        ) : !previewData ? (
          <div className="flex-1 flex justify-center items-center">
            <p className="text-muted-foreground">Preview not available</p>
          </div>
        ) : (
          <Tabs
            value={activeTab}
            onValueChange={setActiveTab}
            className="flex-1 flex flex-col"
          >
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="preview">Preview</TabsTrigger>
              <TabsTrigger value="columns">Select Columns</TabsTrigger>
            </TabsList>
            
            <TabsContent value="preview" className="flex-1 overflow-auto mt-4">
              {previewData.previewHtml && (
                <div className="mb-2 flex justify-end">
                  <Button 
                    variant="outline" 
                    size="sm" 
                    onClick={toggleTextTable}
                  >
                    {showTextTable ? "Show Image Preview" : "Show HTML Table"}
                  </Button>
                </div>
              )}
              
              {showTextTable && previewData.previewHtml ? (
                renderTextTable()
              ) : previewData.previewUrl ? (
                <iframe
                  src={previewData.previewUrl}
                  className="w-full h-full border rounded"
                  title="Table Preview"
                />
              ) : previewData.previewHtml ? (
                <div 
                  className="w-full h-full border rounded p-4 overflow-auto"
                  dangerouslySetInnerHTML={{ __html: previewData.previewHtml }}
                />
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p className="text-muted-foreground">Preview not available</p>
                </div>
              )}
              
              {previewData.headers.length > 0 && (
                <div className="mt-4 p-4 border rounded bg-gray-50">
                  <h3 className="text-sm font-medium mb-2">Detected Headers:</h3>
                  <div className="flex flex-wrap gap-2">
                    {previewData.headers.map((header, index) => (
                      <div key={index} className="px-2 py-1 bg-white border rounded text-sm">
                        {header}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="columns" className="flex-1 overflow-auto mt-4 p-4">
              {previewData.headers.length > 0 ? (
                <ColumnSelector
                  headers={previewData.headers}
                  onSelectionChange={handleSelectionChange}
                  initialSelection={previewData.headers}
                />
              ) : (
                <div className="flex justify-center items-center h-full">
                  <p className="text-muted-foreground">No columns detected</p>
                </div>
              )}
            </TabsContent>
          </Tabs>
        )}

        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Cancel
          </Button>
          <Button 
            onClick={handleConfirm}
            disabled={isLoading || !previewData || selectedHeaders.length === 0}
          >
            Confirm Selection
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
} 