import { clearStoredAuthState, getAuthToken } from '../../auth-store';
import { getApiUrl } from '../../config';

interface FetchOptions extends RequestInit {
  skipAuth?: boolean;
}

export async function fetchApi<T>(
  endpoint: string,
  options: FetchOptions = {}
): Promise<T> {
  const { skipAuth, ...fetchOptions } = options;
  const token = !skipAuth && getAuthToken();

  // Log token state
  console.log('Auth state:', {
    hasToken: !!token,
    tokenPrefix: token ? token.substring(0, 20) + '...' : null,
  });

  const headers = new Headers(options.headers);
  headers.set('Content-Type', 'application/json');

  // Only add Authorization header if we have a token and it's not a login request
  if (token && !endpoint.includes('/auth/login')) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  // Ensure endpoint starts with /api in production
  const normalizedEndpoint = endpoint.startsWith('/')
    ? endpoint.slice(1)
    : endpoint;

  // Special handling for chat endpoints - they don't need the /api prefix
  const isChatEndpoint = normalizedEndpoint.startsWith('chat/');
  
  const isProd =
    import.meta.env.PROD &&
    window.location.hostname === 'shipmate.fpsaus.com.au';
  
  // Get the base API URL
  const baseUrl = getApiUrl();
  
  // Properly construct the URL - ensure we have the right path format
  let url;
  if (isProd) {
    // In production, ensure we use the correct format without double /api
    url = `${baseUrl}/${normalizedEndpoint}`;
  } else {
    // In development, use the standard format
    url = `${baseUrl}/api/${normalizedEndpoint}`;
  }

  // Log the exact request that will be made
  console.log('🚀 Making API request:', {
    url,
    method: fetchOptions.method || 'GET',
    headers: Object.fromEntries(headers.entries()),
    environment: {
      hostname: window.location.hostname,
      mode: import.meta.env.MODE,
      isProd: import.meta.env.PROD,
      origin: window.location.origin,
    },
  });

  const response = await fetch(url, {
    ...fetchOptions,
    headers,
  });

  // Log the response
  console.log('📥 API Response:', {
    status: response.status,
    statusText: response.statusText,
    url: response.url,
  });

  if (!response.ok) {
    if (response.status === 401) {
      clearStoredAuthState();
      window.location.href = '/login';
      throw new Error('Session expired');
    }
    
    // Try to parse the error response as JSON
    try {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json();
        
        // Handle different error response formats
        if (errorData.error) {
          if (typeof errorData.error === 'string') {
            throw new Error(errorData.error);
          } else if (Array.isArray(errorData.error)) {
            // Handle Zod validation errors
            throw new Error(`Validation error: ${errorData.error.map((e: { message: string }) => e.message).join(', ')}`);
          } else if (typeof errorData.error === 'object') {
            throw new Error(`Error: ${JSON.stringify(errorData.error)}`);
          }
        } else if (errorData.message) {
          throw new Error(errorData.message);
        } else if (errorData.errors && Array.isArray(errorData.errors)) {
          throw new Error(`Validation errors: ${errorData.errors.join(', ')}`);
        }
      }
    } catch (parseError) {
      console.error('Error parsing error response:', parseError);
    }
    
    // If we couldn't extract a specific error message, use a more descriptive default
    throw new Error(`API request failed with status ${response.status}: ${response.statusText || 'Unknown error'}`);
  }

  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error parsing response:', error);
    throw new Error('Invalid JSON response from server');
  }
}

export async function uploadFile<T>(
  endpoint: string,
  file: File | FormData,
  options: FetchOptions = {}
): Promise<T> {
  const formData = file instanceof FormData ? file : new FormData();
  if (file instanceof File) {
    formData.append('file', file);
  }

  const token = !options.skipAuth && getAuthToken();
  const headers = new Headers(options.headers);

  // Only add Authorization header if we have a token
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  const normalizedEndpoint = endpoint.startsWith('/')
    ? endpoint.slice(1)
    : endpoint;

  // Special handling for chat endpoints - they don't need the /api prefix
  const isChatEndpoint = normalizedEndpoint.startsWith('chat/');
  
  const isProd =
    import.meta.env.PROD &&
    window.location.hostname === 'shipmate.fpsaus.com.au';
  
  // Get the base API URL
  const baseUrl = getApiUrl();
  
  // Properly construct the URL - ensure we have the right path format
  let url;
  if (isProd) {
    // In production, ensure we use the correct format without double /api
    url = `${baseUrl}/${normalizedEndpoint}`;
  } else {
    // In development, use the standard format
    url = `${baseUrl}/api/${normalizedEndpoint}`;
  }

  const response = await fetch(url, {
    method: 'POST',
    body: formData,
    ...options,
    headers,
    credentials: 'include', // Always include credentials for cookie support
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(error.message || `Failed to upload to ${endpoint}`);
  }

  return response.json();
}

export async function downloadFile(
  endpoint: string,
  options: FetchOptions = {}
): Promise<{ blob: Blob; filename: string }> {
  const token = !options.skipAuth && getAuthToken();
  const headers = new Headers(options.headers);

  // Only add Authorization header if we have a token
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }

  const normalizedEndpoint = endpoint.startsWith('/')
    ? endpoint.slice(1)
    : endpoint;
    
  // Special handling for chat endpoints - they don't need the /api prefix
  const isChatEndpoint = normalizedEndpoint.startsWith('chat/');
  
  const isProd =
    import.meta.env.PROD &&
    window.location.hostname === 'shipmate.fpsaus.com.au';
  
  // Get the base API URL
  const baseUrl = getApiUrl();
  
  // Properly construct the URL - ensure we have the right path format
  let url;
  if (isProd) {
    // In production, ensure we use the correct format without double /api
    url = `${baseUrl}/${normalizedEndpoint}`;
  } else {
    // In development, use the standard format
    url = `${baseUrl}/api/${normalizedEndpoint}`;
  }

  console.log('Making download request:', {
    url,
    headers: Object.fromEntries(headers.entries()),
  });

  try {
    const response = await fetch(url, {
      ...options,
      headers,
      credentials: 'include',
    });

    console.log('Download response:', {
      status: response.status,
      statusText: response.statusText,
      contentType: response.headers.get('Content-Type'),
      contentLength: response.headers.get('Content-Length'),
      contentDisposition: response.headers.get('Content-Disposition'),
    });

    // Check if response is JSON (error response)
    const contentType = response.headers.get('Content-Type');
    if (contentType?.includes('application/json')) {
      const errorData = await response.json();
      console.error('Download error:', errorData);
      throw new Error(
        errorData.error ||
          errorData.message ||
          `Failed to download from ${endpoint}`
      );
    }

    if (!response.ok) {
      console.error('Download failed:', {
        status: response.status,
        statusText: response.statusText,
      });
      throw new Error(
        `Failed to download from ${endpoint} (${response.status}: ${response.statusText})`
      );
    }

    // Get filename from Content-Disposition header
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'downloaded-file.xls'; // Default filename with extension

    if (contentDisposition) {
      // Try UTF-8 encoded filename first
      const utf8Match = /filename\*=UTF-8''([^;]+)/.exec(contentDisposition);
      if (utf8Match && utf8Match[1]) {
        filename = decodeURIComponent(utf8Match[1]);
      } else {
        // Fallback to regular filename
        const asciiMatch = /filename="?([^"]*)"?/.exec(contentDisposition);
        if (asciiMatch && asciiMatch[1]) {
          filename = asciiMatch[1].replace(/['"]/g, '');
        }
      }
    }

    // Get the blob with the correct type for Excel files
    const arrayBuffer = await response.arrayBuffer();
    const blob = new Blob([arrayBuffer], {
      type: 'application/vnd.ms-excel',
    });

    return { blob, filename };
  } catch (error) {
    console.error('Download request failed:', error);
    throw error;
  }
}
