import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';
import { Archive, Download, Edit, FileIcon, MessageSquare, MoreVertical, Trash2 } from 'lucide-react';
import React, { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { ChatMessagesProps } from './types';

/**
 * Chat messages component that displays messages in a chat session
 */
export const ChatMessages: React.FC<ChatMessagesProps> = ({
  messages,
  activeSession,
  onEditSession,
  onArchiveSession,
  onExportChat,
  onDeleteSession,
  onEditMessage,
  onDeleteMessage,
  formatDate
}) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);
  
  // Scroll to the bottom of the messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <Card className="lg:col-span-3 h-[700px] flex flex-col">
      <CardHeader className="pb-3">
        <CardTitle>
          {activeSession
            ? activeSession.title
            : 'New Chat'}
        </CardTitle>
        <CardDescription>
          {activeSession
            ? `Started ${formatDate(activeSession.createdAt)}`
            : 'Start a new conversation'}
        </CardDescription>
        {activeSession && (
          <div className="flex space-x-2 absolute top-6">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => onEditSession(activeSession.id, activeSession.title)}
                  >
                    <Edit className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Edit chat title</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={onExportChat}
                  >
                    <Download className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Export chat</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => onArchiveSession(activeSession.id)}
                  >
                    <Archive className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Archive chat</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => onDeleteSession(activeSession.id)}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Delete chat</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </CardHeader>
      <CardContent className="flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto pr-4">
          {messages.length === 0 ? (
            <div className="flex flex-col items-center justify-center h-full text-center text-muted-foreground">
              <MessageSquare className="h-12 w-12 mb-4" />
              <p>No messages yet</p>
              <p className="text-sm mt-2">
                Start a conversation by typing a message below
              </p>
            </div>
          ) : (
            <div className="space-y-4">
              {messages.map((message, index) => (
                <div
                  key={message.id || index}
                  className={cn(
                    'flex',
                    message.role === 'user' ? 'justify-end' : 'justify-start'
                  )}
                >
                  <div
                    className={cn(
                      'max-w-[80%] rounded-lg px-4 py-2',
                      message.role === 'user'
                        ? 'bg-primary text-primary-foreground'
                        : 'bg-muted'
                    )}
                  >
                    {message.documentId && (
                      <div className="mb-2 flex items-center space-x-2 text-xs">
                        <FileIcon className="h-4 w-4" />
                        <span>Document attached</span>
                      </div>
                    )}
                    <div className="whitespace-pre-wrap prose prose-sm dark:prose-invert max-w-none markdown-chat">
                      {message.content ? (
                        <ReactMarkdown
                          components={{
                            ul: ({ node, ...props }) => <ul className="my-2 pl-5 list-disc" {...props} />,
                            ol: ({ node, ...props }) => <ol className="my-2 pl-5 list-decimal" {...props} />,
                            li: ({ node, ...props }) => <li className="my-1" {...props} />,
                            p: ({ node, ...props }) => <p className="my-2" {...props} />
                          }}
                        >
                          {message.content}
                        </ReactMarkdown>
                      ) : (
                        <span className="text-muted-foreground italic">
                          [Empty message]
                        </span>
                      )}
                    </div>
                    <div
                      className={cn(
                        'mt-1 text-xs',
                        message.role === 'user'
                          ? 'text-primary-foreground/70'
                          : 'text-muted-foreground'
                      )}
                    >
                      {message.timestamp instanceof Date
                        ? format(message.timestamp, 'h:mm a')
                        : format(new Date(message.timestamp), 'h:mm a')}
                      
                      {message.role === 'user' && message.id && (
                        <span className="ml-2">
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="h-6 w-6"
                              >
                                <MoreVertical className="h-3 w-3" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent align="end">
                              <DropdownMenuItem
                                onClick={() => onEditMessage(message.id || '', message.content)}
                              >
                                <Edit className="h-4 w-4 mr-2" />
                                Edit
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => onDeleteMessage(message.id || '')}
                              >
                                <Trash2 className="h-4 w-4 mr-2" />
                                Delete
                              </DropdownMenuItem>
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}; 