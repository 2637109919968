import { Alert, AlertDescription } from '@/components/ui/alert';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { Checkbox } from '@/components/ui/checkbox';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { PERMISSIONS, Permission, formatPermission } from '@/lib/permissions';
import { USER_ROLES, User, UserRole, UserUpdateData } from '@/types/user';
import { AlertCircle, Loader2, Shield, UserCog, User as UserIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

interface EditUserDialogProps {
  user: User;
  onUpdateUser: (userData: UserUpdateData) => Promise<boolean>;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

// Validation rules
const validateForm = (data: UserUpdateData) => {
  const errors: Record<string, string> = {};
  
  if (!data.username.trim()) {
    errors.username = 'Username is required';
  } else if (data.username.length < 3) {
    errors.username = 'Username must be at least 3 characters';
  }
  
  if (!data.email.trim()) {
    errors.email = 'Email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
    errors.email = 'Please enter a valid email address';
  }
  
  if (!data.name.trim()) {
    errors.name = 'Name is required';
  }
  
  return errors;
};

export function EditUserDialog({
  user,
  onUpdateUser,
  open,
  onOpenChange,
}: EditUserDialogProps) {
  const [formData, setFormData] = useState<UserUpdateData>({
    id: user.id,
    username: user.username,
    email: user.email,
    name: user.name,
    role: user.role as UserRole,
    permissions: Array.isArray(user.permissions)
      ? user.permissions
      : typeof user.permissions === 'string'
        ? JSON.parse(user.permissions)
        : [],
    is_superadmin: user.is_superadmin,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});
  const [activeTab, setActiveTab] = useState('basic');

  // Reset form when dialog opens with new user data
  useEffect(() => {
    if (open) {
      setFormData({
        id: user.id,
        username: user.username,
        email: user.email,
        name: user.name,
        role: user.role as UserRole,
        permissions: Array.isArray(user.permissions)
          ? user.permissions
          : typeof user.permissions === 'string'
            ? JSON.parse(user.permissions)
            : [],
        is_superadmin: user.is_superadmin,
      });
      setError('');
      setValidationErrors({});
      setActiveTab('basic');
    }
  }, [open, user]);

  const handleInputChange = (field: keyof UserUpdateData, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    
    // Clear validation error for this field if it exists
    if (validationErrors[field]) {
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[field];
        return newErrors;
      });
    }
  };

  const handlePermissionChange = (permission: Permission) => {
    setFormData(prev => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission],
    }));
  };

  const validateAndSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const errors = validateForm(formData);
    
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      // If there are errors in the permissions tab, switch to it
      if (Object.keys(errors).some(key => key === 'permissions')) {
        setActiveTab('permissions');
      }
      return;
    }
    
    setError('');

    try {
      setIsSubmitting(true);
      console.log('Submitting user update:', formData);
      const success = await onUpdateUser(formData);
      if (success) {
        onOpenChange(false);
      }
    } catch (error) {
      setError(
        error instanceof Error ? error.message : 'Failed to update user'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  // Group permissions by category for better organization
  const permissionCategories = {
    'User Management': Object.values(PERMISSIONS).filter(p => p.startsWith('user')),
    'Content Management': Object.values(PERMISSIONS).filter(p => p.startsWith('content')),
    'System': Object.values(PERMISSIONS).filter(p => !p.startsWith('user') && !p.startsWith('content')),
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-md">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <UserCog className="h-5 w-5" />
            Edit User
          </DialogTitle>
          <DialogDescription>
            Update user information and permissions.
          </DialogDescription>
        </DialogHeader>
        
        <form onSubmit={validateAndSubmit}>
          <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="basic">Basic Info</TabsTrigger>
              <TabsTrigger value="permissions">Permissions</TabsTrigger>
            </TabsList>
            
            <TabsContent value="basic" className="space-y-4 mt-4">
              <div className="grid gap-2">
                <Label htmlFor="name">Full Name</Label>
                <Input
                  id="name"
                  value={formData.name}
                  onChange={e => handleInputChange('name', e.target.value)}
                  placeholder="Enter name"
                  className={validationErrors.name ? 'border-destructive' : ''}
                />
                {validationErrors.name && (
                  <p className="text-xs text-destructive">{validationErrors.name}</p>
                )}
              </div>
              
              <div className="grid gap-2">
                <Label htmlFor="username">Username</Label>
                <Input
                  id="username"
                  value={formData.username}
                  onChange={e => handleInputChange('username', e.target.value)}
                  placeholder="Enter username"
                  className={validationErrors.username ? 'border-destructive' : ''}
                />
                {validationErrors.username && (
                  <p className="text-xs text-destructive">{validationErrors.username}</p>
                )}
              </div>
              
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  type="email"
                  value={formData.email}
                  onChange={e => handleInputChange('email', e.target.value)}
                  placeholder="Enter email"
                  className={validationErrors.email ? 'border-destructive' : ''}
                />
                {validationErrors.email && (
                  <p className="text-xs text-destructive">{validationErrors.email}</p>
                )}
              </div>
              
              <div className="grid gap-2">
                <Label htmlFor="role">Role</Label>
                <Select
                  value={formData.role}
                  onValueChange={value => handleInputChange('role', value as UserRole)}
                >
                  <SelectTrigger id="role" className="w-full">
                    <SelectValue placeholder="Select role" />
                  </SelectTrigger>
                  <SelectContent>
                    {USER_ROLES.map(role => (
                      <SelectItem key={role} value={role}>
                        <div className="flex items-center">
                          {role === 'admin' ? (
                            <Shield className="mr-2 h-4 w-4 text-primary" />
                          ) : (
                            <UserIcon className="mr-2 h-4 w-4 text-muted-foreground" />
                          )}
                          <span className="capitalize">{role}</span>
                        </div>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <p className="text-xs text-muted-foreground">
                  {formData.role === 'admin' 
                    ? 'Admins have full access to all features.' 
                    : 'Regular users have limited access based on permissions.'}
                </p>
              </div>
            </TabsContent>
            
            <TabsContent value="permissions" className="mt-4">
              <div className="mb-4">
                <p className="text-sm text-muted-foreground mb-2">
                  Select the permissions for this user. {formData.role === 'admin' && (
                    <span className="text-primary font-medium">Note: Admins have all permissions by default.</span>
                  )}
                </p>
                
                <div className="flex flex-wrap gap-2 mb-4">
                  <Badge variant="outline" className="cursor-pointer" onClick={() => {
                    setFormData(prev => ({
                      ...prev,
                      permissions: Object.values(PERMISSIONS)
                    }));
                  }}>
                    Select All
                  </Badge>
                  <Badge variant="outline" className="cursor-pointer" onClick={() => {
                    setFormData(prev => ({
                      ...prev,
                      permissions: []
                    }));
                  }}>
                    Clear All
                  </Badge>
                </div>
              </div>
              
              <ScrollArea className="h-[300px] pr-4">
                {Object.entries(permissionCategories).map(([category, permissions]) => (
                  <div key={category} className="mb-4">
                    <h4 className="text-sm font-medium mb-2">{category}</h4>
                    <div className="grid grid-cols-1 gap-2">
                      {permissions.map(permission => (
                        <div key={permission} className="flex items-center space-x-2 p-2 rounded-md hover:bg-muted">
                          <Checkbox
                            id={`edit-${permission}`}
                            checked={formData.permissions.includes(permission)}
                            onCheckedChange={() => handlePermissionChange(permission)}
                          />
                          <label
                            htmlFor={`edit-${permission}`}
                            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 cursor-pointer flex-1"
                          >
                            {formatPermission(permission)}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </ScrollArea>
            </TabsContent>
          </Tabs>
          
          {error && (
            <Alert variant="destructive" className="mt-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          <DialogFooter className="mt-6">
            <Button
              type="button"
              variant="outline"
              onClick={() => onOpenChange(false)}
            >
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Updating...
                </>
              ) : (
                <>
                  <UserCog className="mr-2 h-4 w-4" />
                  Update User
                </>
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
}
