import { Progress } from '@/components/ui/progress';
import { useToast } from '@/components/ui/use-toast';
import { cn } from '@/lib/utils';
import { Upload } from 'lucide-react';
import React, { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export interface FileUploadAreaProps {
  selectedFiles: File[];
  isUploading: boolean;
  onFilesSelected: (files: File[]) => void;
  uploadProgress?: number;
  maxFileSize?: number;
  validFileTypes?: string[];
  accept?: string;
}

export function FileUploadArea({
  selectedFiles,
  isUploading,
  onFilesSelected,
  uploadProgress = 0,
  maxFileSize = 10 * 1024 * 1024, // 10MB default
  validFileTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/tiff', 'image/heic'],
  accept,
}: FileUploadAreaProps) {
  const { toast } = useToast();
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  // Create a safe wrapper for onFilesSelected to prevent "n is not a function" errors
  const safeOnFilesSelected = useCallback((files: File[]) => {
    if (typeof onFilesSelected === 'function') {
      onFilesSelected(files);
    } else {
      console.error('onFilesSelected is not a function', onFilesSelected);
    }
  }, [onFilesSelected]);

  const onDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(false);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const droppedFiles = Array.from(e.dataTransfer.files);
        const validFiles = droppedFiles.filter((file) => {
          // Check file type
          if (!validFileTypes.includes(file.type)) {
            toast({
              title: 'Invalid file type',
              description: `${file.name}: Please upload only supported file types`,
              variant: 'destructive',
            });
            return false;
          }

          // Check file size
          if (file.size > maxFileSize) {
            toast({
              title: 'File too large',
              description: `${file.name}: Maximum file size is ${maxFileSize / (1024 * 1024)}MB`,
              variant: 'destructive',
            });
            return false;
          }

          return true;
        });

        if (validFiles.length > 0) {
          safeOnFilesSelected(validFiles);
        }
      }
    },
    [maxFileSize, safeOnFilesSelected, toast, validFileTypes]
  );

  const handleFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files && e.target.files.length > 0) {
        const selectedFiles = Array.from(e.target.files);
        const validFiles = selectedFiles.filter((file) => {
          // Check file type
          if (!validFileTypes.includes(file.type)) {
            toast({
              title: 'Invalid file type',
              description: `${file.name}: Please upload only supported file types`,
              variant: 'destructive',
            });
            return false;
          }

          // Check file size
          if (file.size > maxFileSize) {
            toast({
              title: 'File too large',
              description: `${file.name}: Maximum file size is ${maxFileSize / (1024 * 1024)}MB`,
              variant: 'destructive',
            });
            return false;
          }

          return true;
        });

        if (validFiles.length > 0) {
          safeOnFilesSelected(validFiles);
        }
      }
    },
    [maxFileSize, safeOnFilesSelected, toast, validFileTypes]
  );

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
  }, []);

  const handleClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: isUploading,
    accept: accept
      ? accept.split(',').reduce(
          (acc, type) => {
            acc[type] = [];
            return acc;
          },
          {} as Record<string, string[]>
        )
      : validFileTypes?.reduce(
          (acc, type) => {
            acc[type] = [];
            return acc;
          },
          {} as Record<string, string[]>
        ),
  });

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={cn(
          "border-2 border-dashed rounded-lg p-6 text-center cursor-pointer transition-colors",
          isDragging
            ? "border-primary bg-primary/10"
            : "border-gray-300 hover:border-primary",
          isUploading && "opacity-50 cursor-not-allowed"
        )}
        onDrop={onDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onClick={isUploading ? undefined : handleClick}
      >
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          onChange={handleFileChange}
          multiple
          disabled={isUploading}
          title="Upload files"
        />
        <div className="flex flex-col items-center justify-center space-y-2">
          <Upload className="h-8 w-8 text-gray-500" />
          <div className="text-sm text-gray-500">
            {selectedFiles.length > 0 ? (
              <div>
                <p className="font-medium">Selected files:</p>
                <ul className="list-disc list-inside">
                  {selectedFiles.map((file, index) => (
                    <li key={index} className="text-left">
                      {file.name} ({(file.size / 1024).toFixed(2)} KB)
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <p>
                <span className="font-medium">Click to upload</span> or drag and
                drop
              </p>
            )}
          </div>
          {isUploading && (
            <div className="w-full mt-2">
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-full bg-primary transition-all duration-300 ease-in-out"
                  style={{ width: `${uploadProgress}%` }}
                ></div>
              </div>
              <p className="text-xs text-gray-500 mt-1">
                Uploading... {uploadProgress}%
              </p>
            </div>
          )}
        </div>
      </div>
      
      {isUploading && uploadProgress > 0 && (
        <div className="space-y-2">
          <Progress value={uploadProgress} className="h-2" />
          <p className="text-xs text-center text-muted-foreground">
            Uploading... {uploadProgress.toFixed(0)}%
          </p>
        </div>
      )}
    </div>
  );
}
