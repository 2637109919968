import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { fetchApi } from '@/lib/api/utils/fetch';
import { useMutation } from '@tanstack/react-query';
import {
    AlertTriangle,
    DollarSign,
    Loader2,
    Package,
    Thermometer
} from 'lucide-react';
import { useState } from 'react';

interface FCLRate {
  id: string;
  carrierId: string;
  carrierName: string;
  exportPort: string;
  importPort: string;
  containerTypes: string[];
  validityFrom: string;
  validityTo: string;
  baseRate: number;
  routeType: 'direct' | 'transit';
  transitPorts?: string[];
  transitTime?: string;
  surcharges: {
    hazardous?: {
      class: string;
      amount: number;
      currency: string;
    }[];
    refrigeration?: number;
    valuable?: number;
    project?: number;
    obs?: number;
    thd?: number;
    thl?: number;
    isl?: number;
  };
}

interface QuoteDetails {
  id?: string;
  quoteNumber?: string;
  customerName: string;
  customerEmail?: string;
  customerPhone?: string;
  cargoType: 'general' | 'hazardous' | 'valuable' | 'project';
  containerType: string;
  quantity: number;
  exportPort: string;
  importPort: string;
  routeType: 'direct' | 'transit';
  transitPorts?: string[];
  transitTime?: string;
  baseRate: number;
  surcharges: {
    hazardous?: {
      class: string;
      amount: number;
      currency: string;
    }[];
    refrigeration?: number;
    valuable?: number;
    project?: number;
    obs?: number;
    thd?: number;
    thl?: number;
    isl?: number;
  };
  totalAmount: number;
  specialRequirements?: string;
  remarks?: string;
  status: 'draft' | 'sent' | 'accepted' | 'rejected';
  validityFrom: string;
  validityTo: string;
  comments?: string;
  lastModified?: string;
  createdAt?: string;
  createdBy?: string;
  lastModifiedBy?: string;
  hazardousClass?: string;
  cargoValue?: number;
  temperature?: string;
}

interface QuoteHistoryEntry {
  id: string;
  quoteId: string;
  fieldName: string;
  oldValue?: string;
  newValue: string;
  changedBy: string;
  changedAt: string;
  changeComment?: string;
}

interface SearchResponse {
  recommendedQuote?: QuoteDetails;
  analysis?: {
    alternativeOptions?: QuoteDetails[];
  };
}

interface FCLSearchProps {}

const containerTypes = [
  { value: '20GP', label: "20' GP (Standard)" },
  { value: '20ST', label: "20' ST (Standard)" },
  { value: '20HC', label: "20' HC (High Cube)" },
  { value: '20REF', label: "20' REF (Refrigerated)" },
  { value: '20RF', label: "20' RF (Refrigerated)" },
  { value: '20RH', label: "20' RH (Refrigerated High Cube)" },
  { value: '40GP', label: "40' GP (Standard)" },
  { value: '40ST', label: "40' ST (Standard)" },
  { value: '40HC', label: "40' HC (High Cube)" },
  { value: '40REF', label: "40' REF (Refrigerated)" },
  { value: '40RF', label: "40' RF (Refrigerated)" },
  { value: '40RH', label: "40' RH (Refrigerated High Cube)" },
  { value: '40NOR', label: "40' NOR (Non-Operating Reefer)" },
  { value: '40RAD', label: "40' RAD (Refrigerated)" },
];

const cargoTypes = [
  { value: 'general', label: 'General Cargo' },
  { value: 'hazardous', label: 'Hazardous' },
  { value: 'valuable', label: 'High Value' },
  { value: 'project', label: 'Project Logistics' },
];

const hazardousClasses = [
  { value: 'class1', label: 'Class 1 - Explosives' },
  { value: 'class2', label: 'Class 2 - Gases' },
  { value: 'class3', label: 'Class 3 - Flammable Liquids' },
  { value: 'class4', label: 'Class 4 - Flammable Solids' },
  { value: 'class5', label: 'Class 5 - Oxidizing Substances' },
  { value: 'class6', label: 'Class 6 - Toxic Substances' },
  { value: 'class7', label: 'Class 7 - Radioactive Material' },
  { value: 'class8', label: 'Class 8 - Corrosive Substances' },
  { value: 'class9', label: 'Class 9 - Miscellaneous Dangerous Goods' },
];

const FCLSearch: React.FC<FCLSearchProps> = () => {
  const [searchParams, setSearchParams] = useState({
    naturalLanguageQuery: '',
    exportPort: '',
    importPort: '',
    containerType: '',
    cargoType: 'general' as QuoteDetails['cargoType'],
    validityDate: new Date().toISOString().split('T')[0],
    routePreference: 'any' as 'direct' | 'any',
    hazardous: false,
    hazardousClass: '',
    refrigerated: false,
    temperature: '',
    valuable: false,
    cargoValue: 0,
    projectLogistics: false,
  });

  const [shouldSearch, setShouldSearch] = useState(false);
  const { toast } = useToast();

  // Search rates mutation
  const searchRatesMutation = useMutation<
    SearchResponse,
    Error,
    typeof searchParams
  >({
    mutationFn: async (params: typeof searchParams) => {
      // Convert search parameters into a natural language query for vector search
      let searchQuery = params.naturalLanguageQuery;
      if (!searchQuery) {
        searchQuery = `Find FCL shipping rates from ${params.exportPort || 'any port'} to ${
          params.importPort || 'any port'
        }`;
        if (params.containerType) {
          searchQuery += ` for ${params.containerType} container`;
        }
        if (params.hazardous) {
          searchQuery += ` with hazardous cargo class ${params.hazardousClass}`;
        }
        if (params.refrigerated) {
          searchQuery += ` requiring refrigeration at ${params.temperature}`;
        }
        if (params.valuable) {
          searchQuery += ` for valuable cargo worth ${params.cargoValue}`;
        }
        if (params.projectLogistics) {
          searchQuery += ` requiring project logistics`;
        }
      }

      const response = await fetchApi<SearchResponse>('fcl/search', {
        method: 'POST',
        body: JSON.stringify({
          query: searchQuery,
          exportPort: params.exportPort,
          importPort: params.importPort,
          containerType: params.containerType,
          cargoType: params.cargoType,
          validityDate: params.validityDate,
          routePreference: params.routePreference,
          specialRequirements: {
            hazardous: params.hazardous,
            hazardousClass: params.hazardousClass,
            refrigerated: params.refrigerated,
            temperature: params.temperature,
            valuable: params.valuable,
            cargoValue: params.cargoValue,
            projectLogistics: params.projectLogistics,
          },
          useVectorSearch: true, // Signal to use BGE embeddings for semantic search
          useLlama: true, // Signal to use Llama for result analysis
        }),
      });

      return response;
    },
    onError: error => {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to search rates',
        variant: 'destructive',
      });
    },
  });

  const handleSearch = () => {
    searchRatesMutation.mutate(searchParams);
  };

  return (
    <div className="container mx-auto p-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>FCL Quote Search</CardTitle>
          <CardDescription>
            Search for FCL quotes using natural language or specific criteria
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            {/* Natural Language Search */}
            <div className="space-y-2">
              <Label htmlFor="naturalLanguageQuery">
                Natural Language Search
              </Label>
              <Textarea
                id="naturalLanguageQuery"
                placeholder="Describe what you're looking for... (e.g., 'I need a quote for shipping 2 containers from Shanghai to Los Angeles, cargo is temperature sensitive')"
                value={searchParams.naturalLanguageQuery}
                onChange={e =>
                  setSearchParams(prev => ({
                    ...prev,
                    naturalLanguageQuery: e.target.value,
                  }))
                }
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Port Selection */}
              <div className="space-y-2">
                <Label htmlFor="exportPort">Export Port</Label>
                <Input
                  id="exportPort"
                  value={searchParams.exportPort}
                  onChange={e =>
                    setSearchParams(prev => ({
                      ...prev,
                      exportPort: e.target.value,
                    }))
                  }
                  placeholder="Enter export port"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="importPort">Import Port</Label>
                <Input
                  id="importPort"
                  value={searchParams.importPort}
                  onChange={e =>
                    setSearchParams(prev => ({
                      ...prev,
                      importPort: e.target.value,
                    }))
                  }
                  placeholder="Enter import port"
                />
              </div>

              {/* Container Type */}
              <div className="space-y-2">
                <Label htmlFor="containerType">Container Type</Label>
                <Select
                  value={searchParams.containerType}
                  onValueChange={value =>
                    setSearchParams(prev => ({
                      ...prev,
                      containerType: value,
                    }))
                  }
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select container type" />
                  </SelectTrigger>
                  <SelectContent>
                    {containerTypes.map(type => (
                      <SelectItem key={type.value} value={type.value}>
                        {type.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>

            {/* Special Requirements */}
            <div className="space-y-4">
              <Label>Special Requirements</Label>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                {/* Hazardous Cargo */}
                <Card className="p-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="hazardous"
                      checked={searchParams.hazardous}
                      onCheckedChange={checked =>
                        setSearchParams(prev => ({
                          ...prev,
                          hazardous: checked as boolean,
                        }))
                      }
                    />
                    <Label htmlFor="hazardous" className="flex items-center">
                      <AlertTriangle className="w-4 h-4 mr-2" />
                      Hazardous
                    </Label>
                  </div>
                  {searchParams.hazardous && (
                    <div className="mt-2">
                      <Select
                        value={searchParams.hazardousClass}
                        onValueChange={value =>
                          setSearchParams(prev => ({
                            ...prev,
                            hazardousClass: value,
                          }))
                        }
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Select hazard class" />
                        </SelectTrigger>
                        <SelectContent>
                          {hazardousClasses.map(cls => (
                            <SelectItem key={cls.value} value={cls.value}>
                              {cls.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  )}
                </Card>

                {/* Refrigerated */}
                <Card className="p-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="refrigerated"
                      checked={searchParams.refrigerated}
                      onCheckedChange={checked =>
                        setSearchParams(prev => ({
                          ...prev,
                          refrigerated: checked as boolean,
                        }))
                      }
                    />
                    <Label htmlFor="refrigerated" className="flex items-center">
                      <Thermometer className="w-4 h-4 mr-2" />
                      Refrigerated
                    </Label>
                  </div>
                  {searchParams.refrigerated && (
                    <div className="mt-2">
                      <Input
                        type="text"
                        placeholder="Temperature (e.g., -18°C)"
                        value={searchParams.temperature}
                        onChange={e =>
                          setSearchParams(prev => ({
                            ...prev,
                            temperature: e.target.value,
                          }))
                        }
                      />
                    </div>
                  )}
                </Card>

                {/* Valuable Cargo */}
                <Card className="p-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="valuable"
                      checked={searchParams.valuable}
                      onCheckedChange={checked =>
                        setSearchParams(prev => ({
                          ...prev,
                          valuable: checked as boolean,
                        }))
                      }
                    />
                    <Label htmlFor="valuable" className="flex items-center">
                      <DollarSign className="w-4 h-4 mr-2" />
                      Valuable
                    </Label>
                  </div>
                  {searchParams.valuable && (
                    <div className="mt-2">
                      <Input
                        type="number"
                        placeholder="Cargo value"
                        value={searchParams.cargoValue || ''}
                        onChange={e =>
                          setSearchParams(prev => ({
                            ...prev,
                            cargoValue: parseFloat(e.target.value) || 0,
                          }))
                        }
                      />
                    </div>
                  )}
                </Card>

                {/* Project Logistics */}
                <Card className="p-4">
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="projectLogistics"
                      checked={searchParams.projectLogistics}
                      onCheckedChange={checked =>
                        setSearchParams(prev => ({
                          ...prev,
                          projectLogistics: checked as boolean,
                        }))
                      }
                    />
                    <Label
                      htmlFor="projectLogistics"
                      className="flex items-center"
                    >
                      <Package className="w-4 h-4 mr-2" />
                      Project Logistics
                    </Label>
                  </div>
                </Card>
              </div>
            </div>

            <Button
              className="w-full"
              onClick={handleSearch}
              disabled={searchRatesMutation.isLoading}
            >
              {searchRatesMutation.isLoading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Searching...
                </>
              ) : (
                'Search Quotes'
              )}
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Results Section */}
      {searchRatesMutation.isSuccess && searchRatesMutation.data && (
        <Card>
          <CardHeader>
            <CardTitle>Search Results</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              {searchRatesMutation.data.recommendedQuote && (
                <div>
                  <h3 className="text-lg font-semibold mb-2">
                    Recommended Quote
                  </h3>
                  <QuoteCard
                    quote={searchRatesMutation.data.recommendedQuote}
                  />
                </div>
              )}

              {searchRatesMutation.data.analysis?.alternativeOptions &&
                searchRatesMutation.data.analysis.alternativeOptions.length >
                  0 && (
                  <div>
                    <h3 className="text-lg font-semibold mb-2">
                      Alternative Options
                    </h3>
                    <div className="space-y-4">
                      {searchRatesMutation.data.analysis.alternativeOptions.map(
                        (quote: QuoteDetails, index: number) => (
                          <QuoteCard key={index} quote={quote} isAlternative />
                        )
                      )}
                    </div>
                  </div>
                )}
            </div>
          </CardContent>
        </Card>
      )}

      {searchRatesMutation.isError && (
        <Card className="bg-red-50">
          <CardContent className="text-red-600 p-4">
            {searchRatesMutation.error instanceof Error
              ? searchRatesMutation.error.message
              : 'An error occurred while searching'}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

const QuoteCard: React.FC<{ quote: QuoteDetails; isAlternative?: boolean }> = ({
  quote,
  isAlternative,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card
      className={`${isAlternative ? 'bg-gray-50' : 'bg-white'} cursor-pointer hover:shadow-md transition-shadow`}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <CardContent className="p-4">
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <div>
            <Label className="text-sm text-gray-500">Route</Label>
            <p className="font-medium">
              {quote.exportPort} → {quote.importPort}
            </p>
          </div>
          <div>
            <Label className="text-sm text-gray-500">Container</Label>
            <p className="font-medium">{quote.containerType}</p>
          </div>
          <div>
            <Label className="text-sm text-gray-500">Base Rate</Label>
            <p className="font-medium">${quote.baseRate.toLocaleString()}</p>
          </div>
          <div>
            <Label className="text-sm text-gray-500">Transit Time</Label>
            <p className="font-medium">{quote.transitTime || 'N/A'}</p>
          </div>
        </div>

        {isExpanded && (
          <>
            <div className="mt-4 border-t pt-4">
              <Label className="text-sm text-gray-500 mb-2">Surcharges</Label>
              <div className="space-y-2">
                {quote.surcharges.hazardous &&
                  quote.surcharges.hazardous.length > 0 && (
                    <div>
                      <p className="text-sm font-medium">Hazardous Cargo:</p>
                      {quote.surcharges.hazardous.map(
                        (
                          surcharge: {
                            class: string;
                            amount: number;
                            currency: string;
                          },
                          index: number
                        ) => (
                          <p key={index} className="text-sm ml-4">
                            Class {surcharge.class}: $
                            {surcharge.amount.toLocaleString()}{' '}
                            {surcharge.currency}
                          </p>
                        )
                      )}
                    </div>
                  )}
                {quote.surcharges.refrigeration && (
                  <p className="text-sm">
                    Refrigeration: $
                    {quote.surcharges.refrigeration.toLocaleString()}
                  </p>
                )}
                {quote.surcharges.valuable && (
                  <p className="text-sm">
                    Valuable Cargo: $
                    {quote.surcharges.valuable.toLocaleString()}
                  </p>
                )}
                {quote.surcharges.project && (
                  <p className="text-sm">
                    Project Cargo: ${quote.surcharges.project.toLocaleString()}
                  </p>
                )}
                {quote.surcharges.obs && (
                  <p className="text-sm">
                    OBS: ${quote.surcharges.obs.toLocaleString()}
                  </p>
                )}
                {quote.surcharges.thd && (
                  <p className="text-sm">
                    THD: ${quote.surcharges.thd.toLocaleString()}
                  </p>
                )}
                {quote.surcharges.thl && (
                  <p className="text-sm">
                    THL: ${quote.surcharges.thl.toLocaleString()}
                  </p>
                )}
                {quote.surcharges.isl && (
                  <p className="text-sm">
                    ISL: ${quote.surcharges.isl.toLocaleString()}
                  </p>
                )}
              </div>
            </div>

            <div className="mt-4 border-t pt-4">
              <Label className="text-sm text-gray-500">Total Amount</Label>
              <p className="font-medium text-lg">
                ${quote.totalAmount.toLocaleString()}
              </p>
            </div>

            {quote.specialRequirements && (
              <div className="mt-4 border-t pt-4">
                <Label className="text-sm text-gray-500">
                  Special Requirements
                </Label>
                <p className="text-sm">{quote.specialRequirements}</p>
              </div>
            )}

            {quote.remarks && (
              <div className="mt-4 border-t pt-4">
                <Label className="text-sm text-gray-500">Remarks</Label>
                <p className="text-sm">{quote.remarks}</p>
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default FCLSearch;
