import config from '@/lib/config';
import { User } from '@/types/user';

interface AuthState {
  token: string | null;
  user: User | null;
  isAuthenticated: boolean;
}

const AUTH_STORAGE_KEY = 'token';
const USER_STORAGE_KEY = 'user';

const defaultState: AuthState = {
  token: null,
  user: null,
  isAuthenticated: false,
};

export function getStoredAuthState(): AuthState {
  try {
    const token = localStorage.getItem(AUTH_STORAGE_KEY);
    const userJson = localStorage.getItem(USER_STORAGE_KEY);

    if (!token) {
      return defaultState;
    }

    let user: User | null = null;
    if (userJson) {
      try {
        user = JSON.parse(userJson);
      } catch (error) {
        console.error('Failed to parse stored user:', error);
      }
    }

    return {
      token,
      user,
      isAuthenticated: true,
    };
  } catch (error) {
    console.error('Failed to get stored auth state:', error);
    return defaultState;
  }
}

export function setStoredAuthState(state: AuthState): void {
  try {
    if (state.token) {
      localStorage.setItem(AUTH_STORAGE_KEY, state.token);
      if (state.user) {
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(state.user));
      }
    } else {
      clearStoredAuthState();
    }
  } catch (error) {
    console.error('Failed to store auth state:', error);
  }
}

export function clearStoredAuthState(): void {
  try {
    localStorage.removeItem(AUTH_STORAGE_KEY);
    localStorage.removeItem(USER_STORAGE_KEY);
  } catch (error) {
    console.error('Failed to clear auth state:', error);
  }
}

export function setAuthToken(token: string, user: User): void {
  if (!token) {
    console.error('Invalid token');
    return;
  }

  setStoredAuthState({
    token,
    user,
    isAuthenticated: true,
  });
}

export function getAuthToken(): string | null {
  return localStorage.getItem(AUTH_STORAGE_KEY);
}

export function getStoredUser(): User | null {
  try {
    const userJson = localStorage.getItem(USER_STORAGE_KEY);
    return userJson ? JSON.parse(userJson) : null;
  } catch (error) {
    console.error('Failed to get stored user:', error);
    return null;
  }
}

export function isAuthenticated(): boolean {
  return !!getAuthToken() && !!getStoredUser();
}

/**
 * Attempts to refresh the authentication token
 * @returns {Promise<boolean>} True if token was successfully refreshed, false otherwise
 */
export async function refreshAuthToken(): Promise<boolean> {
  try {
    console.log('Attempting to refresh auth token...');
    
    // Get the current token
    const currentToken = getAuthToken();
    if (!currentToken) {
      console.error('No token to refresh');
      return false;
    }
    
    // Call the refresh token endpoint
    const response = await fetch(`${config.apiUrl}/auth/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentToken}`
      },
      credentials: 'include'
    });
    
    if (!response.ok) {
      console.error('Failed to refresh token:', response.status, response.statusText);
      // If refresh fails, clear auth state
      clearStoredAuthState();
      return false;
    }
    
    const data = await response.json();
    
    if (!data.token || !data.user) {
      console.error('Invalid response from refresh token endpoint');
      clearStoredAuthState();
      return false;
    }
    
    // Store the new token and user data
    setAuthToken(data.token, data.user);
    console.log('Auth token refreshed successfully');
    
    return true;
  } catch (error) {
    console.error('Error refreshing token:', error);
    clearStoredAuthState();
    return false;
  }
}
