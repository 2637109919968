import { User } from '@/types/user';

export type Permission =
  | 'view_dashboard'
  | 'manage_users'
  | 'manage_settings'
  | 'manage_tenants'
  | 'view_reports'
  | 'manage_reports'
  | 'view_fcl_rates'
  | 'manage_fcl_rates'
  | 'view_hs_codes'
  | 'manage_hs_codes';

export const PERMISSIONS = {
  VIEW_DASHBOARD: 'view_dashboard' as Permission,
  MANAGE_USERS: 'manage_users' as Permission,
  MANAGE_SETTINGS: 'manage_settings' as Permission,
  MANAGE_TENANTS: 'manage_tenants' as Permission,
  VIEW_REPORTS: 'view_reports' as Permission,
  MANAGE_REPORTS: 'manage_reports' as Permission,
  VIEW_FCL_RATES: 'view_fcl_rates' as Permission,
  MANAGE_FCL_RATES: 'manage_fcl_rates' as Permission,
  VIEW_HS_CODES: 'view_hs_codes' as Permission,
  MANAGE_HS_CODES: 'manage_hs_codes' as Permission,
};

// Define page access permissions
export const PAGE_PERMISSIONS = {
  dashboard: [], // Everyone can access dashboard
  convert: [PERMISSIONS.VIEW_DASHBOARD],
  hsLookup: [PERMISSIONS.VIEW_HS_CODES],
  fclSearch: [PERMISSIONS.VIEW_FCL_RATES],
  docCheck: [PERMISSIONS.VIEW_REPORTS],
  chat: [PERMISSIONS.VIEW_DASHBOARD],
  users: [PERMISSIONS.MANAGE_USERS],
  settings: [PERMISSIONS.MANAGE_SETTINGS],
  superadmin: [PERMISSIONS.VIEW_DASHBOARD],
} as const;

// Default permissions for each role
export const ROLE_PERMISSIONS: Record<string, Permission[]> = {
  admin: [
    PERMISSIONS.VIEW_DASHBOARD,
    PERMISSIONS.VIEW_HS_CODES,
    PERMISSIONS.VIEW_FCL_RATES,
    PERMISSIONS.VIEW_REPORTS,
    PERMISSIONS.MANAGE_USERS,
    PERMISSIONS.MANAGE_SETTINGS,
    PERMISSIONS.MANAGE_TENANTS,
    PERMISSIONS.MANAGE_REPORTS,
    PERMISSIONS.MANAGE_FCL_RATES,
    PERMISSIONS.MANAGE_HS_CODES,
  ],
  user: [
    PERMISSIONS.VIEW_DASHBOARD,
    PERMISSIONS.VIEW_HS_CODES,
    PERMISSIONS.VIEW_FCL_RATES,
    PERMISSIONS.VIEW_REPORTS,
  ],
  manager: [
    PERMISSIONS.VIEW_DASHBOARD,
    PERMISSIONS.VIEW_HS_CODES,
    PERMISSIONS.VIEW_FCL_RATES,
    PERMISSIONS.VIEW_REPORTS,
    PERMISSIONS.MANAGE_REPORTS,
  ],
  viewer: [
    PERMISSIONS.VIEW_DASHBOARD,
    PERMISSIONS.VIEW_HS_CODES,
    PERMISSIONS.VIEW_FCL_RATES,
    PERMISSIONS.VIEW_REPORTS,
  ],
};

// Feature to permission mapping
export const FEATURE_TO_PERMISSION_MAP: Record<string, Permission> = {
  'convert_to_excel': PERMISSIONS.VIEW_DASHBOARD,
  'fcl_search': PERMISSIONS.VIEW_FCL_RATES,
  'chat': PERMISSIONS.VIEW_DASHBOARD,
  'hs_lookup': PERMISSIONS.VIEW_HS_CODES,
  'doc_check': PERMISSIONS.VIEW_REPORTS,
  'manage_users': PERMISSIONS.MANAGE_USERS,
  'manage_settings': PERMISSIONS.MANAGE_SETTINGS,
  'view_dashboard': PERMISSIONS.VIEW_DASHBOARD,
  'view_reports': PERMISSIONS.VIEW_REPORTS,
  'manage_reports': PERMISSIONS.MANAGE_REPORTS,
  'view_fcl_rates': PERMISSIONS.VIEW_FCL_RATES,
  'manage_fcl_rates': PERMISSIONS.MANAGE_FCL_RATES,
  'view_hs_codes': PERMISSIONS.VIEW_HS_CODES,
  'manage_hs_codes': PERMISSIONS.MANAGE_HS_CODES,
  'manage_tenants': PERMISSIONS.MANAGE_TENANTS,
  // Add direct mappings for page permissions
  'dashboard': PERMISSIONS.VIEW_DASHBOARD,
  'convert': PERMISSIONS.VIEW_DASHBOARD,
  'hsLookup': PERMISSIONS.VIEW_HS_CODES,
  'fclSearch': PERMISSIONS.VIEW_FCL_RATES,
  'docCheck': PERMISSIONS.VIEW_REPORTS,
  'users': PERMISSIONS.MANAGE_USERS,
  'settings': PERMISSIONS.MANAGE_SETTINGS,
  'superadmin': PERMISSIONS.MANAGE_TENANTS
};

// Check if user has permission to access a page
export function hasPageAccess(
  user: User | null,
  page: keyof typeof PAGE_PERMISSIONS
): boolean {
  if (!user) {
    console.log(`Access denied: No user provided for page ${page}`);
    return false;
  }

  // Superadmin has access to everything
  if (user.is_superadmin) {
    console.log(`Access granted: User ${user.email} is superadmin, accessing ${page}`);
    return true;
  }

  // Admins have access to everything except superadmin pages
  if (user.role === 'admin' && page !== 'superadmin') {
    console.log(`Access granted: User ${user.email} is admin, accessing ${page}`);
    return true;
  }

  // For dashboard or pages without specific permissions
  if (PAGE_PERMISSIONS[page].length === 0) {
    console.log(`Access granted: Page ${page} has no specific permissions required`);
    return true;
  }

  // Check if user has any of the required permissions
  const userPermissions = getUserPermissions(user);
  
  // Check if the user has the direct page permission mapping
  const directPagePermission = FEATURE_TO_PERMISSION_MAP[page];
  const hasDirectPermission = directPagePermission && userPermissions.includes(directPagePermission);
  
  // Check if the user has any of the required permissions from PAGE_PERMISSIONS
  const hasRequiredPermission = PAGE_PERMISSIONS[page].some(permission => 
    userPermissions.includes(permission)
  );
  
  const hasAccess = hasDirectPermission || hasRequiredPermission;
  
  console.log(`Permission check for ${user.email} accessing ${page}:`, {
    requiredPermissions: PAGE_PERMISSIONS[page],
    directPagePermission,
    userPermissions,
    role: user.role,
    hasDirectPermission,
    hasRequiredPermission,
    hasAccess
  });
  
  return hasAccess;
}

export function formatPermission(permission: Permission): string {
  return permission
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function getUserPermissions(user: User): Permission[] {
  // Superadmin has all permissions
  if (user.is_superadmin) {
    return Object.values(PERMISSIONS);
  }

  // If user has a role with default permissions and no specific permissions set,
  // return the default permissions for that role
  if (user.role && (!user.permissions || (Array.isArray(user.permissions) && user.permissions.length === 0))) {
    return ROLE_PERMISSIONS[user.role] || [];
  }

  // Handle string permissions (from database)
  if (typeof user.permissions === 'string') {
    try {
      // Try to parse as JSON
      const parsedPermissions = JSON.parse(user.permissions);
      
      // If parsed permissions is an array of strings, map feature names to permission types
      if (Array.isArray(parsedPermissions)) {
        const mappedPermissions: Permission[] = [];
        
        // Map each feature to its corresponding permission
        parsedPermissions.forEach(feature => {
          // If the feature is already a Permission type, add it directly
          if (Object.values(PERMISSIONS).includes(feature as Permission)) {
            mappedPermissions.push(feature as Permission);
          } 
          // Otherwise, check if it's a feature name that maps to a permission
          else if (FEATURE_TO_PERMISSION_MAP[feature]) {
            mappedPermissions.push(FEATURE_TO_PERMISSION_MAP[feature]);
            
            // For features that grant access to multiple permissions, add those too
            if (feature === 'convert_to_excel' || feature === 'chat') {
              mappedPermissions.push(PERMISSIONS.VIEW_DASHBOARD);
            } else if (feature === 'fcl_search') {
              mappedPermissions.push(PERMISSIONS.VIEW_FCL_RATES);
            } else if (feature === 'hs_lookup') {
              mappedPermissions.push(PERMISSIONS.VIEW_HS_CODES);
            } else if (feature === 'doc_check') {
              mappedPermissions.push(PERMISSIONS.VIEW_REPORTS);
            }
          }
        });
        
        // Remove duplicates
        const uniquePermissions = [...new Set(mappedPermissions)];
        
        // If we have mapped permissions, return them
        if (uniquePermissions.length > 0) {
          console.log(`Mapped permissions for ${user.email}:`, uniquePermissions);
          return uniquePermissions;
        }
      }
      
      // If parsing succeeded but didn't result in usable permissions, fall back to role permissions
      return user.role ? (ROLE_PERMISSIONS[user.role] || []) : [];
    } catch (error) {
      console.error('Error parsing permissions:', error);
      // If parsing fails and user has a role, return default permissions for that role
      return user.role ? (ROLE_PERMISSIONS[user.role] || []) : [];
    }
  }

  // Handle array permissions
  if (Array.isArray(user.permissions)) {
    const mappedPermissions: Permission[] = [];
    
    // Map each feature to its corresponding permission
    user.permissions.forEach(feature => {
      // If the feature is already a Permission type, add it directly
      if (Object.values(PERMISSIONS).includes(feature as Permission)) {
        mappedPermissions.push(feature as Permission);
      } 
      // Otherwise, check if it's a feature name that maps to a permission
      else if (FEATURE_TO_PERMISSION_MAP[feature]) {
        mappedPermissions.push(FEATURE_TO_PERMISSION_MAP[feature]);
      }
    });
    
    // Remove duplicates
    const uniquePermissions = [...new Set(mappedPermissions)];
    
    // If we have mapped permissions, return them
    if (uniquePermissions.length > 0) {
      console.log(`Mapped permissions for ${user.email}:`, uniquePermissions);
      return uniquePermissions;
    }
    
    // If no mappable permissions, fall back to role permissions
    return user.role ? (ROLE_PERMISSIONS[user.role] || []) : [];
  }

  // If no permissions found but user has a role, return default permissions for that role
  return user.role ? (ROLE_PERMISSIONS[user.role] || []) : [];
}

export function hasPermission(user: User, permission: Permission): boolean {
  const permissions = getUserPermissions(user);
  return user.is_superadmin || permissions.includes(permission);
}

export function hasAnyPermission(
  user: User,
  permissions: Permission[]
): boolean {
  const userPermissions = getUserPermissions(user);
  return (
    user.is_superadmin || permissions.some(p => userPermissions.includes(p))
  );
}

export function hasAllPermissions(
  user: User,
  permissions: Permission[]
): boolean {
  const userPermissions = getUserPermissions(user);
  return (
    user.is_superadmin || permissions.every(p => userPermissions.includes(p))
  );
}
