import { ConversionsList } from '@/components/conversion/ConversionsList';
import { DocusamExtraction } from '@/components/conversion/DocusamExtraction';
import { FileUploadArea } from '@/components/conversion/FileUploadArea';
import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { useInterval } from '@/hooks/useInterval';
import {
    downloadConversionResult,
    useDeleteConversion,
    useRecentConversions,
    useUploadForConversion,
} from '@/lib/api/services/conversion';
import { ExtractionJob } from '@/lib/api/types/table-extraction';
import { isValidFileType } from '@/utils/conversion';
import { Loader2, Upload } from 'lucide-react';
import { useState } from 'react';

// Conversion states
type ConversionState = 'pending' | 'processing' | 'completed' | 'failed';

const ITEMS_PER_PAGE = 15;

export default function Convert() {
  const { toast } = useToast();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeletingId, setIsDeletingId] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const uploadMutation = useUploadForConversion();
  const deleteMutation = useDeleteConversion();
  const {
    data: recentConversions,
    isLoading: isRecentLoading,
    refetch: refreshConversions,
  } = useRecentConversions(ITEMS_PER_PAGE, (currentPage - 1) * ITEMS_PER_PAGE);

  // Poll for updates with a reasonable interval while there are pending or processing conversions
  // Use a longer interval (10 seconds) to reduce server load and prevent excessive refreshing
  useInterval(() => {
    if (refreshConversions) {
      // Ensure jobs array exists and is properly filtered for null values
      const hasActiveJobs = recentConversions?.jobs?.some(
        (job: ExtractionJob) => 
          job && (job.status === 'pending' || job.status === 'processing')
      );
      
      // Only refresh if there are active jobs
      if (hasActiveJobs) {
        console.log('Refreshing conversions due to active jobs');
        refreshConversions();
      }
    }
  }, 10000); // 10 seconds instead of 5 seconds

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFileSelect = (files: File[]) => {
    const validFiles = files.filter(file => {
      if (!isValidFileType(file.type)) {
        toast({
          title: 'Invalid file type',
          description: `${file.name}: Please upload only PNG, JPEG, HEIC, TIFF, or PDF files`,
          variant: 'destructive',
        });
        return false;
      }
      return true;
    });
    setSelectedFiles(validFiles);
  };

  const handleUpload = async () => {
    if (selectedFiles.length === 0) {
      toast({
        title: 'Missing files',
        description: 'Please select files to convert',
        variant: 'destructive',
      });
      return;
    }

    setIsUploading(true);
    try {
      const response = await uploadMutation.mutateAsync({
        files: selectedFiles,
      });

      if (response.success) {
        toast({
          title: 'Upload successful',
          description:
            response.message || 'Your files have been queued for processing',
        });
        setSelectedFiles([]);
        setCurrentPage(1); // Reset to first page after upload
        refreshConversions();
      } else {
        throw new Error(response.error || 'Failed to upload files');
      }
    } catch (error) {
      toast({
        title: 'Upload failed',
        description:
          error instanceof Error ? error.message : 'Failed to upload files',
        variant: 'destructive',
      });
    } finally {
      setIsUploading(false);
    }
  };

  const handleDownload = async (jobId: string) => {
    setIsDownloading(true);
    try {
      await downloadConversionResult(jobId);
      toast({
        title: 'Download started',
        description: 'Your file will be downloaded shortly',
      });
    } catch (error) {
      // Extract the error message
      const errorMessage = error instanceof Error ? error.message : 'Failed to download file';
      
      // Check for specific error messages related to missing output files
      if (errorMessage.includes('output file is not available') || 
          errorMessage.includes('output file is missing') ||
          errorMessage.includes('Excel file needs to be regenerated') ||
          errorMessage.includes('Please try re-uploading the file')) {
        toast({
          title: 'Download failed',
          description: errorMessage,
          variant: 'destructive',
          // Increase duration for these specific errors to give users more time to read
          duration: 15000, // 15 seconds
        });
      } else {
        toast({
          title: 'Download failed',
          description: errorMessage,
          variant: 'destructive',
          duration: 5000, // 5 seconds
        });
      }
      
      // Log the error for debugging
      console.error('Download error:', error);
    } finally {
      setIsDownloading(false);
    }
  };

  const handleDelete = async (jobId: string) => {
    setIsDeletingId(jobId);
    try {
      await deleteMutation.mutateAsync(jobId);
      toast({
        title: 'Conversion deleted',
        description: 'The conversion has been removed',
      });
      refreshConversions();
    } catch (error) {
      toast({
        title: 'Delete failed',
        description:
          error instanceof Error
            ? error.message
            : 'Failed to delete conversion',
        variant: 'destructive',
      });
    } finally {
      setIsDeletingId(null);
    }
  };

  const getConversionStatus = (job: ExtractionJob) => {
    if (!job) return 'Unknown';
    
    // Safety check for null or undefined status
    const status = job.status || 'unknown';
    
    if (status === 'completed') {
      if (job.errorMessage) {
        job.error = job.errorMessage;
      }
      return 'Completed';
    }
    if (status === 'failed') return 'Failed';
    if (status === 'processing') return 'Processing';
    if (status === 'pending') return 'Pending';
    return job.displayStatus || 'Unknown';
  };

  return (
    <div className="container mx-auto p-4 space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>Convert Documents</CardTitle>
          <CardDescription>
            Upload documents to convert them to Excel format
          </CardDescription>
        </CardHeader>
        <CardContent>
          <FileUploadArea
            selectedFiles={selectedFiles}
            isUploading={isUploading}
            onFilesSelected={handleFileSelect}
          />

          <Button
            onClick={handleUpload}
            disabled={selectedFiles.length === 0 || isUploading}
            className="w-full mt-4"
          >
            {isUploading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Uploading...
              </>
            ) : (
              <>
                <Upload className="mr-2 h-4 w-4" />
                Upload and Queue{' '}
                {selectedFiles.length > 0 &&
                  `(${selectedFiles.length} file${
                    selectedFiles.length !== 1 ? 's' : ''
                  })`}
              </>
            )}
          </Button>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Recent Conversions</CardTitle>
        </CardHeader>
        <CardContent>
          <ConversionsList
            jobs={
              recentConversions?.jobs?.map((job: ExtractionJob) => {
                if (!job) return null;
                return {
                  ...job,
                  id: job.id ? job.id.toString() : 'unknown',
                  userId: job.userId || (job.user_id ? job.user_id.toString() : 'unknown'),
                  displayStatus: getConversionStatus(job),
                  canDownload: job.status === 'completed',
                  isProcessing: job.status === 'processing',
                  isPending: job.status === 'pending',
                };
              }).filter(Boolean) || []
            }
            isLoading={isRecentLoading}
            isDownloading={isDownloading}
            isDeletingId={isDeletingId}
            onDownload={handleDownload}
            onDelete={handleDelete}
            currentPage={currentPage}
            totalItems={recentConversions?.total || 0}
            itemsPerPage={ITEMS_PER_PAGE}
            onPageChange={handlePageChange}
          />
        </CardContent>
      </Card>

      {/* Add Docusam Table Extraction */}
      <DocusamExtraction />
    </div>
  );
}
