import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { fetchApi, uploadFile, downloadFile } from '../utils/fetch';
import {
  ConversionJob,
  ConversionListResponse,
  ConversionUploadResponse,
  ConversionUploadParams,
} from '../types/conversion';
import { getApiUrl } from '../../config';
import { getAuthToken } from '../../auth-store';

export function useUploadForConversion() {
  const queryClient = useQueryClient();
  const token = getAuthToken();

  return useMutation({
    mutationFn: async ({ files }: ConversionUploadParams) => {
      if (!token) {
        throw new Error('Not authenticated');
      }

      const formData = new FormData();
      files.forEach(file => {
        formData.append('file', file);
      });

      const response = await fetch(`${getApiUrl()}/convert/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to upload file');
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['conversions'] });
    },
  });
}

export function useRecentConversions(limit = 10, offset = 0) {
  const queryClient = useQueryClient();
  const token = getAuthToken();

  return useQuery({
    queryKey: ['conversions', limit, offset],
    queryFn: async () => {
      if (!token) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(
        `${getApiUrl()}/convert/list?limit=${limit}&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to fetch conversions');
      }

      const data = await response.json();
      return {
        ...data,
        jobs: data.jobs.map((job: ConversionJob) => {
          // Make sure id and user_id are strings
          if (!job) return null;
          
          return {
            ...job,
            id: job.id ? job.id.toString() : 'unknown',
            user_id: job.user_id ? job.user_id.toString() : 'unknown',
            status: job.status || 'pending',
            downloadUrl:
              job.status === 'completed'
                ? `${getApiUrl()}/convert/${job.id}/download`
                : null,
          };
        }).filter(Boolean),
      };
    },
    refetchInterval: (data: ConversionListResponse | undefined) => {
      const hasActiveJobs = data?.jobs.some(
        (job: ConversionJob) =>
          job.status === 'pending' || job.status === 'processing'
      );
      return hasActiveJobs ? 2000 : false;
    },
    refetchOnWindowFocus: true,
    staleTime: 0,
    onSuccess: (data: ConversionListResponse) => {
      data.jobs.forEach((job: ConversionJob) => {
        queryClient.setQueryData(['conversion', job.id], job);
      });

      const oldData = queryClient.getQueryData([
        'conversions',
        limit,
        offset,
      ]) as ConversionListResponse;

      if (oldData?.jobs) {
        const hasStatusChanged = data.jobs.some((newJob: ConversionJob) => {
          const oldJob = oldData.jobs.find(j => j.id === newJob.id);
          return oldJob && oldJob.status !== newJob.status;
        });
        if (hasStatusChanged) {
          queryClient.invalidateQueries({ queryKey: ['conversions'] });
        }
      }
    },
  });
}

export function useDeleteConversion() {
  const queryClient = useQueryClient();
  const token = getAuthToken();

  return useMutation({
    mutationFn: async (jobId: string) => {
      if (!token) {
        throw new Error('Not authenticated');
      }

      const response = await fetch(`${getApiUrl()}/convert/${jobId}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to delete conversion');
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['conversions'] });
    },
  });
}

export async function downloadConversionResult(jobId: string): Promise<void> {
  const token = getAuthToken();
  if (!token) {
    throw new Error('Not authenticated');
  }

  try {
    console.log('Starting download for job:', jobId);

    const response = await fetch(`${getApiUrl()}/convert/${jobId}/download`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to download conversion');
    }

    const blob = await response.blob();
    const contentDisposition = response.headers.get('Content-Disposition');
    let filename = 'downloaded-file';

    if (contentDisposition) {
      const filenameMatch = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(
        contentDisposition
      );
      if (filenameMatch && filenameMatch[1]) {
        filename = filenameMatch[1].replace(/['"]/g, '');
      }
    }

    // Create object URL
    const url = window.URL.createObjectURL(blob);
    console.log('Created object URL:', url);

    // Create and click download link
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.style.display = 'none';
    document.body.appendChild(a);

    // Trigger download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

    console.log('Download initiated successfully');
  } catch (error) {
    console.error('Download failed:', error);
    throw new Error(
      error instanceof Error
        ? `Download failed: ${error.message}`
        : 'Download failed: Unknown error'
    );
  }
}
