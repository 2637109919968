import { z } from 'zod';
import { Permission } from '@/lib/permissions';

export type UserRole = 'admin' | 'user';

export const USER_ROLES: UserRole[] = ['admin', 'user'];

export interface User {
  id: string;
  username: string;
  email: string;
  name: string;
  role: string;
  permissions: string[];
  is_superadmin: boolean;
  tenant_id?: string;
  last_login?: string;
  created_at: string;
  updated_at: string;
}

export interface UserFormData {
  username: string;
  email: string;
  password: string;
  name: string;
  role: UserRole;
  permissions: Permission[];
}

export interface UserUpdateData {
  id: string;
  username?: string;
  email?: string;
  name?: string;
  role?: UserRole;
  permissions: Permission[];
  is_superadmin?: boolean;
}

export interface UsersResponse {
  users: User[];
}

export interface PasswordUpdateData {
  id: string;
  password: string;
}

export interface ErrorResponse {
  details: string;
}

export interface CreateUserInput {
  username: string;
  email: string;
  password: string;
  name: string;
  role: string;
  permissions?: string[];
  is_superadmin?: boolean;
  tenant_id?: string;
}

export interface UpdateUserInput {
  username?: string;
  email?: string;
  password?: string;
  name?: string;
  role?: string;
  permissions?: string[];
  is_superadmin?: boolean;
  tenant_id?: string;
}

// Role-based access control types
export type Permission =
  | 'users:read'
  | 'users:write'
  | 'users:delete'
  | 'shipments:read'
  | 'shipments:write'
  | 'shipments:delete'
  | 'documents:read'
  | 'documents:write'
  | 'documents:delete'
  | 'rates:read'
  | 'rates:write'
  | 'rates:delete'
  | 'settings:read'
  | 'settings:write'
  | 'settings:delete';

export type Role = 'admin' | 'user' | 'manager' | 'viewer';

// API response types
export interface UserResponse {
  user: User;
}

export interface UserError {
  error: string;
  field?: string;
}
