import { getAuthToken } from './auth-store';
import { getApiUrl } from './config';

interface AuditLogEntry {
  action: string;
  target_type: string;
  target_id: string;
  details?: Record<string, any>;
  timestamp?: string;
}

/**
 * Logs user actions for audit purposes
 * @param logEntry The audit log entry to record
 */
export async function logUserAction(logEntry: AuditLogEntry): Promise<void> {
  try {
    // Add timestamp if not provided
    if (!logEntry.timestamp) {
      logEntry.timestamp = new Date().toISOString();
    }

    // Get auth token for the current user
    const token = getAuthToken();
    if (!token) {
      console.warn('Cannot log user action: No authentication token found');
      return;
    }

    // Log to console in development
    if (import.meta.env.DEV) {
      console.log('Audit Log:', logEntry);
    }

    // Send to server
    const response = await fetch(`${getApiUrl()}/audit-logs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(logEntry),
      credentials: 'include'
    });

    if (!response.ok) {
      console.error('Failed to log user action:', await response.text());
    }
  } catch (error) {
    // Don't let logging failures affect the application
    console.error('Error logging user action:', error);
  }
}

/**
 * Retrieves audit logs for a specific target
 * @param targetType The type of target (e.g., 'tenant', 'user')
 * @param targetId The ID of the target
 * @param limit Maximum number of logs to retrieve
 * @returns Array of audit log entries
 */
export async function getAuditLogs(
  targetType: string,
  targetId: string,
  limit: number = 50
): Promise<any[]> {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await fetch(
      `${getApiUrl()}/audit-logs?target_type=${encodeURIComponent(targetType)}&target_id=${encodeURIComponent(targetId)}&limit=${limit}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to retrieve audit logs: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error retrieving audit logs:', error);
    throw error;
  }
}

/**
 * Retrieves audit logs for the current user's actions
 * @param limit Maximum number of logs to retrieve
 * @returns Array of audit log entries
 */
export async function getUserActivityLogs(limit: number = 50): Promise<any[]> {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await fetch(
      `${getApiUrl()}/audit-logs/user-activity?limit=${limit}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to retrieve user activity logs: ${response.status} ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error retrieving user activity logs:', error);
    throw error;
  }
}

/**
 * Exports audit logs for a specific target as CSV
 * @param targetType The type of target (e.g., 'tenant', 'user')
 * @param targetId The ID of the target
 * @returns Blob containing CSV data
 */
export async function exportAuditLogs(
  targetType: string,
  targetId: string
): Promise<Blob> {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error('No authentication token found');
    }

    const response = await fetch(
      `${getApiUrl()}/audit-logs/export?target_type=${encodeURIComponent(targetType)}&target_id=${encodeURIComponent(targetId)}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        credentials: 'include'
      }
    );

    if (!response.ok) {
      throw new Error(`Failed to export audit logs: ${response.status} ${response.statusText}`);
    }

    return await response.blob();
  } catch (error) {
    console.error('Error exporting audit logs:', error);
    throw error;
  }
} 