import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from '@/components/ui/table';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useAuth } from '@/hooks/useAuth';
import { logUserAction } from '@/lib/audit-logger';
import { hasPermission } from '@/lib/permissions';
import {
    AlertCircle,
    ArrowUpDown,
    ChevronLeft,
    ChevronRight,
    Edit,
    MoreHorizontal,
    Power,
    Search,
    ShieldAlert,
    Trash2,
    Users
} from 'lucide-react';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Tenant {
  id: string;
  name: string;
  status: 'active' | 'disabled';
  email: string;
  contactName: string;
  phone: string;
  maxUsers: number;
  currentUsers: number;
  domain: string;
  isPrimary: boolean;
  domainStatus: 'active' | 'inactive';
}

interface TenantsTableProps {
  tenants: Tenant[];
  onDeleteTenant: (id: string) => Promise<boolean>;
  onUpdateTenant: (id: string, data: Partial<Tenant>) => Promise<boolean>;
  onToggleStatus: (id: string) => Promise<boolean>;
  isLoading?: boolean;
}

type SortField = 'name' | 'status' | 'contactName' | 'domain' | 'currentUsers';
type SortDirection = 'asc' | 'desc';

export function TenantsTable({
  tenants,
  onDeleteTenant,
  onUpdateTenant,
  onToggleStatus,
  isLoading = false,
}: TenantsTableProps) {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const [actionLoading, setActionLoading] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState<string | null>(null);
  const [sortField, setSortField] = useState<SortField>('name');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [actionError, setActionError] = useState<string | null>(null);
  
  const itemsPerPage = 10;

  // Check permissions
  const canManageTenants = currentUser && (
    currentUser.role === 'admin' || 
    currentUser.role === 'superadmin' || 
    hasPermission(currentUser, 'tenant.manage')
  );
  
  const canViewTenantUsers = currentUser && (
    currentUser.role === 'admin' || 
    currentUser.role === 'superadmin' || 
    hasPermission(currentUser, 'tenant.view_users')
  );

  // Handle sorting
  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
    
    // Log sort action
    logUserAction({
      action: 'sort_tenants',
      target_type: 'tenant_list',
      target_id: 'all',
      details: { field, direction: sortDirection === 'asc' ? 'desc' : 'asc' }
    });
  };

  // Filter and sort tenants
  const filteredAndSortedTenants = useMemo(() => {
    return tenants
      .filter(tenant => 
        tenant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant.contactName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        tenant.domain.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => {
        let comparison = 0;
        
        switch (sortField) {
          case 'name':
            comparison = a.name.localeCompare(b.name);
            break;
          case 'status':
            comparison = a.status.localeCompare(b.status);
            break;
          case 'contactName':
            comparison = a.contactName.localeCompare(b.contactName);
            break;
          case 'domain':
            comparison = a.domain.localeCompare(b.domain);
            break;
          case 'currentUsers':
            comparison = a.currentUsers - b.currentUsers;
            break;
        }
        
        return sortDirection === 'asc' ? comparison : -comparison;
      });
  }, [tenants, searchTerm, sortField, sortDirection]);

  // Paginate tenants
  const paginatedTenants = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return filteredAndSortedTenants.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredAndSortedTenants, currentPage]);

  const totalPages = Math.ceil(filteredAndSortedTenants.length / itemsPerPage);

  const handleAction = async (
    id: string,
    action: 'delete' | 'toggle' | 'edit' | 'users'
  ) => {
    // Check permissions
    if (!canManageTenants && (action === 'delete' || action === 'toggle' || action === 'edit')) {
      setActionError('You do not have permission to perform this action');
      return;
    }
    
    if (!canViewTenantUsers && action === 'users') {
      setActionError('You do not have permission to view tenant users');
      return;
    }
    
    if (action === 'delete') {
      setTenantToDelete(id);
      setDeleteConfirmOpen(true);
      
      // Log delete attempt
      logUserAction({
        action: 'attempt_delete_tenant',
        target_type: 'tenant',
        target_id: id,
        details: { status: 'confirmation_requested' }
      });
      return;
    }

    setActionLoading(id);
    setActionError(null);
    
    try {
      switch (action) {
        case 'toggle':
          // Log toggle attempt
          logUserAction({
            action: 'toggle_tenant_status',
            target_type: 'tenant',
            target_id: id,
            details: { 
              previous_status: tenants.find(t => t.id === id)?.status 
            }
          });
          
          await onToggleStatus(id);
          break;
        case 'edit':
          // Log edit attempt
          logUserAction({
            action: 'edit_tenant',
            target_type: 'tenant',
            target_id: id,
            details: { }
          });
          
          navigate(`/tenants/${id}/edit`);
          break;
        case 'users':
          // Log users view attempt
          logUserAction({
            action: 'view_tenant_users',
            target_type: 'tenant',
            target_id: id,
            details: { }
          });
          
          navigate(`/tenants/${id}/users`);
          break;
      }
    } catch (error) {
      console.error(`Error performing ${action} action:`, error);
      setActionError(error instanceof Error ? error.message : `Failed to perform ${action} action`);
      
      // Log error
      logUserAction({
        action: `${action}_tenant_error`,
        target_type: 'tenant',
        target_id: id,
        details: { 
          error: error instanceof Error ? error.message : 'Unknown error'
        }
      });
    } finally {
      setActionLoading(null);
    }
  };

  const confirmDelete = async () => {
    if (!tenantToDelete) return;
    
    if (!canManageTenants) {
      setActionError('You do not have permission to delete tenants');
      setDeleteConfirmOpen(false);
      return;
    }
    
    setActionLoading(tenantToDelete);
    setActionError(null);
    
    try {
      // Log delete confirmation
      logUserAction({
        action: 'delete_tenant',
        target_type: 'tenant',
        target_id: tenantToDelete,
        details: { status: 'deletion_started' }
      });
      
      const success = await onDeleteTenant(tenantToDelete);
      
      if (success) {
        // Log successful deletion
        logUserAction({
          action: 'delete_tenant_success',
          target_type: 'tenant',
          target_id: tenantToDelete,
          details: { }
        });
      }
    } catch (error) {
      console.error('Error deleting tenant:', error);
      setActionError(error instanceof Error ? error.message : 'Failed to delete tenant');
      
      // Log error
      logUserAction({
        action: 'delete_tenant_error',
        target_type: 'tenant',
        target_id: tenantToDelete,
        details: { 
          error: error instanceof Error ? error.message : 'Unknown error'
        }
      });
    } finally {
      setActionLoading(null);
      setDeleteConfirmOpen(false);
      setTenantToDelete(null);
    }
  };

  const handleSearch = (value: string) => {
    setSearchTerm(value);
    setCurrentPage(1); // Reset to first page on search
    
    // Log search action if value is not empty
    if (value.trim()) {
      logUserAction({
        action: 'search_tenants',
        target_type: 'tenant_list',
        target_id: 'all',
        details: { search_term: value }
      });
    }
  };

  const SortableHeader = ({ field, children }: { field: SortField, children: React.ReactNode }) => (
    <TableHead className="cursor-pointer" onClick={() => handleSort(field)}>
      <div className="flex items-center space-x-1">
        <span>{children}</span>
        <ArrowUpDown className="h-4 w-4" />
        {sortField === field && (
          <span className="text-xs ml-1">
            {sortDirection === 'asc' ? '↑' : '↓'}
          </span>
        )}
      </div>
    </TableHead>
  );

  if (isLoading) {
    return (
      <div className="space-y-4">
        <div className="flex justify-between">
          <Skeleton className="h-10 w-[250px]" />
          <Skeleton className="h-10 w-[100px]" />
        </div>
        <div className="space-y-2">
          {Array(5).fill(0).map((_, i) => (
            <Skeleton key={i} className="h-16 w-full" />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <div className="relative w-full max-w-sm">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search enterprises..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            className="pl-8"
          />
        </div>
        <div className="text-sm text-muted-foreground">
          {filteredAndSortedTenants.length} {filteredAndSortedTenants.length === 1 ? 'enterprise' : 'enterprises'}
        </div>
      </div>

      {actionError && (
        <div className="bg-destructive/15 text-destructive px-4 py-2 rounded-md flex items-center space-x-2">
          <AlertCircle className="h-4 w-4" />
          <span>{actionError}</span>
          <Button 
            variant="ghost" 
            size="sm" 
            className="ml-auto h-6 px-2 text-destructive hover:text-destructive hover:bg-destructive/10"
            onClick={() => setActionError(null)}
          >
            Dismiss
          </Button>
        </div>
      )}

      {!canManageTenants && (
        <div className="bg-amber-500/15 text-amber-700 dark:text-amber-400 px-4 py-2 rounded-md flex items-center space-x-2">
          <ShieldAlert className="h-4 w-4" />
          <span>You have limited permissions for tenant management. Some actions may be restricted.</span>
        </div>
      )}

      {filteredAndSortedTenants.length === 0 ? (
        <div className="flex flex-col items-center justify-center py-8 text-center">
          <AlertCircle className="h-12 w-12 text-muted-foreground mb-4" />
          <h3 className="text-lg font-medium">No enterprises found</h3>
          <p className="text-sm text-muted-foreground mt-2">
            {tenants.length === 0
              ? "No enterprises have been added yet."
              : "No enterprises match your search criteria."}
          </p>
        </div>
      ) : (
        <>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                <TableRow>
                  <SortableHeader field="name">Enterprise Name</SortableHeader>
                  <SortableHeader field="status">Status</SortableHeader>
                  <SortableHeader field="contactName">Contact</SortableHeader>
                  <SortableHeader field="domain">Domain</SortableHeader>
                  <SortableHeader field="currentUsers">Users</SortableHeader>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {paginatedTenants.map(tenant => (
                  <TableRow key={tenant.id}>
                    <TableCell className="font-medium">{tenant.name}</TableCell>
                    <TableCell>
                      <Badge
                        variant={tenant.status === 'active' ? 'default' : 'destructive'}
                      >
                        {tenant.status}
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <div className="space-y-1">
                        <div>{tenant.contactName}</div>
                        <div className="text-sm text-muted-foreground">
                          {tenant.email}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="space-y-1">
                        <div>{tenant.domain}</div>
                        <Badge
                          variant={
                            tenant.domainStatus === 'active' ? 'outline' : 'secondary'
                          }
                        >
                          {tenant.isPrimary ? 'Primary' : 'Secondary'} •{' '}
                          {tenant.domainStatus}
                        </Badge>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="space-y-1">
                        <div className="flex items-center">
                          <span className="font-medium">{tenant.currentUsers}</span>
                          <span className="mx-1">/</span>
                          <span>{tenant.maxUsers}</span>
                        </div>
                        <div className="w-full bg-secondary h-2 rounded-full overflow-hidden">
                          <div 
                            className={`h-full rounded-full ${
                              tenant.currentUsers / tenant.maxUsers > 0.9 
                                ? 'bg-amber-500' 
                                : tenant.currentUsers / tenant.maxUsers > 0.75 
                                  ? 'bg-amber-400' 
                                  : 'bg-primary'
                            }`}
                            style={{ 
                              width: `${Math.min(100, (tenant.currentUsers / tenant.maxUsers) * 100)}%` 
                            }}
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell className="text-right">
                      <div className="flex justify-end space-x-2">
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleAction(tenant.id, 'users')}
                                disabled={actionLoading === tenant.id || !canViewTenantUsers}
                                className={!canViewTenantUsers ? 'opacity-50 cursor-not-allowed' : ''}
                              >
                                <Users className="h-4 w-4" />
                                <span className="sr-only">View Users</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{canViewTenantUsers ? 'View Users' : 'No permission to view users'}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleAction(tenant.id, 'edit')}
                                disabled={actionLoading === tenant.id || !canManageTenants}
                                className={!canManageTenants ? 'opacity-50 cursor-not-allowed' : ''}
                              >
                                <Edit className="h-4 w-4" />
                                <span className="sr-only">Edit</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{canManageTenants ? 'Edit Enterprise' : 'No permission to edit'}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                onClick={() => handleAction(tenant.id, 'toggle')}
                                disabled={actionLoading === tenant.id || !canManageTenants}
                                className={!canManageTenants ? 'opacity-50 cursor-not-allowed' : ''}
                              >
                                <Power className={`h-4 w-4 ${tenant.status === 'active' ? 'text-green-500' : 'text-red-500'}`} />
                                <span className="sr-only">
                                  {tenant.status === 'active' ? 'Disable' : 'Enable'}
                                </span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>
                                {canManageTenants 
                                  ? `${tenant.status === 'active' ? 'Disable' : 'Enable'} Enterprise` 
                                  : 'No permission to change status'}
                              </p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <Button
                                variant="ghost"
                                size="icon"
                                className="text-red-500 hover:text-red-600 hover:bg-red-50"
                                onClick={() => handleAction(tenant.id, 'delete')}
                                disabled={actionLoading === tenant.id || !canManageTenants}
                              >
                                <Trash2 className="h-4 w-4" />
                                <span className="sr-only">Delete</span>
                              </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                              <p>{canManageTenants ? 'Delete Enterprise' : 'No permission to delete'}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>

                        <DropdownMenu>
                          <DropdownMenuTrigger asChild>
                            <Button
                              variant="ghost"
                              className="h-8 w-8 p-0 lg:hidden"
                              disabled={actionLoading === tenant.id}
                            >
                              <span className="sr-only">Open menu</span>
                              <MoreHorizontal className="h-4 w-4" />
                            </Button>
                          </DropdownMenuTrigger>
                          <DropdownMenuContent align="end">
                            {canViewTenantUsers && (
                              <DropdownMenuItem
                                onClick={() => handleAction(tenant.id, 'users')}
                                disabled={actionLoading === tenant.id}
                              >
                                <Users className="mr-2 h-4 w-4" />
                                View Users
                              </DropdownMenuItem>
                            )}
                            
                            {canManageTenants && (
                              <>
                                <DropdownMenuItem
                                  onClick={() => handleAction(tenant.id, 'edit')}
                                  disabled={actionLoading === tenant.id}
                                >
                                  <Edit className="mr-2 h-4 w-4" />
                                  Edit
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  onClick={() => handleAction(tenant.id, 'toggle')}
                                  disabled={actionLoading === tenant.id}
                                >
                                  <Power className="mr-2 h-4 w-4" />
                                  {tenant.status === 'active' ? 'Disable' : 'Enable'}
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  className="text-red-600"
                                  onClick={() => handleAction(tenant.id, 'delete')}
                                  disabled={actionLoading === tenant.id}
                                >
                                  <Trash2 className="mr-2 h-4 w-4" />
                                  Delete
                                </DropdownMenuItem>
                              </>
                            )}
                            
                            {!canManageTenants && !canViewTenantUsers && (
                              <DropdownMenuItem disabled>
                                <ShieldAlert className="mr-2 h-4 w-4" />
                                No permissions
                              </DropdownMenuItem>
                            )}
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>

          {/* Pagination */}
          {totalPages > 1 && (
            <div className="flex items-center justify-between">
              <div className="text-sm text-muted-foreground">
                Showing {(currentPage - 1) * itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, filteredAndSortedTenants.length)} of {filteredAndSortedTenants.length}
              </div>
              <div className="flex items-center space-x-2">
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setCurrentPage(prev => Math.max(1, prev - 1));
                    logUserAction({
                      action: 'pagination',
                      target_type: 'tenant_list',
                      target_id: 'all',
                      details: { direction: 'previous', new_page: Math.max(1, currentPage - 1) }
                    });
                  }}
                  disabled={currentPage === 1}
                >
                  <ChevronLeft className="h-4 w-4" />
                  <span className="sr-only">Previous Page</span>
                </Button>
                <div className="text-sm">
                  Page {currentPage} of {totalPages}
                </div>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setCurrentPage(prev => Math.min(totalPages, prev + 1));
                    logUserAction({
                      action: 'pagination',
                      target_type: 'tenant_list',
                      target_id: 'all',
                      details: { direction: 'next', new_page: Math.min(totalPages, currentPage + 1) }
                    });
                  }}
                  disabled={currentPage === totalPages}
                >
                  <ChevronRight className="h-4 w-4" />
                  <span className="sr-only">Next Page</span>
                </Button>
              </div>
            </div>
          )}
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete the enterprise
              and all associated data including users, domains, and settings.
              
              {tenantToDelete && (
                <div className="mt-2 p-2 bg-destructive/10 rounded-md">
                  <strong>Enterprise:</strong> {tenants.find(t => t.id === tenantToDelete)?.name}
                  <br />
                  <strong>Users:</strong> {tenants.find(t => t.id === tenantToDelete)?.currentUsers}
                  <br />
                  <strong>Domain:</strong> {tenants.find(t => t.id === tenantToDelete)?.domain}
                </div>
              )}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={confirmDelete}
              className="bg-red-600 hover:bg-red-700"
              disabled={actionLoading === tenantToDelete}
            >
              {actionLoading === tenantToDelete ? 'Deleting...' : 'Delete Enterprise'}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
