import { useState } from 'react';

/**
 * Custom hook for managing dialog state
 */
export const useDialogState = <T>(initialValue: T | null = null) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<T | null>(initialValue);

  const open = (data: T) => {
    setData(data);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    data,
    open,
    close,
    setIsOpen,
  };
}; 