import { useToast } from '@/components/ui/use-toast';
import { apiClient } from '@/lib/api-client';
import { CascadeState, HSResult } from '@/lib/api/types/hs-lookup';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import type { FormSchema } from '../schemas/form';
import { formSchema } from '../schemas/form';

export function useHSLookup() {
  const { toast } = useToast();
  const [result, setResult] = useState<HSResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [lookupId, setLookupId] = useState<string | null>(null);
  const [hsDigits, setHsDigits] = useState<string>('8');
  const [productType, setProductType] = useState<string>('');
  const [customProductType, setCustomProductType] = useState<string>('');
  const [cascadeState, setCascadeState] = useState<CascadeState>({
    stage: 'initial',
    progress: 0,
  });
  
  // Enhanced loading state with more detailed progress tracking
  const [processingStage, setProcessingStage] = useState<{
    stage: 'initial' | 'analyzing' | 'classifying' | 'validating' | 'complete';
    message: string;
  }>({
    stage: 'initial',
    message: 'Preparing request...',
  });

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      product_overview: '',
      origin_country: '',
      target_country: '',
      hs_digits: '8',
    },
  });

  // Define the simulateProgressiveLoading function outside the onSubmit function
  // so it can be reused in the catch block
  const simulateProgressiveLoading = () => {
    // Initial stage - 0-20%
    setCascadeState({
      stage: 'initial',
      progress: 5,
    });
    setProcessingStage({
      stage: 'initial',
      message: 'Analyzing product description...',
    });
    
    // Simulate progress updates
    const stages = [
      { 
        delay: 500, 
        state: { stage: 'initial' as const, progress: 15 },
        message: { stage: 'analyzing', message: 'Extracting key product attributes...' }
      },
      { 
        delay: 1000, 
        state: { stage: 'detailed' as const, progress: 30 },
        message: { stage: 'analyzing', message: 'Determining product classification criteria...' }
      },
      { 
        delay: 2000, 
        state: { stage: 'detailed' as const, progress: 45 },
        message: { stage: 'classifying', message: 'Searching HS classification database...' }
      },
      { 
        delay: 3000, 
        state: { stage: 'detailed' as const, progress: 60 },
        message: { stage: 'classifying', message: 'Evaluating potential HS codes...' }
      },
      { 
        delay: 4000, 
        state: { stage: 'validation' as const, progress: 75 },
        message: { stage: 'validating', message: 'Validating classification against trade rules...' }
      },
      { 
        delay: 5000, 
        state: { stage: 'validation' as const, progress: 90 },
        message: { stage: 'validating', message: 'Finalizing classification details...' }
      }
    ];
    
    // Schedule the progress updates
    const timeouts: NodeJS.Timeout[] = [];
    
    stages.forEach(({ delay, state, message }) => {
      const timeout = setTimeout(() => {
        setCascadeState(state);
        setProcessingStage({
          stage: message.stage as any,
          message: message.message
        });
      }, delay);
      timeouts.push(timeout);
    });
    
    return timeouts;
  };

  const onSubmit = async (values: FormSchema) => {
    try {
      setLoading(true);
      setError(null);
      
      // Start the cascade animation
      setCascadeState({
        stage: 'initial',
        progress: 0,
      });
      
      // Set initial processing stage
      setProcessingStage({
        stage: 'initial',
        message: 'Preparing request...',
      });
      
      // Start the simulated progress
      const progressTimeouts = simulateProgressiveLoading();
      
      // Create a placeholder result to show immediately
      const placeholderResult: HSResult = {
        code: '--------',
        description: 'Analyzing your product...',
        confidence: 0,
        reasoning: 'We are currently analyzing your product description to determine the most accurate HS code.',
        status: 'processing',
        classification_notes: {
          product_type: 'Processing...',
          manufacture_stage: 'Processing...',
          key_materials: 'Processing...'
        },
        trade_considerations: {
          duty_rates: 'Processing...',
          restrictions: 'Processing...',
          special_requirements: 'Processing...',
          trade_agreements: 'Processing...',
          warnings: 'Processing...',
          labeling_requirements: 'Processing...',
          packaging_regulations: 'Processing...',
          rebates: 'Processing...',
          excise_tax: 'Processing...',
          import_permits: 'Processing...',
          export_permits: 'Processing...'
        },
        hierarchy: {
          chapter: { code: '--', description: 'Processing...' },
          heading: { code: '----', description: 'Processing...' }
        },
        alternatives: []
      };
      
      // Set the placeholder result immediately
      setResult(placeholderResult);
      
      // Make the actual API request
      const response = await apiClient.post<{ result: HSResult; lookup_id: string }>(
        '/hs-lookup/single',
        {
          product_overview: values.product_overview,
          origin_country: values.origin_country,
          target_country: values.target_country,
          hsDigits: parseInt(values.hs_digits || '8', 10),
          query: values.product_overview, // Include query parameter for compatibility
        }
      );
      
      // Clear the progress simulation timeouts
      progressTimeouts.forEach(timeout => clearTimeout(timeout));
      
      // Set the final cascade state
      setCascadeState({
        stage: 'complete',
        progress: 100,
      });
      
      // Set the final processing stage
      setProcessingStage({
        stage: 'complete',
        message: 'Classification complete!',
      });
      
      // Update with the actual result
      setResult(response.result);
      setLookupId(response.lookup_id);
      setHsDigits(values.hs_digits || '8');
      
    } catch (err: any) {
      // Clear the progress simulation timeouts
      const progressTimeouts = simulateProgressiveLoading();
      progressTimeouts.forEach(timeout => clearTimeout(timeout));
      
      // Reset cascade state
      setCascadeState({
        stage: 'initial',
        progress: 0,
      });
      
      // Handle error
      console.error('Error in HS lookup:', err);
      setError(err.message || 'An error occurred during HS lookup');
      toast({
        title: 'Error',
        description: err.message || 'An error occurred during HS lookup',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const refreshResult = async () => {
    if (!lookupId) {
      return;
    }

    try {
      setLoading(true);
      setError(null);

      // Start the cascade animation
      setCascadeState({
        stage: 'initial',
        progress: 0,
      });

      // Simulate progress updates
      const interval = setInterval(() => {
        setCascadeState(prev => {
          if (prev.progress >= 100) {
            clearInterval(interval);
            return prev;
          }

          let newStage = prev.stage;
          let newProgress = prev.progress + 5;

          if (newProgress > 30 && newProgress <= 70) {
            newStage = 'detailed';
          } else if (newProgress > 70 && newProgress < 100) {
            newStage = 'validation';
          } else if (newProgress >= 100) {
            newStage = 'complete';
          }

          return {
            stage: newStage,
            progress: newProgress,
          };
        });
      }, 200);

      const response = await apiClient.get<{ result: HSResult }>(`/hs-lookup/${lookupId}`);

      clearInterval(interval);

      setCascadeState({
        stage: 'complete',
        progress: 100,
      });

      setResult(response.result);
    } catch (err: any) {
      console.error('Error refreshing HS lookup result:', err);
      setError(err.message || 'An error occurred while refreshing the result');
      toast({
        title: 'Error',
        description: err.message || 'An error occurred while refreshing the result',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const startPolling = async () => {
    if (!lookupId) {
      return;
    }

    let attempts = 0;
    const maxAttempts = 30; // 30 attempts * 2 seconds = 60 seconds max polling time
    const pollInterval = 2000; // 2 seconds

    const poll = async () => {
      if (attempts >= maxAttempts) {
        setError('Polling timed out after 60 seconds');
        return;
      }

      try {
        const response = await apiClient.get<{ result: HSResult }>(`/hs-lookup/${lookupId}`);
        
        if (response.result.status === 'completed') {
          setResult(response.result);
          setCascadeState({
            stage: 'complete',
            progress: 100,
          });
          return;
        }
        
        // If still processing, update the progress
        setCascadeState(prev => {
          const newProgress = Math.min(90, 30 + (attempts * 2)); // Cap at 90% until complete
          return {
            stage: newProgress > 70 ? 'validation' : 'detailed',
            progress: newProgress,
          };
        });
        
        // Continue polling
        attempts++;
        setTimeout(poll, pollInterval);
      } catch (err: any) {
        console.error('Error polling HS lookup result:', err);
        setError(err.message || 'An error occurred while polling for results');
      }
    };

    // Start polling
    poll();
  };

  const handleClear = () => {
    setResult(null);
    setError(null);
    setLookupId(null);
    setHsDigits('8');
    setProductType('');
    setCustomProductType('');
    setCascadeState({
      stage: 'initial',
      progress: 0,
    });
    setProcessingStage({
      stage: 'initial',
      message: 'Preparing request...',
    });
    form.reset({
      product_overview: '',
      origin_country: '',
      target_country: '',
      hs_digits: '8',
    });
  };

  const handleProductTypeChange = (value: string) => {
    setProductType(value);
    if (value !== 'custom') {
      form.setValue('product_overview', value);
    } else {
      form.setValue('product_overview', customProductType);
    }
  };

  const handleCustomProductTypeChange = (value: string) => {
    setCustomProductType(value);
    if (productType === 'custom') {
      form.setValue('product_overview', value);
    }
  };

  return {
    form,
    onSubmit,
    result,
    loading,
    error,
    lookupId,
    hsDigits,
    refreshResult,
    handleClear,
    cascadeState,
    processingStage,
    productType,
    handleProductTypeChange,
    customProductType,
    handleCustomProductTypeChange,
  };
}
