/**
 * DocusamExtraction Component
 * 
 * This component provides a UI for extracting tables from documents (PDF, images)
 * and converting them to Excel format. It includes file upload, preview generation,
 * custom column specification, and extraction management functionality.
 */
import { ExtractionsList } from '@/components/conversion/ExtractionsList';
import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Textarea } from '@/components/ui/textarea';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/contexts/AuthContext';
import {
    useTableExtractionState
} from '@/lib/api/services/table-extraction';
import { Loader2, RefreshCw } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FileUploadArea } from './FileUploadArea';
import { TablePreviewData, TablePreviewDialog } from './TablePreviewDialog';

/**
 * Constants for configuration
 */
const ITEMS_PER_PAGE = 15;
const MAX_RETRIES = 3; // Maximum number of retries for failed operations
const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size for large files
const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB max file size
const VALID_FILE_TYPES = [
  'image/png',
  'image/jpeg',
  'image/tiff',
  'application/pdf',
];

/**
 * Logging level configuration to control console output
 */
const LOG_LEVEL = {
  NONE: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4
};

// Set current log level - change to lower value to reduce console output
const CURRENT_LOG_LEVEL = LOG_LEVEL.WARN;

/**
 * Helper function for controlled logging
 * 
 * @param {number} level - The log level (ERROR, WARN, INFO, DEBUG)
 * @param {any[]} args - Arguments to log
 */
const log = (level: number, ...args: any[]) => {
  if (level <= CURRENT_LOG_LEVEL) {
    switch (level) {
      case LOG_LEVEL.ERROR:
        console.error(...args);
        break;
      case LOG_LEVEL.WARN:
        console.warn(...args);
        break;
      case LOG_LEVEL.INFO:
      case LOG_LEVEL.DEBUG:
      default:
        console.log(...args);
        break;
    }
  }
};

/**
 * DocusamExtraction Component
 * 
 * Main component for document table extraction functionality
 * 
 * @returns {JSX.Element} The DocusamExtraction component
 */
export function DocusamExtraction() {
  const { toast } = useToast();
  const { isAuthenticated } = useAuth();
  
  /**
   * File upload state
   */
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [customColumns, setCustomColumns] = useState('');
  const [generatePreview, setGeneratePreview] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDeletingId, setIsDeletingId] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isManualRefreshing, setIsManualRefreshing] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isChunkedUpload, setIsChunkedUpload] = useState(false);
  
  /**
   * Preview dialog state
   */
  const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
  const [previewJob, setPreviewJob] = useState<any | null>(null);
  const [previewData, setPreviewData] = useState<TablePreviewData | null>(null);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [selectedHeaders, setSelectedHeaders] = useState<string[]>([]);
  const [orderedHeaders, setOrderedHeaders] = useState<string[]>([]);
  const [currentUploadFile, setCurrentUploadFile] = useState<File | null>(null);
  
  /**
   * Reference to track whether we have an active polling interval
   * This helps avoid creating multiple intervals
   */
  const pollingIntervalRef = useRef<NodeJS.Timeout | undefined>(undefined);
  
  /**
   * Local state to track active jobs without triggering re-renders
   */
  const hasActiveJobsRef = useRef<boolean>(false);
  
  /**
   * Table extraction state hook
   * Provides API methods and state for managing extractions
   */
  const {
    jobs: extractionJobs,
    totalJobs,
    isLoading: isRecentLoading,
    error: extractionError,
    uploadFile,
    downloadExtraction,
    deleteExtraction,
    refresh: refreshExtractions,
    refreshSingleJob,
    getPreviewData
  } = useTableExtractionState();

  /**
   * Keep track of jobs that are currently in processing states
   * This lets us update them individually
   */
  const processingJobsRef = useRef<Set<string>>(new Set());

  /**
   * Force a refresh when the component mounts
   */
  useEffect(() => {
    log(LOG_LEVEL.INFO, 'Component mounted, forcing refresh');
    refreshExtractions();
    
    // Cleanup function to run when component unmounts
    return () => {
      // Clear any active polling interval
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
      }
    };
  }, [refreshExtractions]);
  
  /**
   * Check if there are any processing jobs in the current job list
   * This is extracted as a function to avoid code duplication
   * 
   * @param {any[]} jobs - The list of extraction jobs to check
   * @returns {boolean} True if any job is in a processing state
   */
  const checkForProcessingJobs = useCallback((jobs: any[]) => {
    // Clear the processing jobs set
    processingJobsRef.current.clear();
    
    // Check each job and track processing ones
    const hasProcessingJobs = jobs.some(job => {
      const status = job.status?.toLowerCase() || '';
      const isProcessing = status === 'pending' || 
                           status === 'processing' || 
                           status === 'queued' ||
                           status === 'uploading';
      
      // If the job is processing, add it to our tracked set
      if (isProcessing && job.id) {
        processingJobsRef.current.add(job.id);
      }
      
      return isProcessing;
    });
    
    return hasProcessingJobs;
  }, []);
  
  /**
   * Setup background polling for job status updates
   * Only runs server requests and doesn't trigger UI updates until complete
   */
  const setupBackgroundPolling = useCallback(() => {
    // If we already have a polling interval, don't create another one
    if (pollingIntervalRef.current) {
      return;
    }
    
    log(LOG_LEVEL.INFO, 'Setting up background polling for job status');
    
    // Create a polling interval that checks for updates without forcing re-renders
    pollingIntervalRef.current = setInterval(async () => {
      try {
        // Only refresh processing jobs individually to avoid full list refreshes
        if (processingJobsRef.current.size > 0) {
          log(LOG_LEVEL.INFO, `Refreshing ${processingJobsRef.current.size} processing jobs individually`);
          
          // Create a copy of the set to avoid issues if it's modified during iteration
          const processingJobs = Array.from(processingJobsRef.current);
          
          // Refresh each processing job individually
          for (const jobId of processingJobs) {
            const updatedJobs = await refreshSingleJob(jobId);
            const updatedJob = updatedJobs?.[0];
            
            // If job is no longer processing, remove it from our tracking
            if (updatedJob && 
               !['pending', 'processing', 'queued', 'uploading'].includes(updatedJob.status?.toLowerCase() || '')) {
              processingJobsRef.current.delete(jobId);
            }
          }
          
          // If no more processing jobs, stop polling
          if (processingJobsRef.current.size === 0) {
            log(LOG_LEVEL.INFO, 'No more active jobs, stopping background polling');
            if (pollingIntervalRef.current) {
              clearInterval(pollingIntervalRef.current);
              pollingIntervalRef.current = undefined;
            }
            
            // Do one final full refresh to ensure we have the latest state
            await refreshExtractions();
          }
        } else {
          // Silently fetch the latest job data if we don't have specific jobs to track
          const updatedJobs = await refreshExtractions();
          
          // Check if we have any active jobs
          hasActiveJobsRef.current = checkForProcessingJobs(updatedJobs || []);
          
          // If we no longer have any active jobs, stop polling
          if (!hasActiveJobsRef.current && pollingIntervalRef.current) {
            log(LOG_LEVEL.INFO, 'No more active jobs, stopping background polling');
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = undefined;
          }
        }
      } catch (error) {
        log(LOG_LEVEL.ERROR, 'Error in background polling:', error);
      }
    }, 10000); // Poll every 10 seconds
  }, [refreshExtractions, refreshSingleJob, checkForProcessingJobs]);
  
  /**
   * Monitor job status changes and setup background polling when needed
   * This effect runs when the list of jobs changes
   */
  useEffect(() => {
    // Check if we have any processing jobs
    const hasProcessingJobs = checkForProcessingJobs(extractionJobs);
    
    // Update our ref without triggering re-renders
    hasActiveJobsRef.current = hasProcessingJobs;
    
    // If we have processing jobs, ensure background polling is setup
    if (hasProcessingJobs) {
      log(LOG_LEVEL.INFO, 'Processing jobs detected, ensuring background polling is active');
      setupBackgroundPolling();
    }
    // We don't need an else clause to stop polling here, as the polling function
    // itself will stop when it detects no more active jobs
  }, [extractionJobs, checkForProcessingJobs, setupBackgroundPolling]);

  /**
   * Handle file selection from the FileUploadArea component
   * 
   * @param {File[]} files - The selected files
   */
  const handleFileSelect = useCallback((files: File[]) => {
    setSelectedFiles(files);
  }, []);

  /**
   * Generate a preview for a file before uploading
   * Opens the preview dialog and loads preview data
   * 
   * @param {File} file - The file to generate a preview for
   * @returns {Promise<null>} Null if preview generation is disabled
   */
  const handleGeneratePreview = useCallback(async (file: File) => {
    if (!generatePreview) return null;
    
    setIsLoadingPreview(true);
    setCurrentUploadFile(file);
    setPreviewDialogOpen(true);
    
    log(LOG_LEVEL.INFO, `Generating preview for: ${file.name}`);
    
    try {
      // Create a FormData object to send the file
      const formData = new FormData();
      formData.append('file', file);
      
      // Add custom columns if provided
      if (customColumns.trim()) {
        try {
          // Just send the comma-separated string directly
          formData.append('customColumns', customColumns.trim());
          log(LOG_LEVEL.INFO, `Added custom columns: ${customColumns.trim()}`);
        } catch (error) {
          log(LOG_LEVEL.ERROR, 'Error with custom columns:', error);
          toast({
            title: 'Invalid Custom Columns',
            description: 'Please provide a comma-separated list of column names.',
            variant: 'destructive',
          });
          setPreviewDialogOpen(false);
          setIsLoadingPreview(false);
          return;
        }
      }
      
      // Call the API to get preview data
      const previewResponse = await getPreviewData(formData);
      
      if (previewResponse && previewResponse.headers) {
        setPreviewData({
          headers: previewResponse.headers,
          previewUrl: previewResponse.previewUrl,
          previewHtml: previewResponse.previewHtml
        });
        
        // Set initial selected headers
        setSelectedHeaders(previewResponse.headers);
        setOrderedHeaders(previewResponse.headers);
      } else {
        toast({
          title: 'Preview generation failed',
          description: 'Could not generate preview for this file.',
          variant: 'destructive',
        });
        setPreviewDialogOpen(false);
      }
    } catch (error) {
      log(LOG_LEVEL.ERROR, 'Error generating preview:', error);
      toast({
        title: 'Preview generation failed',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        variant: 'destructive',
      });
      setPreviewDialogOpen(false);
    } finally {
      setIsLoadingPreview(false);
    }
  }, [customColumns, generatePreview, getPreviewData, toast]);

  /**
   * Upload a single file to the server
   * 
   * @param {File} file - The file to upload
   * @param {string[]} [headers] - Optional selected headers for the extraction
   * @param {string[]} [ordered] - Optional ordered headers for the extraction
   * @returns {Promise<boolean>} True if upload was successful, false otherwise
   */
  const handleUploadSingleFile = useCallback(async (file: File, headers?: string[], ordered?: string[]) => {
    log(LOG_LEVEL.INFO, `Uploading file: ${file.name}`);
    
    try {
      // Use custom columns if provided and no headers were selected
      const customColumnsToUse = !headers && customColumns.trim() ? customColumns.trim() : undefined;
      
      const extractionId = await uploadFile({
        file,
        customColumns: customColumnsToUse,
        generatePreview: false, // We already generated the preview
        selectedHeaders: headers,
        orderedHeaders: ordered
      });
      
      log(LOG_LEVEL.INFO, `Successfully uploaded: ${file.name} with ID: ${extractionId}`);
      
      // Ensure polling is active after file upload
      setupBackgroundPolling();
      
      return true;
    } catch (error) {
      log(LOG_LEVEL.ERROR, `Error uploading ${file.name}:`, error);
      toast({
        title: `Error uploading ${file.name}`,
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        variant: 'destructive',
      });
      return false;
    }
  }, [customColumns, uploadFile, toast, setupBackgroundPolling]);

  /**
   * Handle preview confirmation from the preview dialog
   * Uploads the file with the selected headers after preview confirmation
   * 
   * @param {string[]} selectedHeaders - The headers selected by the user
   * @param {string[]} orderedHeaders - The headers in the order specified by the user
   */
  const handlePreviewConfirm = useCallback(async (selectedHeaders: string[], orderedHeaders: string[]) => {
    setSelectedHeaders(selectedHeaders);
    setOrderedHeaders(orderedHeaders);
    
    // Close the preview dialog
    setPreviewDialogOpen(false);
    
    // If we have a file waiting to be uploaded, do it now
    if (currentUploadFile) {
      setIsUploading(true);
      try {
        await handleUploadSingleFile(currentUploadFile, selectedHeaders, orderedHeaders);
        
        // Clear the current upload file
        setCurrentUploadFile(null);
        
        // Show success message
        toast({
          title: 'Upload complete',
          description: `Successfully uploaded ${currentUploadFile.name}`,
        });
      } catch (error) {
        log(LOG_LEVEL.ERROR, 'Error uploading file after preview:', error);
        toast({
          title: 'Upload failed',
          description: error instanceof Error ? error.message : 'Unknown error occurred',
          variant: 'destructive',
        });
      } finally {
        setIsUploading(false);
      }
    }
  }, [currentUploadFile, handleUploadSingleFile, toast]);

  /**
   * Handle the main upload button click
   * Validates files and initiates the upload process
   */
  const handleUpload = useCallback(async () => {
    if (!selectedFiles.length) {
      toast({
        title: 'No files selected',
        description: 'Please select at least one file to upload.',
        variant: 'destructive',
      });
      return;
    }

    // Validate file types
    const invalidFiles = selectedFiles.filter(
      file => !VALID_FILE_TYPES.includes(file.type)
    );
    
    if (invalidFiles.length > 0) {
      toast({
        title: 'Invalid file type',
        description: `Only PDF, PNG, JPEG, and TIFF files are supported. Invalid files: ${invalidFiles.map(f => f.name).join(', ')}`,
        variant: 'destructive',
      });
      return;
    }

    // Validate file size
    const oversizedFiles = selectedFiles.filter(
      file => file.size > MAX_FILE_SIZE
    );
    
    if (oversizedFiles.length > 0) {
      toast({
        title: 'File too large',
        description: `Maximum file size is 50MB. Oversized files: ${oversizedFiles.map(f => f.name).join(', ')}`,
        variant: 'destructive',
      });
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      // If preview is enabled and we have only one file, generate preview first
      if (generatePreview && selectedFiles.length === 1) {
        await handleGeneratePreview(selectedFiles[0]);
        // The actual upload will happen after preview confirmation in handlePreviewConfirm
        setIsUploading(false);
        return;
      }
      
      // Upload each file sequentially
      let successCount = 0;
      for (const file of selectedFiles) {
        const success = await handleUploadSingleFile(file);
        if (success) successCount++;
      }

      // Clear selected files after upload
      setSelectedFiles([]);
      
      toast({
        title: 'Upload complete',
        description: `Successfully uploaded ${successCount} of ${selectedFiles.length} file(s).`,
      });
    } catch (error) {
      log(LOG_LEVEL.ERROR, 'Error in upload process:', error);
      toast({
        title: 'Upload failed',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        variant: 'destructive',
      });
    } finally {
      setIsUploading(false);
      setUploadProgress(0);
    }
  }, [selectedFiles, customColumns, generatePreview, toast, handleGeneratePreview, handleUploadSingleFile]);

  /**
   * Handle downloading an extraction
   * 
   * @param {string} jobId - The ID of the extraction job to download
   */
  const handleDownload = useCallback(async (jobId: string) => {
    if (isDownloading) return;
    
    setIsDownloading(true);
    
    try {
      await downloadExtraction(jobId);
    } catch (error) {
      log(LOG_LEVEL.ERROR, 'Error downloading extraction:', error);
      toast({
        title: 'Download failed',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        variant: 'destructive',
      });
    } finally {
      setIsDownloading(false);
    }
  }, [isDownloading, downloadExtraction, toast]);

  /**
   * Handle deleting an extraction
   * 
   * @param {string} jobId - The ID of the extraction job to delete
   */
  const handleDelete = useCallback(async (jobId: string) => {
    if (isDeletingId) return;
    
    setIsDeletingId(jobId);
    
    try {
      await deleteExtraction(jobId);
      
      toast({
        title: 'Extraction deleted',
        description: 'The extraction has been successfully deleted.',
      });
    } catch (error) {
      log(LOG_LEVEL.ERROR, 'Error deleting extraction:', error);
      toast({
        title: 'Delete failed',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        variant: 'destructive',
      });
    } finally {
      setIsDeletingId(null);
    }
  }, [isDeletingId, deleteExtraction, toast]);

  /**
   * Handle manual refresh of the extraction list
   */
  const handleManualRefresh = useCallback(async () => {
    if (isManualRefreshing) return;
    
    setIsManualRefreshing(true);
    
    try {
      await refreshExtractions();
      
      toast({
        title: 'Refreshed',
        description: 'The extraction list has been refreshed.',
      });
    } catch (error) {
      log(LOG_LEVEL.ERROR, 'Error refreshing extractions:', error);
      toast({
        title: 'Refresh failed',
        description: error instanceof Error ? error.message : 'Unknown error occurred',
        variant: 'destructive',
      });
    } finally {
      setIsManualRefreshing(false);
    }
  }, [isManualRefreshing, refreshExtractions, toast]);

  /**
   * Handle pagination page change
   * 
   * @param {number} page - The page number to navigate to
   */
  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  /**
   * Handle preview click from the extraction list
   * Opens the preview dialog for an existing extraction
   * 
   * @param {any} job - The extraction job to preview
   */
  const handlePreviewClick = useCallback((job: any) => {
    setPreviewJob(job);
    
    // If the job has preview headers, use them
    if (job.previewHeaders && job.previewHeaders.length > 0) {
      setPreviewData({
        headers: job.previewHeaders,
        previewUrl: job.previewUrl
      });
    } else {
      // Otherwise, just show the preview URL
      setPreviewData({
        headers: [],
        previewUrl: job.previewUrl
      });
    }
    
    setPreviewDialogOpen(true);
  }, []);

  // Calculate pagination
  const totalPages = Math.ceil(totalJobs / ITEMS_PER_PAGE);
  const paginatedJobs = extractionJobs;

  return (
    <div className="container mx-auto py-6 space-y-8">
      {/* File Upload Card */}
      <Card>
        <CardHeader>
          <CardTitle className="text-2xl">Docusam PDF & Images to Excel</CardTitle>
          <CardDescription>
            Upload documents to extract tables. Supported formats: PDF, PNG, JPEG, TIFF.
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          {/* File Upload Area */}
          <FileUploadArea
            onFilesSelected={handleFileSelect}
            selectedFiles={selectedFiles}
            isUploading={isUploading}
            uploadProgress={uploadProgress}
            maxFileSize={MAX_FILE_SIZE}
            validFileTypes={VALID_FILE_TYPES}
          />

          <div className="space-y-4">
            {/* Custom Columns Input */}
            <div>
              <Label htmlFor="customColumns">Custom Columns (optional)</Label>
              <Textarea
                id="customColumns"
                placeholder="Enter comma-separated column names"
                value={customColumns}
                onChange={(e) => setCustomColumns(e.target.value)}
                className="mt-1"
              />
              <p className="text-sm text-muted-foreground mt-1">
                Specify custom column names to override detected headers. Only used if preview is disabled.
              </p>
            </div>

            {/* Generate Preview Toggle */}
            <div className="flex items-center space-x-2">
              <Switch
                id="generatePreview"
                checked={generatePreview}
                onCheckedChange={setGeneratePreview}
              />
              <Label htmlFor="generatePreview">Generate Preview</Label>
              <p className="text-sm text-muted-foreground ml-2">
                Preview document and select columns before extraction.
              </p>
            </div>

            {/* Upload Button */}
            <Button
              onClick={handleUpload}
              disabled={!selectedFiles.length || isUploading || !isAuthenticated}
              className="w-full"
            >
              {isUploading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Uploading...
                </>
              ) : (
                'Upload and Extract'
              )}
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Recent Extractions Card */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <div>
            <CardTitle className="text-2xl">Recent Extractions</CardTitle>
            <CardDescription>
              View and manage your recent table extractions.
            </CardDescription>
          </div>
          {/* Refresh Button */}
          <Button
            variant="outline"
            size="sm"
            onClick={handleManualRefresh}
            disabled={isManualRefreshing || isRecentLoading}
          >
            {isManualRefreshing || isRecentLoading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <RefreshCw className="h-4 w-4" />
            )}
            <span className="ml-2">Refresh</span>
          </Button>
        </CardHeader>
        <CardContent>
          {/* Extractions List */}
          <ExtractionsList
            jobs={paginatedJobs}
            isLoading={isRecentLoading}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onPreviewClick={handlePreviewClick}
            isDownloading={isDownloading}
            isDeletingId={isDeletingId}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </CardContent>
      </Card>

      {/* Preview Dialog */}
      <TablePreviewDialog
        open={previewDialogOpen}
        onOpenChange={setPreviewDialogOpen}
        job={previewJob}
        previewData={previewData}
        isLoading={isLoadingPreview}
        onConfirm={handlePreviewConfirm}
      />
    </div>
  );
}
