import { useToast } from '@/hooks/use-toast';
import { fetchApi } from '@/lib/api/utils/fetch';
import { getAuthToken, isAuthenticated } from '@/lib/auth-store';
import {
    User,
    UserFormData,
    UsersResponse,
    UserUpdateData
} from '@/types/user';
import { useState } from 'react';

export function useUserManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  const fetchUsers = async () => {
    try {
      // Check authentication first
      if (!isAuthenticated()) {
        console.log('User is not authenticated');
        toast({
          title: 'Error',
          description: 'Please log in to view users',
          variant: 'destructive',
        });
        return;
      }

      const token = getAuthToken();
      console.log('Auth state:', {
        isAuthenticated: isAuthenticated(),
        hasToken: !!token,
      });

      const data = await fetchApi<UsersResponse>('users');
      setUsers(data.users || []);
    } catch (error) {
      console.error('Error fetching users:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to fetch users',
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const addUser = async (userData: UserFormData) => {
    try {
      // Validate required fields
      const requiredFields = [
        'username',
        'email',
        'password',
        'name',
        'role',
      ] as const;
      const missingFields = requiredFields.filter(
        field => !userData[field as keyof UserFormData]
      );

      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }

      // Validate email format
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(userData.email)) {
        throw new Error('Please enter a valid email address');
      }

      // Validate password length
      if (userData.password.length < 8) {
        throw new Error('Password must be at least 8 characters long');
      }

      console.log('Adding user with data:', {
        ...userData,
        password: '********', // Don't log the actual password
      });

      try {
        const response = await fetchApi<{ user: User } | { error: string }>('users', {
          method: 'POST',
          body: JSON.stringify({
            ...userData,
            permissions: userData.permissions || [],
          }),
        });

        // Check if response is an error object
        if ('error' in response) {
          throw new Error(`Failed to add user: ${response.error}`);
        }
        
        // Check if response has the user property
        if (!response || typeof response !== 'object' || !('user' in response)) {
          console.error('Invalid response format:', response);
          throw new Error('Failed to add user: Invalid response format from server');
        }
        
        // Now we know response has a user property
        const { user } = response as { user: User };
        
        if (!user || typeof user !== 'object') {
          console.error('Invalid user data in response:', user);
          throw new Error('Failed to add user: Invalid user data from server');
        }

        console.log('User added successfully:', { id: user.id, username: user.username });
        setUsers(prevUsers => [...prevUsers, user]);
        toast({
          title: 'Success',
          description: 'User added successfully',
        });
        return true;
      } catch (error) {
        // Handle specific error cases
        const errorMessage = error instanceof Error ? error.message : 'Unknown error';
        
        if (errorMessage.includes('already exists')) {
          throw new Error(`User creation failed: ${errorMessage}`);
        } else if (errorMessage.includes('Validation')) {
          throw new Error(`Validation error: ${errorMessage}`);
        } else if (errorMessage.includes('500')) {
          throw new Error('Server error: The system encountered an issue while creating the user. Please try again or contact support.');
        }
        
        // Re-throw the original error if no specific handling
        throw error;
      }
    } catch (error) {
      console.error('Error adding user:', error);
      
      // Provide a user-friendly error message
      let errorMessage = 'Failed to add user';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      
      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
      });
      return false;
    }
  };

  const updateUser = async (userData: UserUpdateData) => {
    try {
      if (!userData.id) {
        throw new Error('User ID is required for update');
      }

      console.log('Updating user:', userData);
      const response = await fetchApi<{ user: User }>(`users/${userData.id}`, {
        method: 'PATCH',
        body: JSON.stringify({
          ...userData,
          permissions: Array.isArray(userData.permissions)
            ? userData.permissions
            : [],
        }),
      });

      // Check if response is valid
      if (!response) {
        throw new Error('Failed to update user: No response from server');
      }
      
      // Validate that response has the expected user property
      if (!response.user || typeof response.user !== 'object') {
        console.error('Invalid response from server:', response);
        throw new Error('Failed to update user: Invalid response format');
      }

      setUsers(prevUsers =>
        prevUsers.map(user => (user.id === userData.id ? response.user : user))
      );
      
      toast({
        title: 'Success',
        description: 'User updated successfully',
      });
      
      return true;
    } catch (error) {
      console.error('Error updating user:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to update user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const updatePassword = async (id: string, password: string) => {
    try {
      if (!id) {
        throw new Error('User ID is required');
      }
      if (!password || password.length < 8) {
        throw new Error('Password must be at least 8 characters long');
      }

      console.log(`Updating password for user ${id}`);
      
      // Send password update request
      await fetchApi(`users/${id}/password`, {
        method: 'PUT',
        body: JSON.stringify({ password }),
      });

      toast({
        title: 'Success',
        description: 'Password updated successfully',
      });
      return true;
    } catch (error) {
      console.error('Error updating password:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to update password',
        variant: 'destructive',
      });
      return false;
    }
  };

  const deleteUser = async (id: string) => {
    try {
      if (!id) {
        throw new Error('Invalid user ID');
      }

      await fetchApi<{ success: true }>(`users/${id}`, {
        method: 'DELETE',
      });

      setUsers(prevUsers => prevUsers.filter(user => user.id !== id));
      toast({
        title: 'Success',
        description: 'User deleted successfully',
      });
      return true;
    } catch (error) {
      console.error('Error deleting user:', error);
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to delete user',
        variant: 'destructive',
      });
      return false;
    }
  };

  return {
    users,
    isLoading,
    fetchUsers,
    addUser,
    updateUser,
    updatePassword,
    deleteUser,
  };
}
