import * as z from 'zod';

export const formSchema = z.object({
  product_overview: z.string().optional(),
  product_function: z.string().optional(),
  product_type: z.string().optional(),
  manufacture_stage: z.enum(['finished', 'semi-finished', 'raw']).optional(),
  materials: z.string().optional(),
  dimensions: z.string().optional(),
  industry: z.string().optional(),
  origin_country: z.string().optional(),
  target_country: z.string().optional(),
  hs_digits: z.string().optional().default('8'),
});

export type FormSchema = z.infer<typeof formSchema>;
