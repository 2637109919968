interface Config {
  apiUrl: string;
  baseUrl: string;
  environment: string;
}

const getEnvironmentConfig = (): Config => {
  // Check if running in Cloudflare Pages preview
  const isPreview =
    typeof window !== 'undefined' &&
    window.location.hostname.includes('.pages.dev');
  const isDevelopment = import.meta.env.MODE === 'development';
  const isProduction = import.meta.env.PROD;

  if (isPreview) {
    return {
      apiUrl: 'https://freight-forwarding-system-dev.opdeeppc.workers.dev',
      baseUrl: window.location.origin,
      environment: 'preview',
    };
  }

  if (isDevelopment) {
    return {
      apiUrl: 'http://127.0.0.1:8787',
      baseUrl: 'http://localhost:5173',
      environment: 'development',
    };
  }

  // Production
  return {
    apiUrl: 'https://api.shipmate.fpsaus.com.au',
    baseUrl: 'https://shipmate.fpsaus.com.au',
    environment: 'production',
  };
};

const config = getEnvironmentConfig();

// Get the API URL based on environment
export const getApiUrl = (path?: string) => {
  const { apiUrl, environment } = config;

  // Clean and normalize the path
  const cleanPath = path?.trim() || '';
  const normalizedPath = cleanPath ? `/${cleanPath}`.replace(/\/+/g, '/') : '';

  // For production, ensure we're using the correct path format
  if (environment === 'production') {
    // For hs-lookup endpoints, ensure we're using the correct path
    if (cleanPath.startsWith('hs-lookup/') || normalizedPath.startsWith('/hs-lookup/')) {
      return `${apiUrl}/api${normalizedPath}`;
    }
    // For table-extraction endpoints, ensure we're using the correct path
    if (cleanPath === 'table-extraction') {
      return `${apiUrl}/api/table-extraction`;
    }
    // For chat endpoints, ensure we're using the correct path
    if (cleanPath.startsWith('chat/') || normalizedPath.startsWith('/chat/')) {
      return `${apiUrl}/api${normalizedPath}`;
    }
    // For FCL search endpoint, ensure we're using the correct path
    if (cleanPath === 'fcl/search' || normalizedPath === '/fcl/search') {
      return `${apiUrl}${normalizedPath}`;
    }
    return `${apiUrl}/api${normalizedPath}`;
  }

  // For development and preview, ensure /api prefix is present
  if (!normalizedPath.startsWith('/api/')) {
    return `${apiUrl}/api${normalizedPath}`;
  }

  // For development and preview, remove /api prefix if it exists
  const devPath = normalizedPath.replace(/^\/api/, '');
  return `${apiUrl}${devPath}`;
};

// Export environment-specific values
export const isDevelopment = config.environment === 'development';
export const isPreview = config.environment === 'preview';
export const isProduction = config.environment === 'production';

export const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.shipmate.fpsaus.com.au'
    : 'https://freight-forwarding-system.opdeeppc.workers.dev/api';

export default config;
