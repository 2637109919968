import {
    ChatInput,
    ChatMessages,
    ChatSidebar,
    ConfirmDialog,
    formatDate,
    SessionDialog,
    useDialogState
} from '@/components/chat';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Skeleton } from '@/components/ui/skeleton';
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip';
import { useToast } from '@/hooks/use-toast';
import { useChat, useChatSessions } from '@/lib/api/services/chat';
import { Loader2, Plus, Search } from 'lucide-react';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Chat page component
 * Provides a user interface for interacting with the AI chat system
 */
export default function Chat() {
  // State for the active session and input
  const [activeSessionId, setActiveSessionId] = useState<string | null>(null);
  const [input, setInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  
  // Refs
  const inputRef = useRef<HTMLInputElement>(null);
  
  // Dialog state using custom hooks
  const newSessionDialog = useDialogState<string>('');
  const editSessionDialog = useDialogState<{id: string, title: string}>({id: '', title: ''});
  const deleteSessionDialog = useDialogState<string>('');
  const archiveSessionDialog = useDialogState<string>('');
  const editMessageDialog = useDialogState<{id: string, content: string}>({id: '', content: ''});
  const deleteMessageDialog = useDialogState<string>('');
  
  const { toast } = useToast();
  
  // Fetch sessions and chat data
  const {
    sessions,
    isLoading: isSessionsLoading,
    error: sessionsError,
    createSession,
    updateSession,
    deleteSession,
    archiveSession,
    fetchSessions,
  } = useChatSessions();
  
  const {
    messages,
    isLoading: isMessagesLoading,
    error,
    sendMessage,
    deleteMessage,
    editMessage,
    exportChat,
    fetchMessages,
  } = useChat(activeSessionId);

  // Filter sessions based on search query
  const filteredSessions = searchQuery
    ? sessions.filter(session => 
        session.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : sessions;

  // Show error toast if there's an error
  useEffect(() => {
    if (error) {
      toast({
        title: 'Error',
        description: error,
        variant: 'destructive',
      });
    }
  }, [error, toast]);

  // Show error toast if there's a sessions error
  useEffect(() => {
    if (sessionsError) {
      toast({
        title: 'Error',
        description: sessionsError,
        variant: 'destructive',
      });
    }
  }, [sessionsError, toast]);

  // Keyboard shortcuts
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Cmd/Ctrl + N to create new chat
      if ((e.metaKey || e.ctrlKey) && e.key === 'n') {
        e.preventDefault();
        newSessionDialog.open('');
      }
      
      // Cmd/Ctrl + F to focus search
      if ((e.metaKey || e.ctrlKey) && e.key === 'f') {
        e.preventDefault();
        setIsSearching(true);
        setTimeout(() => {
          document.getElementById('session-search')?.focus();
        }, 100);
      }
      
      // Escape to cancel search
      if (e.key === 'Escape' && isSearching) {
        setIsSearching(false);
        setSearchQuery('');
        inputRef.current?.focus();
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [newSessionDialog, isSearching]);

  // Retry mechanism for failed operations
  const retryOperation = useCallback(async (operation: () => Promise<any>, errorMessage: string) => {
    try {
      setRetryCount(prev => prev + 1);
      return await operation();
    } catch (error) {
      if (retryCount < 3) {
        toast({
          title: 'Retrying...',
          description: `Attempt ${retryCount + 1} of 3`,
        });
        return await retryOperation(operation, errorMessage);
      } else {
        toast({
          title: 'Error',
          description: errorMessage,
          variant: 'destructive',
        });
        setRetryCount(0);
        return false;
      }
    }
  }, [retryCount, toast]);

  // Create a new session
  const handleCreateSession = async () => {
    const title = newSessionDialog.data?.trim() || 'New Chat';
    
    const createSessionOperation = async () => {
      const sessionId = await createSession(title);
      if (sessionId) {
        setActiveSessionId(sessionId);
        newSessionDialog.close();
        toast({
          title: 'Success',
          description: 'New chat session created',
        });
        return true;
      }
      return false;
    };
    
    await retryOperation(
      createSessionOperation,
      'Failed to create a new chat session. Please try again.'
    );
  };

  // Update a session
  const handleUpdateSession = async () => {
    if (editSessionDialog.data && editSessionDialog.data.title.trim()) {
      const updateSessionOperation = async () => {
        const success = await updateSession(
          editSessionDialog.data!.id, 
          editSessionDialog.data!.title.trim()
        );
        if (success) {
          editSessionDialog.close();
          toast({
            title: 'Success',
            description: 'Chat session renamed successfully',
          });
          return true;
        }
        return false;
      };
      
      await retryOperation(
        updateSessionOperation,
        'Failed to rename chat session. Please try again.'
      );
    }
  };

  // Delete a session
  const handleDeleteSession = async () => {
    if (deleteSessionDialog.data) {
      const deleteSessionOperation = async () => {
        const success = await deleteSession(deleteSessionDialog.data!);
        if (success) {
          if (activeSessionId === deleteSessionDialog.data) {
            setActiveSessionId(null);
          }
          deleteSessionDialog.close();
          toast({
            title: 'Success',
            description: 'Chat session deleted successfully',
          });
          return true;
        }
        return false;
      };
      
      await retryOperation(
        deleteSessionOperation,
        'Failed to delete chat session. Please try again.'
      );
    }
  };

  // Archive a session
  const handleArchiveSession = async () => {
    if (archiveSessionDialog.data) {
      const archiveSessionOperation = async () => {
        const success = await archiveSession(archiveSessionDialog.data!);
        if (success) {
          archiveSessionDialog.close();
          toast({
            title: 'Success',
            description: 'Chat session archived successfully',
          });
          return true;
        }
        return false;
      };
      
      await retryOperation(
        archiveSessionOperation,
        'Failed to archive chat session. Please try again.'
      );
    }
  };

  // Edit a message
  const handleEditMessage = async () => {
    if (editMessageDialog.data && editMessageDialog.data.content.trim()) {
      const editMessageOperation = async () => {
        const success = await editMessage(
          editMessageDialog.data!.id, 
          editMessageDialog.data!.content.trim()
        );
        if (success) {
          editMessageDialog.close();
          toast({
            title: 'Success',
            description: 'Message updated successfully',
          });
          return true;
        }
        return false;
      };
      
      await retryOperation(
        editMessageOperation,
        'Failed to edit message. Please try again.'
      );
    }
  };

  // Delete a message
  const handleDeleteMessage = async () => {
    if (deleteMessageDialog.data) {
      const deleteMessageOperation = async () => {
        const success = await deleteMessage(deleteMessageDialog.data!);
        if (success) {
          deleteMessageDialog.close();
          toast({
            title: 'Success',
            description: 'Message deleted successfully',
          });
          return true;
        }
        return false;
      };
      
      await retryOperation(
        deleteMessageOperation,
        'Failed to delete message. Please try again.'
      );
    }
  };

  // Export chat history
  const handleExportChat = async () => {
    if (!activeSessionId) return;
    
    const exportChatOperation = async () => {
      const chatData = await exportChat();
      if (chatData) {
        // Create a downloadable JSON file
        const dataStr = JSON.stringify(chatData, null, 2);
        const dataUri = 'data:application/json;charset=utf-8,'+ encodeURIComponent(dataStr);
        
        const exportFileDefaultName = `chat-export-${new Date().toISOString().slice(0, 10)}.json`;
        
        const linkElement = document.createElement('a');
        linkElement.setAttribute('href', dataUri);
        linkElement.setAttribute('download', exportFileDefaultName);
        linkElement.click();
        
        toast({
          title: 'Success',
          description: 'Chat exported successfully',
        });
        return true;
      }
      return false;
    };
    
    await retryOperation(
      exportChatOperation,
      'Failed to export chat. Please try again.'
    );
  };

  // Refresh data
  const handleRefresh = async () => {
    if (activeSessionId) {
      await fetchMessages();
    }
    await fetchSessions();
    
    toast({
      title: 'Refreshed',
      description: 'Chat data refreshed successfully',
    });
  };

  // Handle message submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!input.trim() || isLoading) return;
    
    try {
      setIsLoading(true);
      
      // If no active session, create one first
      if (!activeSessionId) {
        const sessionId = await createSession('New Chat');
        
        if (sessionId) {
          setActiveSessionId(sessionId);
          
          // Wait a bit for the session to be created and state to update
          await new Promise(resolve => setTimeout(resolve, 500));
          
          // Send the message with the stored input
          await sendMessage(input);
          setInput('');
        } else {
          throw new Error('Failed to create a new chat session');
        }
      } else {
        // If we already have an active session, send the message normally
        await sendMessage(input);
        setInput('');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description:
          error instanceof Error ? error.message : 'Failed to send message',
        variant: 'destructive',
      });
      
      // Offer retry option
      toast({
        title: 'Retry',
        description: 'Click to try sending the message again',
        action: (
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => {
              // Use setTimeout to allow the state to update before submitting
              setTimeout(() => {
                const formEvent = { preventDefault: () => {} } as React.FormEvent;
                handleSubmit(formEvent);
              }, 100);
            }}
          >
            Retry
          </Button>
        ),
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Find the active session
  const activeSession = activeSessionId 
    ? sessions.find(s => s.id === activeSessionId) || null
    : null;

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between items-center relative">
        <h1 className="text-3xl font-bold">AI Chat</h1>
        <div className="flex items-center space-x-2">
          {isSearching && (
            <div className="relative">
              <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
              <Input
                id="session-search"
                className="pl-8 w-[200px]"
                placeholder="Search chats..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onBlur={() => !searchQuery && setIsSearching(false)}
              />
            </div>
          )}
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="outline" 
                  size="icon"
                  onClick={() => setIsSearching(true)}
                  className={isSearching ? 'hidden' : ''}
                >
                  <Search className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Search chats (Ctrl+F)</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button 
                  variant="outline" 
                  size="icon"
                  onClick={handleRefresh}
                  disabled={isMessagesLoading || isSessionsLoading}
                >
                  {(isMessagesLoading || isSessionsLoading) ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-refresh-cw">
                      <path d="M3 12a9 9 0 0 1 9-9 9.75 9.75 0 0 1 6.74 2.74L21 8"/>
                      <path d="M21 3v5h-5"/>
                      <path d="M21 12a9 9 0 0 1-9 9 9.75 9.75 0 0 1-6.74-2.74L3 16"/>
                      <path d="M3 21v-5h5"/>
                    </svg>
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Refresh</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button onClick={() => newSessionDialog.open('')}>
                  <Plus className="h-4 w-4 mr-2" />
                  New Chat
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Start a new chat session (Ctrl+N)</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Chat Sessions Sidebar */}
        {isSessionsLoading ? (
          <div className="space-y-2">
            <Skeleton className="h-[50px] w-full" />
            <Skeleton className="h-[50px] w-full" />
            <Skeleton className="h-[50px] w-full" />
          </div>
        ) : (
          <ChatSidebar
            sessions={filteredSessions}
            isLoading={isSessionsLoading}
            activeSessionId={activeSessionId}
            onSessionSelect={setActiveSessionId}
            onNewSession={() => newSessionDialog.open('')}
            onEditSession={(id, title) => editSessionDialog.open({ id, title })}
            onArchiveSession={(id) => archiveSessionDialog.open(id)}
            onExportSession={(id) => {
              setActiveSessionId(id);
              setTimeout(() => handleExportChat(), 100);
            }}
            onDeleteSession={(id) => deleteSessionDialog.open(id)}
            formatDate={formatDate}
          />
        )}

        {/* Chat Messages */}
        <div className="lg:col-span-3 flex flex-col h-[calc(100vh-200px)] min-h-[500px]">
          {isMessagesLoading && !messages.length ? (
            <div className="flex-grow flex flex-col items-center justify-center">
              <Loader2 className="h-8 w-8 animate-spin mb-4" />
              <p className="text-muted-foreground">Loading messages...</p>
            </div>
          ) : (
            <ChatMessages
              messages={messages}
              activeSession={activeSession}
              onEditSession={(id, title) => editSessionDialog.open({ id, title })}
              onArchiveSession={(id) => archiveSessionDialog.open(id)}
              onExportChat={handleExportChat}
              onDeleteSession={(id) => deleteSessionDialog.open(id)}
              onEditMessage={(id, content) => editMessageDialog.open({ id, content })}
              onDeleteMessage={(id) => deleteMessageDialog.open(id)}
              formatDate={formatDate}
            />
          )}
          <ChatInput
            input={input}
            setInput={setInput}
            isLoading={isLoading || isMessagesLoading}
            onSubmit={handleSubmit}
            inputRef={inputRef}
          />
        </div>
      </div>

      {/* New Session Dialog */}
      <SessionDialog
        isOpen={newSessionDialog.isOpen}
        onOpenChange={newSessionDialog.setIsOpen}
        title="New Chat Session"
        description="Create a new chat session with the AI"
        inputLabel="Chat title (optional)"
        inputValue={newSessionDialog.data || ''}
        onInputChange={(value) => newSessionDialog.open(value)}
        confirmLabel="Create"
        onConfirm={handleCreateSession}
      />

      {/* Edit Session Dialog */}
      <SessionDialog
        isOpen={editSessionDialog.isOpen}
        onOpenChange={editSessionDialog.setIsOpen}
        title="Rename Chat Session"
        description="Change the title of this chat session"
        inputLabel="Chat title"
        inputValue={editSessionDialog.data?.title || ''}
        onInputChange={(value) => 
          editSessionDialog.open({ 
            ...editSessionDialog.data!, 
            title: value 
          })
        }
        confirmLabel="Save"
        onConfirm={handleUpdateSession}
      />

      {/* Delete Session Dialog */}
      <ConfirmDialog
        isOpen={deleteSessionDialog.isOpen}
        onOpenChange={deleteSessionDialog.setIsOpen}
        title="Delete Chat Session"
        description="Are you sure you want to delete this chat session? This action cannot be undone."
        confirmLabel="Delete"
        onConfirm={handleDeleteSession}
        isDestructive={true}
      />

      {/* Archive Session Dialog */}
      <ConfirmDialog
        isOpen={archiveSessionDialog.isOpen}
        onOpenChange={archiveSessionDialog.setIsOpen}
        title="Archive Chat Session"
        description="Are you sure you want to archive this chat session? Archived chats will still be visible but marked as archived."
        confirmLabel="Archive"
        onConfirm={handleArchiveSession}
      />

      {/* Edit Message Dialog */}
      <SessionDialog
        isOpen={editMessageDialog.isOpen}
        onOpenChange={editMessageDialog.setIsOpen}
        title="Edit Message"
        description="Update the content of your message"
        inputLabel="Message content"
        inputValue={editMessageDialog.data?.content || ''}
        onInputChange={(value) => 
          editMessageDialog.open({ 
            ...editMessageDialog.data!, 
            content: value 
          })
        }
        confirmLabel="Save"
        onConfirm={handleEditMessage}
      />

      {/* Delete Message Dialog */}
      <ConfirmDialog
        isOpen={deleteMessageDialog.isOpen}
        onOpenChange={deleteMessageDialog.setIsOpen}
        title="Delete Message"
        description="Are you sure you want to delete this message? This action cannot be undone."
        confirmLabel="Delete"
        onConfirm={handleDeleteMessage}
        isDestructive={true}
      />
    </div>
  );
}
