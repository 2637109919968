import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';
import { Button } from '@/components/ui/button';
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from '@/components/ui/card';
import { useToast } from '@/components/ui/use-toast';
import { AddUserDialog } from '@/components/users/AddUserDialog';
import { UsersTable } from '@/components/users/UsersTable';
import { useAuth } from '@/hooks/useAuth';
import { logUserAction } from '@/lib/audit-logger';
import { getAuthToken, refreshAuthToken } from '@/lib/auth-store';
import config from '@/lib/config';
import { PERMISSIONS, hasPermission } from '@/lib/permissions';
import { User, UserUpdateData } from '@/types/user';
import { AlertCircle, Loader2, RefreshCw, ShieldAlert } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function TenantUsers() {
  const { id: tenantId } = useParams<{ id: string }>();
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [tenantDetails, setTenantDetails] = useState<{ name: string; status: string } | null>(null);
  const { toast } = useToast();
  const { user: currentUser } = useAuth();
  const navigate = useNavigate();

  // Check if user has permission to manage this tenant
  const canManageTenant = currentUser && (
    currentUser.role === 'admin' || 
    currentUser.role === 'superadmin' || 
    (currentUser.tenant_id === tenantId && hasPermission(currentUser, PERMISSIONS.MANAGE_USERS))
  );

  // Fetch tenant details to validate tenant exists and is active
  const fetchTenantDetails = async () => {
    if (!tenantId) return null;
    
    try {
      // Get fresh auth token
      const token = getAuthToken();
      
      if (!token) {
        console.warn('No auth token found when fetching tenant details');
        setError('Authentication error. Please log in again.');
        return null;
      }
      
      const response = await fetch(
        `${config.apiUrl}/tenants/${tenantId}`,
        {
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      if (response.status === 401) {
        console.log('Auth token expired, attempting to refresh...');
        const refreshed = await refreshAuthToken();
        if (refreshed) {
          // Try again with new token
          return fetchTenantDetails();
        } else {
          throw new Error('Your session has expired. Please log in again.');
        }
      }

      if (!response.ok) {
        if (response.status === 404) {
          throw new Error('Tenant not found');
        }
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Failed to fetch tenant details (${response.status})`);
      }

      const data = await response.json();
      setTenantDetails(data);
      
      // Check if tenant is active
      if (data.status !== 'active') {
        setError(`This tenant is currently ${data.status}. User management is disabled.`);
      }
      
      return data;
    } catch (error) {
      console.error('Error fetching tenant details:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to fetch tenant details';
      setError(errorMessage);
      
      // If authentication error, redirect to login
      if (errorMessage.includes('session has expired') || errorMessage.includes('Authentication error')) {
        toast({
          title: 'Session Expired',
          description: 'Your session has expired. Please log in again.',
          variant: 'destructive',
        });
        navigate('/login');
      }
      
      return null;
    }
  };

  const fetchUsers = async () => {
    if (!tenantId) {
      setError('Tenant ID is missing');
      setIsLoading(false);
      return;
    }

    // Validate tenant exists and user has permission
    const tenant = tenantDetails || await fetchTenantDetails();
    if (!tenant) {
      setIsLoading(false);
      return;
    }

    if (!canManageTenant) {
      setError('You do not have permission to manage users for this tenant');
      setIsLoading(false);
      return;
    }

    try {
      setError(null);
      
      // Get fresh auth token
      const token = getAuthToken();
      
      if (!token) {
        console.warn('No auth token found when fetching users');
        setError('Authentication error. Please log in again.');
        setIsLoading(false);
        return;
      }
      
      console.log(`Fetching users for tenant ${tenantId} with token: ${token.substring(0, 10)}...`);
      
      const response = await fetch(
        `${config.apiUrl}/tenants/${tenantId}/users`,
        {
          credentials: 'include',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      // Log response status for debugging
      console.log(`Users API response status: ${response.status}`);
      
      // Handle authentication errors
      if (response.status === 401) {
        console.log('Auth token expired, attempting to refresh...');
        const refreshed = await refreshAuthToken();
        if (refreshed) {
          // Try again with new token
          return fetchUsers();
        } else {
          throw new Error('Your session has expired. Please log in again.');
        }
      }

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response data:', errorData);
        throw new Error(errorData.message || `Failed to fetch users (${response.status})`);
      }

      const data = await response.json();
      console.log(`Received ${data.users ? data.users.length : 'undefined'} users for tenant ${tenantId}`);
      
      // Check if data.users exists and is an array
      if (!data.users || !Array.isArray(data.users)) {
        console.error('Invalid response format:', data);
        throw new Error('Invalid response format from server');
      }
      
      const transformedUsers: User[] = data.users.map((user: User) => ({
        ...user,
        role: user.is_superadmin ? 'superadmin' : user.role || 'user',
      }));
      setUsers(transformedUsers);
      
      // Log successful user list fetch
      logUserAction({
        action: 'list_users',
        target_type: 'tenant',
        target_id: tenantId,
        details: { count: transformedUsers.length }
      });
    } catch (error) {
      console.error('Error fetching users:', error);
      const errorMessage = error instanceof Error ? error.message : 'Failed to fetch users';
      setError(errorMessage);
      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
      });
    } finally {
      setIsLoading(false);
      setIsRefreshing(false);
    }
  };

  // Handle user actions
  const handleAddUser = async (userData: any): Promise<boolean> => {
    try {
      if (!tenantId) {
        throw new Error('Tenant ID is missing');
      }

      // Get fresh auth token
      const token = getAuthToken();
      
      if (!token) {
        console.warn('No auth token found when adding user');
        setError('Authentication error. Please log in again.');
        return false;
      }
      
      console.log('Adding user to tenant:', { tenantId, userData: { ...userData, password: '******' } });
      
      // Make API call to create user
      const response = await fetch(
        `${config.apiUrl}/tenants/${tenantId}/users`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            ...userData,
            tenant_id: tenantId
          }),
          credentials: 'include'
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || errorData.message || `Failed to add user (${response.status})`);
      }
      
      const data = await response.json();
      
      if (!data.user) {
        throw new Error('Invalid response format from server');
      }
      
      // Add the new user to the local state
      setUsers(prev => [...prev, data.user]);
      
      // Log successful user creation
      logUserAction({
        action: 'create_user',
        target_type: 'tenant',
        target_id: tenantId,
        details: { userId: data.user.id }
      });
      
      toast({
        title: 'Success',
        description: 'User created successfully',
      });
      
      return true;
    } catch (error) {
      console.error('Error adding user:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to add user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleUpdateUser = async (userData: UserUpdateData): Promise<boolean> => {
    try {
      if (!tenantId) {
        throw new Error('Tenant ID is missing');
      }
      
      // Get fresh auth token
      const token = getAuthToken();
      
      if (!token) {
        console.warn('No auth token found when updating user');
        setError('Authentication error. Please log in again.');
        return false;
      }
      
      console.log('Updating user:', userData);
      
      // Make API call to update user
      const response = await fetch(
        `${config.apiUrl}/users/${userData.id}`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            ...userData,
            tenant_id: tenantId
          }),
          credentials: 'include'
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || errorData.message || `Failed to update user (${response.status})`);
      }
      
      const data = await response.json();
      
      // Update the user in the local state
      setUsers(prev => 
        prev.map(user => user.id === userData.id ? { ...user, ...data.user } : user)
      );
      
      // Log successful user update
      logUserAction({
        action: 'update_user',
        target_type: 'user',
        target_id: userData.id,
        details: { tenantId }
      });
      
      toast({
        title: 'Success',
        description: 'User updated successfully',
      });
      
      return true;
    } catch (error) {
      console.error('Error updating user:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleDeleteUser = async (id: string): Promise<boolean> => {
    try {
      if (!tenantId || !id) {
        throw new Error('Tenant ID or User ID is missing');
      }
      
      // Get fresh auth token
      const token = getAuthToken();
      
      if (!token) {
        console.warn('No auth token found when deleting user');
        setError('Authentication error. Please log in again.');
        return false;
      }
      
      console.log('Deleting user:', id);
      
      // Make API call to delete user
      const response = await fetch(
        `${config.apiUrl}/users/${id}`,
        {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          },
          credentials: 'include'
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || errorData.message || `Failed to delete user (${response.status})`);
      }
      
      // Remove the user from the local state
      setUsers(prev => prev.filter(user => user.id !== id));
      
      // Log successful user deletion
      logUserAction({
        action: 'delete_user',
        target_type: 'user',
        target_id: id,
        details: { tenantId }
      });
      
      toast({
        title: 'Success',
        description: 'User deleted successfully',
      });
      
      return true;
    } catch (error) {
      console.error('Error deleting user:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to delete user',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleUpdatePassword = async (id: string, password: string): Promise<boolean> => {
    try {
      if (!id) {
        throw new Error('User ID is missing');
      }
      
      if (!password || password.length < 8) {
        throw new Error('Password must be at least 8 characters long');
      }
      
      // Get fresh auth token
      const token = getAuthToken();
      
      if (!token) {
        console.warn('No auth token found when updating password');
        setError('Authentication error. Please log in again.');
        return false;
      }
      
      console.log('Updating password for user:', id);
      
      // Make API call to update password
      const response = await fetch(
        `${config.apiUrl}/users/${id}/password`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({ password }),
          credentials: 'include'
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || errorData.message || `Failed to update password (${response.status})`);
      }
      
      // Log successful password update
      logUserAction({
        action: 'update_password',
        target_type: 'user',
        target_id: id,
        details: { tenantId }
      });
      
      toast({
        title: 'Success',
        description: 'Password updated successfully',
      });
      
      return true;
    } catch (error) {
      console.error('Error updating password:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update password',
        variant: 'destructive',
      });
      return false;
    }
  };

  // Handle refresh button click
  const handleRefresh = () => {
    setIsRefreshing(true);
    fetchUsers();
  };

  // Initial data fetch
  useEffect(() => {
    fetchUsers();
  }, [tenantId]);

  // Render loading state
  if (isLoading) {
    return (
      <div className="container mx-auto py-10">
        <div className="flex flex-col items-center justify-center h-64">
          <Loader2 className="h-8 w-8 animate-spin text-primary mb-4" />
          <p className="text-muted-foreground">Loading tenant users...</p>
        </div>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="container mx-auto py-10">
        <Alert variant="destructive" className="mb-6">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
        
        <div className="flex justify-center mt-4">
          <Button onClick={() => navigate('/tenants')} variant="outline">
            Back to Tenants
          </Button>
        </div>
      </div>
    );
  }

  // Render no permission state
  if (!canManageTenant) {
    return (
      <div className="container mx-auto py-10">
        <Alert className="mb-6">
          <ShieldAlert className="h-4 w-4" />
          <AlertTitle>Access Denied</AlertTitle>
          <AlertDescription>
            You do not have permission to manage users for this tenant.
          </AlertDescription>
        </Alert>
        
        <div className="flex justify-center mt-4">
          <Button onClick={() => navigate('/tenants')} variant="outline">
            Back to Tenants
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10">
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">
            {tenantDetails?.name || 'Tenant'} Users
          </h1>
          <p className="text-muted-foreground mt-1">
            Manage users for this tenant
          </p>
        </div>
        
        <div className="flex gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={handleRefresh}
            disabled={isRefreshing}
          >
            {isRefreshing ? (
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
            ) : (
              <RefreshCw className="h-4 w-4 mr-2" />
            )}
            Refresh
          </Button>
          
          <AddUserDialog
            onAddUser={handleAddUser}
          />
        </div>
      </div>
      
      {users.length === 0 ? (
        <Card>
          <CardHeader>
            <CardTitle>No Users</CardTitle>
            <CardDescription>
              This tenant doesn't have any users yet.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-muted-foreground">
              Click the "Add User" button to add users to this tenant.
            </p>
          </CardContent>
        </Card>
      ) : (
        <UsersTable
          users={users}
          onDeleteUser={handleDeleteUser}
          onUpdateUser={handleUpdateUser}
          onUpdatePassword={handleUpdatePassword}
          isLoading={false}
        />
      )}
    </div>
  );
}
